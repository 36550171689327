import { HStack, Text, Box, Skeleton, Center, Spinner, Flex, Image } from "@chakra-ui/react";
import { TwitchRivrPlayer } from "./TwitchRivrPlayer/TwitchRivrPlayer";
import { YouTubeRivrPlayer } from "./YouTubeRivrPlayer/YouTubeRivrPlayer";
import { originTwitch, originYouTube } from "../../models/urls";
import TwitchLogo from "../../assets/twitch-logo.svg";
import YouTubeLogo from "../../assets/youtube-logo.svg";

type Props = {
  videoInfo: any;
  seekTime: { seconds: number } | null;
  pausePlayer: { pause: boolean };
  updatePlayTime: (seconds: number) => void;
};

export const MediaPlayer = (props: Props) => {
  const { videoInfo, seekTime, pausePlayer, updatePlayTime } = props;

  const RivrPlayer = videoInfo
    ? videoInfo.origin_type === originTwitch
      ? TwitchRivrPlayer
      : videoInfo.origin_type === originYouTube
      ? YouTubeRivrPlayer
      : null
    : null;

  return (
    <>
      <HStack spacing={2}>
        {videoInfo ? (
          <>
            {videoInfo.origin_type === originTwitch ? (
              <Image src={TwitchLogo} alt="Twitch Logo" title="Twitch" h="18px" mr={1} />
            ) : videoInfo.origin_type === originYouTube ? (
              <Image src={YouTubeLogo} alt="YouTube Logo" title="YouTube" h="18px" mr={1} />
            ) : (
              <></>
            )}
            <Text fontSize="lg" fontWeight="medium" noOfLines={1} title={videoInfo.video_title}>
              {videoInfo.video_title}
            </Text>
          </>
        ) : (
          <Skeleton height="20px" w="50%" />
        )}
      </HStack>
      <Box
        mt={3}
        flexGrow={10}
        backgroundColor="gray.700"
        p={3}
        borderWidth={1}
        borderRadius={"md"}
      >
        {!videoInfo && (
          <Center flexDirection="column" justifyContent="center" height="100%">
            <Flex alignItems="center">
              <Text mr="2" fontSize="lg" fontWeight="medium">
                Analyzing media...
              </Text>
              <Spinner size="sm" />
            </Flex>
          </Center>
        )}
        {RivrPlayer && (
          <RivrPlayer
            seekTime={seekTime}
            pausePlayer={pausePlayer}
            videoInfo={videoInfo}
            updatePlayTime={updatePlayTime}
          />
        )}
      </Box>
    </>
  );
};
