import { useQuery } from "@apollo/client";
import { Box, Stat, StatLabel, StatNumber, StatHelpText } from "@chakra-ui/react";
import { ClipRequestsQuery } from "../../api/stats";
import { allowedUserIds } from "../../utils/allowedUserIds";

function AverageClipLength() {
  const { data } = useQuery(ClipRequestsQuery, {
    variables: { excludedUserIDs: allowedUserIds },
  });

  const clipRequests = data?.clip_request || [];

  const avgLength =
    clipRequests.reduce((sum, clip) => sum + clip.lead_in + clip.lead_out, 0) / clipRequests.length;

  return (
    <Box p="6" backgroundColor="gray.700" borderRadius={8} w="full">
      <Stat>
        <StatLabel>Average Clip Length</StatLabel>
        <StatNumber>{avgLength.toFixed(0)} seconds</StatNumber>
        <StatHelpText mb={0}>Based on all clip requests</StatHelpText>
      </Stat>
    </Box>
  );
}

export default AverageClipLength;
