import { Box, VStack, Heading, Text, HStack, Badge, Divider, Button, Flex } from "@chakra-ui/react";
import { PaymentIcon } from "react-svg-credit-card-payment-icons";
import { useQuery } from "@apollo/client";
import PlanCard from "../../PlanCard/PlanCard";
import { RivrOrganization } from "../../../models/rivrOrganization";
import {
  LatestOrgSubscriptionEventQuery,
  LatestOrgSubscriptionMetadataEventQuery,
} from "../../../api/subscription-event";
import { Loading } from "../../Loading/Loading";
import { planDetails } from "../../../models/plan";
import { WarningIcon } from "@chakra-ui/icons";
import { PaymentPortalQuery } from "../../../api/actions";
import { formatDateForDisplay } from "../../../utils/time";

const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

type Props = {
  organization: RivrOrganization;
};

export const OrgBilling = (props: Props) => {
  const { organization } = props;
  const hasAccess = organization.subscriptionStatus === "active";
  const hasSub = organization.subscriptionStatus !== "none" && organization.subscriptionId !== null;
  const { data: latestEvent } = useQuery(LatestOrgSubscriptionEventQuery, {
    variables: { organization_id: organization.id },
    skip: !hasSub,
  });
  const { data: latestMetadataEvent } = useQuery(LatestOrgSubscriptionMetadataEventQuery, {
    variables: { organization_id: organization.id },
    skip: !hasSub,
  });
  const { data: urls } = useQuery(PaymentPortalQuery, {
    variables: { org: organization.id },
    skip: !hasSub,
  });

  const subscription = latestEvent?.subscription_event[0];
  const subscriptionMetadata = latestMetadataEvent?.subscription_event[0];
  const customerPortal = urls?.getPaymentPortal.customerPortal;
  const paymentMethod = urls?.getPaymentPortal.paymentMethod;

  const subStatus = subscription?.status as string;

  const subRenewDate = subscriptionMetadata?.renews_at
    ? formatDateForDisplay(new Date(subscriptionMetadata.renews_at as string))
    : null;

  const subEndDate = subscription?.ends_at
    ? formatDateForDisplay(new Date(subscription.ends_at as string))
    : null;

  if (hasSub && (!subscription || !subscriptionMetadata || !urls))
    return <Loading variant={"large"} />;

  return (
    <Box w={"100%"} height={"100%"} alignItems={"flex-start"}>
      <VStack alignItems={"flex-start"} spacing={12} pt={6}>
        <HStack
          alignItems={"center"}
          w={"100%"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          rowGap={6}
          spacing={0}
        >
          <VStack alignItems={"flex-start"}>
            <Heading as={"h1"} fontSize={"3xl"}>
              Billing and Plans
            </Heading>
            <Text color={"gray.300"}>
              View your payment information, billing history, and make changes to your plan.
            </Text>
          </VStack>
        </HStack>
        <Divider />
        {hasSub ? (
          <>
            <HStack
              w={"100%"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              flexWrap={"wrap"}
              spacing={0}
              rowGap={6}
            >
              <VStack alignItems={"flex-start"} w="50%" spacing={1}>
                <Text fontSize={"lg"}>Your plan</Text>
                <Text color={"gray.300"} fontSize={"sm"}>
                  Manage your plan.
                </Text>
              </VStack>
              <HStack
                spacing={6}
                bg={"gray.700"}
                p={4}
                borderRadius={"md"}
                borderWidth={1}
                rowGap={6}
                flexGrow={1}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
              >
                <HStack spacing={6} align={"flex-start"}>
                  <HStack>
                    <Text>Plan:</Text>
                    <Badge fontSize="md" colorScheme="green" px={1.5} letterSpacing={1}>
                      {planDetails.Enterprise.planName}
                    </Badge>
                  </HStack>
                  <VStack spacing={1} align={"flex-start"}>
                    <HStack>
                      <Text>Status:</Text>
                      {hasAccess ? (
                        <Badge
                          fontSize="md"
                          display={"flex"}
                          colorScheme={subStatus === "cancelled" ? "yellow" : "green"}
                          px={1.5}
                          alignItems={"center"}
                          letterSpacing={1}
                        >
                          {subStatus === "cancelled" && <WarningIcon mr={1} />} Active
                        </Badge>
                      ) : (
                        <Badge fontSize="md" colorScheme={"red"} px={1.5} letterSpacing={1}>
                          Inactive
                        </Badge>
                      )}
                    </HStack>
                    {hasAccess && subStatus === "active" && subRenewDate && (
                      <Text fontSize={"xs"} color={"gray.300"}>
                        Renews on {subRenewDate}
                      </Text>
                    )}
                    {hasAccess && subStatus === "cancelled" && (
                      <Text fontSize={"xs"} color={"gray.300"}>
                        Ends on {subEndDate}
                      </Text>
                    )}
                  </VStack>
                </HStack>

                <Button
                  colorScheme="blue"
                  as="a"
                  href={customerPortal}
                  target="_blank"
                  rel="noreferrer"
                >
                  Manage plan
                </Button>
              </HStack>
            </HStack>
            <Flex w={"100%"} justify={"flex-end"}>
              <PlanCard
                orgId={organization.id}
                planType="Enterprise"
                planAvailable={true}
                showSubscribe={false}
                subscriptionExists={true}
                displayVariant="monthly"
              />
            </Flex>
            <Divider />
            <HStack
              w={"100%"}
              justifyContent={"space-between"}
              flexWrap={"wrap"}
              rowGap={6}
              spacing={0}
              alignItems={"flex-start"}
            >
              <VStack alignItems={"flex-start"} w={"50%"} spacing={1}>
                <Text fontSize={"lg"}>Payment method</Text>
                <Text color={"gray.300"} fontSize={"sm"}>
                  Manage your default payment method.
                </Text>
              </VStack>
              <HStack
                bg={"gray.700"}
                p={4}
                borderRadius={"md"}
                justifyContent={"space-between"}
                borderWidth={1}
                flexGrow={1}
              >
                <HStack>
                  <Box p={2} bg={"gray.600"} borderRadius={"md"}>
                    <PaymentIcon
                      type={capitalize(subscriptionMetadata?.card_brand) as any}
                      format="flatRounded"
                      width={40}
                    />
                  </Box>
                  <VStack spacing={0} alignItems={"flex-start"} pl={2}>
                    <Text fontSize={"sm"}>
                      {capitalize(subscriptionMetadata?.card_brand)} ****{" "}
                      {subscriptionMetadata?.card_last_four}
                    </Text>
                  </VStack>
                </HStack>
                <a href={paymentMethod} target="_blank" rel="noreferrer">
                  <Button colorScheme={"blue"}>Manage payment</Button>
                </a>
              </HStack>
            </HStack>
            <Divider />
            <VStack alignItems={"flex-start"} w={"100%"} spacing={6}>
              <HStack w="100%" justifyContent={"space-between"} pb={4} alignItems={"flex-start"}>
                <VStack alignItems={"flex-start"} spacing={1}>
                  <Text fontSize={"lg"}>Billing history</Text>
                  <Text color={"gray.300"} fontSize={"sm"} mb={4}>
                    View your billing history.
                  </Text>
                </VStack>
                <a href={customerPortal} target="_blank" rel="noreferrer">
                  <Button colorScheme={"blue"}>View history</Button>
                </a>
              </HStack>
            </VStack>
          </>
        ) : (
          <Flex w={"100%"} justify={"center"}>
            <PlanCard
              orgId={organization.id}
              planType="Enterprise"
              planAvailable={true}
              showSubscribe={true}
              subscriptionExists={false}
            />
          </Flex>
        )}
      </VStack>
    </Box>
  );
};
