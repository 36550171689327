import React, { useState, useEffect, useRef } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  IconButton,
  Box,
  Button,
  Text,
  HStack,
} from "@chakra-ui/react";
import { CheckCircleIcon, QuestionIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";
import TourModal from "./TourModal";
import "./TourButton.css";
import { Round } from "@icon-park/react";
import { useTutorial } from "../../hooks/useTutorial";

interface TourButtonProps {
  top?: string | number;
  right?: string | number;
  bottom?: string | number;
  left?: string | number;
}

const TourButton: React.FC<TourButtonProps> = ({ top, right, bottom, left }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tourType, setTourType] = useState<"video" | "search" | "results">("video");
  const [showToast, setShowToast] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const hasOpenedModalRef = useRef(false);

  const videoTutorial = useTutorial("video");
  const searchTutorial = useTutorial("search");
  const resultsTutorial = useTutorial("results");

  const handleOpenModal = (type: "video" | "search" | "results") => {
    setTourType(type);
    setIsOpen(true);
    setShowToast(false);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (currentPath === "/search" && !videoTutorial.completed && !hasOpenedModalRef.current) {
      handleOpenModal("video");
      hasOpenedModalRef.current = true;
    }
  }, [currentPath, videoTutorial.completed]);

  useEffect(() => {
    if (
      (currentPath === "/search" && !searchTutorial.completed) ||
      (currentPath.startsWith("/results/") && !resultsTutorial.completed)
    ) {
      setShowToast(true);
    }
  }, [currentPath, searchTutorial.completed, resultsTutorial.completed]);

  const availableTutorials = {
    video: true,
    search: currentPath === "/search",
    results: currentPath.startsWith("/results/"),
  };

  const completedCount = Object.keys(availableTutorials).filter(
    (tutorial) =>
      availableTutorials[tutorial as keyof typeof availableTutorials] &&
      (tutorial === "video"
        ? videoTutorial.completed
        : tutorial === "search"
        ? searchTutorial.completed
        : resultsTutorial.completed)
  ).length;

  const totalCount = Object.values(availableTutorials).filter(Boolean).length;

  return (
    <Box>
      {showToast && (
        <Box
          position={"fixed"}
          bottom={16}
          right={16}
          background={"yellow.200"}
          px={4}
          py={2}
          borderRadius={"full"}
          boxShadow={"lg"}
          zIndex={"overlay"}
        >
          <Text color={"blackAlpha.900"} fontWeight={"semibold"}>
            New tutorial available
          </Text>
        </Box>
      )}

      <Popover placement={"top-start"}>
        <PopoverTrigger>
          <IconButton
            className={completedCount < totalCount ? "tour-button-pulse" : "tour-button"}
            size={"md"}
            colorScheme={"yellow"}
            aria-label={"Tutorial Menu"}
            icon={<QuestionIcon />}
            pos={"fixed"}
            bottom={bottom ?? "auto"}
            right={right ?? "auto"}
            left={left ?? "auto"}
            top={top ?? "auto"}
            zIndex={1000}
            borderRadius={"full"}
            boxShadow={"lg"}
            onClick={() => setShowToast(false)}
          />
        </PopoverTrigger>

        <PopoverContent w={"min-content"}>
          <PopoverHeader fontWeight="bold">Tutorials</PopoverHeader>
          <PopoverBody px={0}>
            <Button
              justifyContent={"start"}
              borderRadius={0}
              onClick={() => handleOpenModal("video")}
              width="100%"
              bg={"transparent"}
              fontWeight={"normal"}
            >
              <HStack w={"100%"} justifyContent={"space-between"}>
                <Text mr={4}>Rivr Walkthrough</Text>
                {videoTutorial.completed ? (
                  <CheckCircleIcon color={"green.200"} />
                ) : (
                  <Round strokeWidth={5} />
                )}
              </HStack>
            </Button>

            {currentPath === "/search" && (
              <Button
                justifyContent={"start"}
                borderRadius={0}
                onClick={() => handleOpenModal("search")}
                width="100%"
                bg={"transparent"}
                fontWeight={"normal"}
              >
                <HStack w={"100%"} justifyContent={"space-between"}>
                  <Text mr={4}>Analyzing Videos</Text>
                  {searchTutorial.completed ? (
                    <CheckCircleIcon color={"green.200"} />
                  ) : (
                    <Round strokeWidth={5} />
                  )}
                </HStack>
              </Button>
            )}
            {currentPath.startsWith("/results/") && (
              <Button
                borderRadius={0}
                onClick={() => handleOpenModal("results")}
                width="100%"
                bg={"transparent"}
                fontWeight={"normal"}
              >
                <HStack w={"100%"} justifyContent={"space-between"}>
                  <Text mr={4}>Results</Text>
                  {resultsTutorial.completed ? (
                    <CheckCircleIcon color={"green.200"} />
                  ) : (
                    <Round strokeWidth={5} />
                  )}
                </HStack>
              </Button>
            )}
          </PopoverBody>
          <PopoverFooter>
            <HStack w={"100%"} justifyContent={"space-between"} alignItems={"center"} px={1}>
              <Text maxW={"fit-content"} color={"gray.300"} fontSize={"sm"}>
                {completedCount}/{totalCount} completed
              </Text>
              {completedCount === totalCount && <CheckCircleIcon color={"green.200"} />}
            </HStack>
          </PopoverFooter>
        </PopoverContent>
      </Popover>

      <TourModal isOpen={isOpen} onClose={handleCloseModal} tourType={tourType} />
    </Box>
  );
};

export default React.memo(TourButton);
