import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  ModalFooter,
  Text,
  Link,
  Alert,
  AlertIcon,
  Box,
} from "@chakra-ui/react";

import PlanCard from "../PlanCard/PlanCard";
import { OrganizationMembership } from "../../models/rivrOrganization";
import { useContext } from "react";
import { AccountContext } from "../../context/AccountContext";

type BillingCtaProps = {
  membership?: OrganizationMembership;
  isOpen: boolean;
  onClose: () => void;
};

const BillingCta = ({ membership, isOpen, onClose }: BillingCtaProps) => {
  const { account } = useContext(AccountContext);

  const role = membership?.role;
  const subscriptionExists =
    membership !== undefined
      ? ((membership.organization.subscriptionStatus !== "none" &&
          membership.organization.subscriptionId !== null) as boolean)
      : ((account.subscription_status !== "none" && account.subscription_id !== null) as boolean);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl" motionPreset="scale" autoFocus>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <VStack spacing={4} alignItems="center">
            {!subscriptionExists ? (
              <Text fontSize="2xl" fontWeight={"semibold"} textAlign={"center"}>
                Subscribe to get started
              </Text>
            ) : (
              <Box w="100%" px={2} pt={2}>
                <Alert
                  status={"error"}
                  fontSize="xl"
                  fontWeight={"semibold"}
                  textAlign={"center"}
                  borderRadius={"md"}
                  justifyContent={"center"}
                  variant={"solid"}
                >
                  <AlertIcon />
                  No active subscription
                </Alert>
              </Box>
            )}

            {membership === undefined ? (
              <PlanCard
                planType={"EA"}
                planAvailable={true}
                showSubscribe={true}
                subscriptionExists={subscriptionExists}
              />
            ) : role === "owner" ? (
              <PlanCard
                orgId={membership.organization.id}
                planType={"Enterprise"}
                planAvailable={true}
                showSubscribe={true}
                subscriptionExists={subscriptionExists}
              />
            ) : (
              <Text textAlign={"center"} sx={{ textWrap: "balance" }}>
                Please contact your organization owner to reactivate your Rivr subscription.
              </Text>
            )}

            {subscriptionExists && role === "owner" && (
              <Text fontSize={"xs"} color={"gray.300"}>
                If you believe there is an error with your subscription, please{" "}
                <Link textDecor={"underline"} href={"mailto:sales@rivr.stream"} target={"_blank"}>
                  contact sales
                </Link>
                .
              </Text>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default BillingCta;
