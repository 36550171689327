import { useState, useRef, useEffect, ChangeEvent, KeyboardEvent } from "react";
import {
  VStack,
  Button,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  useDisclosure,
  Text,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
  WrapItem,
  InputGroup,
  InputRightElement,
  Kbd,
  ModalCloseButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Select,
  IconButton,
  Divider,
  Tooltip,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { DeleteFive, Word } from "@icon-park/react";
import { MentionsSearch } from "../../../../models/mentionsSearch";
import { useSearchParams } from "react-router-dom";
import { CampaignURLParams } from "../../../../models/navigation";
import { ApolloQueryResult, useMutation } from "@apollo/client";
import {
  CreateMentionsSearchMutation,
  RenameMentionsSearchMutation,
  SetMentionsSearchDeleteStatusMutation,
  UpdateMentionsSearchMutation,
} from "../../../../api/mentions-search";

interface Props {
  mentionsSearches: MentionsSearch[];
  fetchMentionsSearches: (
    variables?:
      | Partial<{
          organization_id: string;
        }>
      | undefined
  ) => Promise<ApolloQueryResult<any>>;
  isModalOpen: boolean;
  onModalClose: () => void;
}

const MentionsSearchManager = ({
  mentionsSearches,
  fetchMentionsSearches,
  isModalOpen,
  onModalClose,
}: Props) => {
  const toast = useToast();
  const {
    isOpen: isNewKeywordSetAlertOpen,
    onOpen: onNewKeywordSetAlertOpen,
    onClose: onNewKeywordSetAlertClose,
  } = useDisclosure();
  const {
    isOpen: isRenameAlertOpen,
    onOpen: onRenameAlertOpen,
    onClose: onRenameAlertClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure();

  const cancelRef = useRef<HTMLButtonElement>(null);

  const [keywordSetTitle, setKeywordSetTitle] = useState<string>("");
  const [selectedSearch, setSelectedSearch] = useState<MentionsSearch | null>(null);
  const [showSaveError, setShowSaveError] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");

  const [searchParams] = useSearchParams();
  const selectedOrg = searchParams.get(CampaignURLParams.SelectedOrganization) || "";

  const [mentionsSearchCreateAPI] = useMutation(CreateMentionsSearchMutation, {
    onCompleted() {
      toast({
        title: "New keyword set created",
        description: `${keywordSetTitle} was successfully created.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchMentionsSearches();
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      toast({
        title: "Keyword set not created",
        description: "There was an error encountered while creating the new keyword set.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const [renameMentionsSearchAPI] = useMutation(RenameMentionsSearchMutation, {
    onCompleted() {
      toast({
        title: "Keyword set renamed",
        description: `Keyword set renamed to ${keywordSetTitle}.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchMentionsSearches();
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      toast({
        title: "Keyword set not renamed",
        description: "There was an error encountered while renaming the keyword set.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const [deleteMentionsSearchAPI] = useMutation(SetMentionsSearchDeleteStatusMutation, {
    onCompleted() {
      toast({
        title: "Keyword set deleted",
        description: `${selectedSearch?.title} was successfully deleted.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchMentionsSearches();
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      toast({
        title: "Keyword set not deleted",
        description: "There was an error encountered while deleting the keyword set.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const [updateMentionsSearchAPI] = useMutation(UpdateMentionsSearchMutation, {
    onCompleted() {
      fetchMentionsSearches();
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      toast({
        title: "Keyword set not updated",
        description: "There was an error encountered while updating the keyword set.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleNewKeywordSet = () => {
    if (
      mentionsSearches.map((s) => s.title.toLowerCase()).includes(keywordSetTitle.toLowerCase())
    ) {
      setShowSaveError(true);
      toast({
        title: "Keyword set not created",
        description: "A set with that title already exists.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      const searchCreateVariables = {
        organization_id: selectedOrg,
        title: keywordSetTitle,
        search: "",
        created_at: new Date(Date.now()),
      };
      mentionsSearchCreateAPI({ variables: searchCreateVariables });

      setShowSaveError(false);
      onNewKeywordSetAlertClose();
    }
  };

  const handleSearchSelect = (searchId: string) => {
    const search = mentionsSearches.find((s) => s.id === searchId);
    setSelectedSearch(search ?? mentionsSearches[0]);
  };

  const handleRenameKeywordSet = () => {
    if (selectedSearch === null) {
      return;
    }

    if (
      mentionsSearches.map((s) => s.title.toLowerCase()).includes(keywordSetTitle.toLowerCase())
    ) {
      setShowSaveError(true);
      toast({
        title: "Keyword set not renamed",
        description: "Another set with that title already exists.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      const searchRenameVariables = {
        id: selectedSearch.id,
        title: keywordSetTitle,
        updated_at: new Date(Date.now()),
      };
      renameMentionsSearchAPI({
        variables: searchRenameVariables,
      });

      setShowSaveError(false);
      onRenameAlertClose();
    }
  };

  const handleDeleteKeywordSet = (deleted: boolean) => {
    if (selectedSearch === null) {
      return;
    }

    const currentTime = new Date(Date.now());

    const searchDeleteVariables = {
      id: selectedSearch.id,
      deleted_at: deleted ? currentTime : null,
      updated_at: currentTime,
    };

    deleteMentionsSearchAPI({
      variables: searchDeleteVariables,
    });

    onDeleteAlertClose();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleNewKeywords = (newKeywords: string[]) => {
    if (selectedSearch === null) {
      return;
    }

    const result = [...selectedSearch.keywords];
    for (const kw of newKeywords) {
      if (kw !== "" && !selectedSearch.keywords.includes(kw)) {
        result.push(kw);
      }
    }
    const searchUpdateVariables = {
      id: selectedSearch.id,
      search: buildSearchString(result),
      updated_at: new Date(Date.now()),
    };

    updateMentionsSearchAPI({
      variables: searchUpdateVariables,
    });
  };

  const buildSearchString = (keywords: string[]): string => {
    return keywords.join(" OR ");
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Allow for quoted strings
    if (e.key === '"') {
      if (searchInput.includes('"')) {
        e.preventDefault();
        handleNewKeywords([searchInput + '"']);
        setSearchInput("");
      }
      if (searchInput !== "") {
        e.preventDefault();
      }
    }
    if (e.key === " " || e.key === "Tab" || e.key === "Enter") {
      // Allow for quoted strings
      if (searchInput.includes('"')) {
        if (e.key === "Tab") e.preventDefault();
        return;
      }
      e.preventDefault();
      const trimmed = searchInput.trim();
      const values = trimmed.split(" ");
      if (values.length === 1 && values[0] === "") {
        return;
      } else {
        handleNewKeywords(values);
      }
      setSearchInput("");
    }
  };

  const handleRemoveKeyword = (index: number) => {
    if (selectedSearch === null) {
      return;
    }

    const result = [...selectedSearch.keywords];
    result.splice(index, 1);

    const searchUpdateVariables = {
      id: selectedSearch.id,
      search: buildSearchString(result),
      updated_at: new Date(Date.now()),
    };

    updateMentionsSearchAPI({
      variables: searchUpdateVariables,
    });
  };

  useEffect(() => {
    if (mentionsSearches.length > 0) {
      setSelectedSearch(mentionsSearches[0]);
    } else {
      setSelectedSearch(null);
    }
  }, [mentionsSearches]);

  return (
    <>
      <AlertDialog
        isOpen={isNewKeywordSetAlertOpen}
        onClose={onNewKeywordSetAlertClose}
        leastDestructiveRef={cancelRef}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <VStack w={"100%"} spacing={0} justify={"flex-start"} align={"flex-start"}>
                <Text>New keywords set</Text>
                <Text fontSize={"sm"} color={"gray.300"} fontWeight={"normal"}>
                  Title your new set of keywords.
                </Text>
              </VStack>
            </AlertDialogHeader>
            <AlertDialogBody>
              <Input
                placeholder={"New keyword set title"}
                value={keywordSetTitle}
                onChange={(e) => setKeywordSetTitle(e.target.value)}
                isInvalid={showSaveError}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && keywordSetTitle !== "") {
                    handleNewKeywordSet();
                  }
                }}
              />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                variant={"ghost"}
                onClick={() => {
                  setShowSaveError(false);
                  setKeywordSetTitle("");
                  onNewKeywordSetAlertClose();
                }}
              >
                Close
              </Button>
              <Button
                isDisabled={keywordSetTitle === ""}
                colorScheme={"green"}
                ml={2}
                onClick={handleNewKeywordSet}
              >
                Save
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isRenameAlertOpen}
        onClose={onRenameAlertClose}
        leastDestructiveRef={cancelRef}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <VStack w={"100%"} spacing={0} justify={"flex-start"} align={"flex-start"}>
                <Text>Rename keywords</Text>
                <Text fontSize={"sm"} color={"gray.300"} fontWeight={"normal"}>
                  Rename your set of keywords.
                </Text>
              </VStack>
            </AlertDialogHeader>
            <AlertDialogBody>
              <Input
                placeholder={"New keyword set title"}
                defaultValue={selectedSearch?.title}
                onChange={(e) => setKeywordSetTitle(e.target.value)}
                isInvalid={showSaveError}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && keywordSetTitle !== "") {
                    handleRenameKeywordSet();
                  }
                }}
              />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                variant={"ghost"}
                onClick={() => {
                  setShowSaveError(false);
                  setKeywordSetTitle("");
                  onRenameAlertClose();
                }}
              >
                Close
              </Button>
              <Button
                isDisabled={
                  keywordSetTitle.trim() === "" || keywordSetTitle === selectedSearch?.title
                }
                colorScheme={"blue"}
                ml={2}
                onClick={handleRenameKeywordSet}
              >
                Rename
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteAlertClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize={"lg"} fontWeight={"bold"}>
              Delete keywords
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              Are you sure you want to delete this set of keywords? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onDeleteAlertClose} variant={"ghost"}>
                Cancel
              </Button>
              <Button
                colorScheme={"red"}
                onClick={() => {
                  handleDeleteKeywordSet(true);
                }}
                ml={2}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal
        isOpen={isModalOpen}
        onClose={onModalClose}
        closeOnOverlayClick={false}
        isCentered
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <VStack w={"100%"} spacing={0} justify={"flex-start"} align={"flex-start"}>
              <Text>Manage keywords</Text>
              <Text fontSize={"sm"} color={"gray.300"} fontWeight={"normal"}>
                Create, edit, and delete sets of keywords. Changes are saved automatically.
              </Text>
            </VStack>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody pb={6}>
            <VStack w={"100%"} spacing={4} bg={"gray.800"} p={4} borderRadius={"md"}>
              <HStack w={"100%"}>
                <Tooltip label={"New set of keywords"}>
                  <IconButton
                    aria-label={"New keyword set"}
                    icon={<AddIcon />}
                    colorScheme={"green"}
                    size={"sm"}
                    onClick={() => {
                      setKeywordSetTitle("");
                      onNewKeywordSetAlertOpen();
                    }}
                  />
                </Tooltip>
                <Select
                  value={selectedSearch?.id}
                  size={"sm"}
                  borderRadius={"md"}
                  variant={"filled"}
                  onChange={(e) => {
                    handleSearchSelect(e.target.value);
                  }}
                  disabled={mentionsSearches.length === 0}
                >
                  {mentionsSearches
                    .sort((a, b) => {
                      return new Date(a.updatedAt).valueOf() < new Date(b.updatedAt).valueOf()
                        ? 1
                        : -1;
                    })
                    .map((search) => (
                      <option key={search.id} value={search.id}>
                        {search.title}
                      </option>
                    ))}
                </Select>
                <Tooltip label={"Rename"}>
                  <IconButton
                    aria-label={"Rename selected set of keywords"}
                    icon={<EditIcon />}
                    size={"sm"}
                    colorScheme={"blue"}
                    onClick={onRenameAlertOpen}
                    disabled={selectedSearch === null}
                  />
                </Tooltip>
                <Tooltip label={"Delete"}>
                  <IconButton
                    aria-label={"Delete selected set of keywords"}
                    icon={<DeleteFive size={18} />}
                    size={"sm"}
                    colorScheme={"red"}
                    onClick={onDeleteAlertOpen}
                    disabled={selectedSearch === null}
                  />
                </Tooltip>
              </HStack>
              <Divider />
              <InputGroup size={"sm"}>
                <InputLeftElement pointerEvents={"none"}>
                  <Word style={{ opacity: selectedSearch === null ? 0.4 : 1 }} />
                </InputLeftElement>
                <Input
                  value={searchInput}
                  onChange={handleInputChange}
                  onKeyDown={handleInputKeyDown}
                  placeholder={"Add keywords"}
                  borderRadius={"md"}
                  minH={8}
                  disabled={selectedSearch === null}
                />
                <InputRightElement justifyContent={"flex-end"} mr={2} pointerEvents={"none"}>
                  <HStack spacing={1}>
                    <Kbd opacity={selectedSearch === null ? 0.4 : 1}>Space</Kbd>
                    <Kbd opacity={selectedSearch === null ? 0.4 : 1}>Tab</Kbd>
                    <Kbd opacity={selectedSearch === null ? 0.4 : 1}>Enter</Kbd>
                  </HStack>
                </InputRightElement>
              </InputGroup>
              <Wrap
                mt={"2 !important"}
                pr={2}
                pl={0}
                w={"100%"}
                h={"100%"}
                maxH={80}
                overflowY={"auto"}
                css={{
                  "& > :first-of-type": {
                    margin: 0,
                  },
                }}
              >
                {selectedSearch?.keywords.map((keyword, index) => (
                  <WrapItem key={index} m={0}>
                    <Tag
                      borderRadius={"full"}
                      variant={"solid"}
                      color={"blackAlpha.900"}
                      bg={"green.200"}
                      _hover={{ bg: "green.300" }}
                    >
                      <TagLabel>{keyword}</TagLabel>
                      <TagCloseButton onClick={() => handleRemoveKeyword(index)} />
                    </Tag>
                  </WrapItem>
                ))}
              </Wrap>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MentionsSearchManager;
