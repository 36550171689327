import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button, HStack, Text, IconButton, useBreakpointValue, Tooltip } from "@chakra-ui/react";
import { Analysis, Flag, Lightning, Ranking } from "@icon-park/react";
import Cookies from "js-cookie";
import {
  CampaignSubPages,
  CampaignURLParams,
  RivrLocations,
  updateURLParams,
} from "../../models/navigation";

const CampaignsNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedPage = searchParams.get(CampaignURLParams.SubPage) || CampaignSubPages.Campaigns;
  const isAdmin = Cookies.get("xHasuraRole") === "admin";

  const inCampaigns = location.pathname.startsWith(RivrLocations.Campaigns);
  const isMobile = useBreakpointValue({ base: true, sm: true, md: true, lg: false });

  const handleSubPageSelect = (subPage: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.SubPage, subPage]];

    if (inCampaigns) {
      setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: false });
    } else {
      navigate(`${RivrLocations.Campaigns}?${updateURLParams(searchParams.toString(), newParams)}`);
    }
  };

  const renderCampaignsButton = (subPage: string, label: string, icon: JSX.Element) => {
    if (isMobile) {
      return (
        <Tooltip placement={"bottom"} label={label}>
          <IconButton
            aria-label={label}
            variant={"ghost"}
            size={"sm"}
            icon={icon}
            onClick={() => handleSubPageSelect(subPage)}
            isActive={inCampaigns && selectedPage === subPage}
            colorScheme={inCampaigns && selectedPage === subPage ? "blue" : "gray"}
            fontWeight={"medium"}
            cursor={"pointer"}
          />
        </Tooltip>
      );
    }

    return (
      <Button
        variant={"ghost"}
        size={"sm"}
        fontSize={"sm"}
        onClick={() => handleSubPageSelect(subPage)}
        isActive={inCampaigns && selectedPage === subPage}
        colorScheme={inCampaigns && selectedPage === subPage ? "blue" : "gray"}
        leftIcon={icon}
        fontWeight={"medium"}
        cursor={"pointer"}
      >
        <Text>{label}</Text>
      </Button>
    );
  };

  return (
    <HStack>
      {renderCampaignsButton(CampaignSubPages.Campaigns, "Campaigns", <Flag />)}
      {renderCampaignsButton(CampaignSubPages.Analyze, "Analyze", <Analysis />)}
      {renderCampaignsButton(CampaignSubPages.Discover, "Discover", <Lightning />)}
      {isAdmin && renderCampaignsButton(CampaignSubPages.Report, "Report", <Ranking />)}
    </HStack>
  );
};

export default CampaignsNavigation;
