import { v4 as uuidv4 } from "uuid";
import { formatDateForDisplay, secondsToHMSDuration } from "../utils/time";
import { Close, Comment, Dislike, DoubleUp, Endocrine, Like, Minus, Up } from "@icon-park/react";
import { Box } from "@chakra-ui/react";

export type Shape = "circle" | "square" | "triangle" | "diamond" | "star" | "cross" | "x" | "heart";
export type MomentType = "Recommended" | "User Generated";

export const recommendedMomentSettings: Record<string, any> = {
  Hype: {
    title: "Hype",
    color: "#FAF089",
    colorScheme: "yellow",
    shape: "triangle",
    icon: <Endocrine size={16} />,
  },
  Chat: {
    title: "Chat",
    color: "#38B2AC",
    colorScheme: "teal",
    shape: "square",
    icon: <Comment size={16} />,
  },
  "Chat positive": {
    title: "Chat Positivity",
    color: "#d33682",
    colorScheme: "blue",
    shape: "circle",
    icon: <Like size={16} />,
  },
  "Chat negative": {
    title: "Chat Negativity",
    color: "#268bd2",
    colorScheme: "purple",
    shape: "circle",
    icon: <Dislike size={16} />,
  },
  "Chat intensity": {
    title: "Chat Intensity",
    color: "#9AE6B4",
    colorScheme: "teal",
    shape: "circle",
    icon: <Comment size={16} />,
  },
  "Recommended Moment": {
    title: "Recommended",
    color: "#68D391",
    colorScheme: "blue",
    shape: "circle",
  },
};
const scoreAnnotations: Record<string, number> = {
  Hype: 1.5,
  "Chat positive": 1,
  "Chat negative": 1,
};
const recommendedMomentDefaultLeadIn = 30;
const recommendedMomentDefaultLeadOut = 30;
export const userMomentColor = "#68D391";
export const userMomentShape = "circle";
export const selectedMomentColor = "#C6F6D5";
export const momentOpacity = 0.75;
export const selectedMomentOpacity = 1.0;
export const dimmedMomentOpacity = 0.3;
export const markerBorderColor = "#15151a";
export const markerSize = 22;

type BaseMoment = {
  id: string;
  search_id: number;
  title: string;
  tags: string[];
  start_time: number;
  end_time: number;
  deleted: boolean;
  annotations: Record<string, number>;
};

type BaseUserMoment = BaseMoment & {
  recommended_moment_id?: string;
};

export type Moment = BaseUserMoment & {
  created_at: Date;
  updated_at: Date;
};

export type SerializableMoment = BaseUserMoment & {
  created_at: string;
  updated_at: string;
};

export function momentFromJSON(moment: SerializableMoment): Moment {
  return {
    ...moment,
    created_at: new Date(moment.created_at),
    updated_at: new Date(moment.updated_at),
  };
}

export type RecommendedMoment = BaseMoment & {
  color: string;
  shape: Shape;
  kind: string;
  level: number;
  rejected: boolean;
  time: number;
  created_at: Date;
};

export type RivrMoment = {
  id: string;
  searchId: number;
  channel: string;
  vod: string;
  language: string;
  title: string;
  type: MomentType;
  annotations: Record<string, number>;
  rank: number;
  date: string;
  timestamp: string;
  favorited: boolean;
  archivedAt: string | null;
  url: string;
  // accepted?: boolean;
};

export function recommendedMomentFromEvent(event: any, videoID: string): RecommendedMoment | null {
  const settings = (recommendedMomentSettings as any)[event.title];
  if (!settings) return null;
  const annotations: Record<string, any> = {};
  for (const key in event.annotations)
    if (recommendedMomentSettings[key]) annotations[key] = event.annotations[key];
  const level = Object.keys(scoreAnnotations).reduce(
    (lvl, signal) => lvl + (annotations?.[signal] ?? 0) * scoreAnnotations[signal],
    0
  );
  return {
    id: event.id,
    search_id: Number(videoID),
    title: `${settings.title} Moment`,
    tags: [],
    start_time: Math.max(event.moment_time - recommendedMomentDefaultLeadIn, 0),
    end_time: event.moment_time + recommendedMomentDefaultLeadOut,
    deleted: false,
    color: settings.color,
    shape: settings.shape,
    kind: event.title,
    level,
    rejected: event.rejected,
    annotations,
    time: event.moment_time,
    created_at: new Date(event.created_at),
  };
}

export function momentFromRecommendedMoment(moment: RecommendedMoment): Moment {
  const now = new Date();
  return {
    id: uuidv4(),
    search_id: moment.search_id,
    title: moment.title,
    tags: moment.tags,
    start_time: moment.start_time,
    end_time: moment.end_time,
    deleted: moment.deleted,
    created_at: now,
    updated_at: now,
    recommended_moment_id: moment.id,
    annotations: {},
  };
}

export function rivrMomentFromQueryResult(moment: any, search: any): RivrMoment {
  const annotations: Record<string, any> = {};
  for (const key in moment.annotations)
    if (recommendedMomentSettings[key]) annotations[key] = moment.annotations[key];
  const rank = Object.keys(scoreAnnotations).reduce(
    (rnk, signal) => rnk + (annotations?.[signal] ?? 0) * scoreAnnotations[signal],
    0
  );

  return moment.start_time !== undefined
    ? {
        id: moment.id,
        searchId: moment.search_id,
        channel: search.metadata.user_name,
        vod: search.video_title,
        language: search.metadata.language,
        title: moment.title,
        type: "User Generated",
        annotations: annotations,
        rank: rank,
        date: formatDateForDisplay(new Date(search.metadata.published_at)),
        timestamp: secondsToHMSDuration(moment.start_time),
        favorited: moment.favorited,
        archivedAt: moment.archived_at,
        url: search.url,
      }
    : {
        id: moment.id,
        searchId: moment.search_id,
        channel: search.metadata.user_name,
        vod: search.video_title,
        language: search.metadata.language,
        title: moment.title,
        type: "Recommended",
        annotations: annotations,
        rank: rank,
        date: formatDateForDisplay(new Date(search.metadata.published_at)),
        timestamp: secondsToHMSDuration(
          Math.max(Math.floor(moment.moment_time - recommendedMomentDefaultLeadIn), 0)
        ),
        favorited: moment.favorited,
        archivedAt: moment.archived_at,
        url: search.url,
        // accepted: moment.moments.length > 0,
      };
}

export function getBadgeValue(value: number) {
  if (value > 0.7) return <DoubleUp size={18} strokeWidth={4} />;
  if (value > 0.4) return <Up size={18} strokeWidth={4} />;
  if (value > 0.1)
    return (
      <Box pl={"0.1rem"}>
        <Minus size={18} strokeWidth={4} />
      </Box>
    );
  return (
    <Box pl={0.5}>
      <Close size={13} strokeWidth={5} />
    </Box>
  );
}
