import { useQuery } from "@apollo/client";
import { Box, VStack, Text, HStack } from "@chakra-ui/react";
import { TopUsersThisWeekQuery } from "../../api/stats";
import { userNames } from "../../utils/knownUserIds";
import { allowedUserIds } from "../../utils/allowedUserIds";

function TopUsersThisWeek() {
  const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  const { data } = useQuery(TopUsersThisWeekQuery, {
    variables: {
      timestamp_seven_days_ago: sevenDaysAgo.toISOString().split(".")[0],
      excludedUserIDs: allowedUserIds,
    },
  });

  const userIdCounts = {};

  // Count the occurrences of each user_id
  if (data) {
    data.search.forEach((record) => {
      if (userIdCounts[record.user_id]) {
        userIdCounts[record.user_id]++;
      } else {
        userIdCounts[record.user_id] = 1;
      }
    });
  }

  // Replace user_id values with names if present in the mapping
  const usersWithNames = Object.keys(userIdCounts).map((userId) => ({
    user_id: userNames[userId] || userId,
    count: userIdCounts[userId],
  }));

  // Sort the users by count in descending order
  const sortedUsers = usersWithNames.sort((a, b) => b.count - a.count);

  // Get the top/bottom 10 users
  const topUsers = sortedUsers.slice(0, 10);
  const bottomUsers = sortedUsers.slice(-10);

  return (
    <Box p="6" backgroundColor={"gray.700"} w={"full"} borderRadius={8}>
      <HStack alignItems={"flex-start"} spacing={8}>
        <VStack alignContent={"flex-start"} alignItems={"flex-start"}>
          <Text fontWeight="medium" fontSize="sm" mb={4}>
            Top 10 Searchers (Last 7 Days)
          </Text>
          <VStack spacing={2} alignItems={"flex-start"}>
            {topUsers.map((user, index) => (
              <Box key={index}>
                <Text fontSize="sm">{userNames[user.user_id] || user.user_id}</Text>
                <Text fontSize="sm" color="whiteAlpha.700">
                  Searches: {user.count}
                </Text>
              </Box>
            ))}
          </VStack>
        </VStack>
        <VStack alignContent={"flex-start"} alignItems={"flex-start"}>
          <Text fontWeight="medium" fontSize="sm" mb={4}>
            Bottom 10 Searchers (Last 7 Days)
          </Text>
          <VStack spacing={2} alignItems={"flex-start"}>
            {bottomUsers.map((user, index) => (
              <Box key={index}>
                <Text fontSize="sm">{userNames[user.user_id] || user.user_id}</Text>
                <Text fontSize="sm" color="whiteAlpha.700">
                  Searches: {user.count}
                </Text>
              </Box>
            ))}
          </VStack>
        </VStack>
      </HStack>
    </Box>
  );
}

export default TopUsersThisWeek;
