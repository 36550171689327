import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";
import { Brain, City, KeyOne, Logout, MessageEmoji, People, RoadSign } from "@icon-park/react";
import Cookies from "js-cookie";
import Discord from "../../assets/discord-logo.svg";
import { OrganizationMembership } from "../../models/rivrOrganization";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useContext, useMemo } from "react";
import {
  AccountURLParams,
  CampaignURLParams,
  RivrLocations,
  updateURLParams,
} from "../../models/navigation";
import { AccountContext } from "../../context/AccountContext";
import { useFeedbackModal } from "../../context/FeedbackModalContext";

declare const window: Window & typeof globalThis & { $chatwoot: any };

const rivrVersion = process.env.REACT_APP_VERSION;

const NavMenu = () => {
  const { getFlag, logout } = useKindeAuth();
  const { memberships } = useContext(AccountContext);
  const { onOpen: openFeedbackModal } = useFeedbackModal();
  const navigate = useNavigate();

  const fullLogout = () => {
    if (window.$chatwoot) {
      window.$chatwoot.reset();
    }
    logout();
  };

  const [searchParams] = useSearchParams();
  const selectedOrg = searchParams.get(CampaignURLParams.SelectedOrganization) || "";

  const selectedMembership = useMemo(() => {
    return selectedOrg !== ""
      ? memberships.find((m: OrganizationMembership) => m.organization.id === selectedOrg)
      : memberships[0];
  }, [selectedOrg, memberships]);

  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const demoUser = getFlag("demo-user").value as boolean;
  const isOrgMember = memberships.length > 0;
  const isOrgOwner: boolean = useMemo(() => {
    return selectedMembership !== undefined ? selectedMembership.role === "owner" : false;
  }, [selectedMembership]);

  return (
    <Box>
      <Menu isLazy>
        <MenuButton as={IconButton} icon={<SettingsIcon />} size={"sm"} />
        <MenuList>
          <MenuItem
            onClick={() => {
              navigate(
                `${RivrLocations.Account}?${updateURLParams(searchParams.toString(), [
                  [AccountURLParams.SelectedTab, "main"],
                ])}`
              );
            }}
            icon={<People />}
          >
            Account
          </MenuItem>
          <MenuItem
            hidden={!isOrgOwner}
            onClick={() => {
              navigate(
                `${RivrLocations.Account}?${updateURLParams(searchParams.toString(), [
                  [AccountURLParams.SelectedTab, "org"],
                ])}`
              );
            }}
            icon={<City theme="outline" />}
          >
            Organization
          </MenuItem>
          <MenuDivider />

          {((!demoUser && isOrgMember) || isAdmin) && (
            <MenuItem as="a" target="_blank" href="https://discord.gg/JgP46aS4dT">
              <Image src={Discord} boxSize={3} mr={3} />
              Client Discord
            </MenuItem>
          )}

          {(!isOrgMember || isAdmin) && (
            <MenuItem as="a" target="_blank" href="https://discord.gg/WTkWJKzyQK">
              <Image src={Discord} boxSize={3} mr={3} />
              Discord
            </MenuItem>
          )}
          <MenuItem
            icon={<Brain theme="filled" />}
            onClick={() => navigate(`${RivrLocations.KnowledgeBase}?${searchParams.toString()}`)}
          >
            Knowledge base
          </MenuItem>
          <MenuItem
            icon={<RoadSign />}
            as={"a"}
            href={"https://rivr.stream/roadmap"}
            target={"_blank"}
          >
            Roadmap
          </MenuItem>
          <MenuItem icon={<MessageEmoji />} onClick={openFeedbackModal}>
            Share feedback
          </MenuItem>

          <MenuDivider />

          {isAdmin && (
            <>
              <MenuItem
                icon={<KeyOne />}
                onClick={() => navigate(`${RivrLocations.KeyManager}?${searchParams.toString()}`)}
              >
                Key manager
              </MenuItem>
              <MenuDivider />
            </>
          )}

          <MenuItem icon={<Logout />} onClick={fullLogout}>
            Sign out
          </MenuItem>
          <MenuDivider />
          <Text textAlign={"center"} fontSize={"xs"}>
            Version: {rivrVersion}
          </Text>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default NavMenu;
