import {
  Flex,
  HStack,
  VStack,
  Text,
  Checkbox,
  Center,
  Spinner,
  useToast,
  Box,
  Progress,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  ToastId,
  Badge,
} from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { AddIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import _ from "lodash";
import { MomentItem } from "../MomentItem/MomentItem";
import { FilterPopover, MomentFilter } from "../FilterPopover/FilterPopover";
import { DeleteConfirmation } from "../DeleteConfirmation/DeleteConfirmation";
import ApplyActionSet from "../ApplyActionSet/ApplyActionSet";
import { MoreOne } from "@icon-park/react";
import { ClipRequestsBySearchIDQuery, ClipRequestsByIDQuery } from "../../../api/clip-request";
import { ClipRequestMutation } from "../../../api/actions";
import { Moment } from "../../../models/moment";
import { useInterval } from "../../../hooks/useInterval";
import { Action, defaultAction } from "../../../models/action";
import { VerticalClip, VerticalFormatModal } from "../../VerticalFormatModal/VerticalFormatModal";
import * as amplitude from "@amplitude/analytics-browser";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

type Props = {
  moments: Moment[];
  focusedCallback: (id: string | null) => void;
  focusedItem: string | null;
  deleteMoments: (ids: string[]) => void;
  updateMoment: (moment: Moment, update: Partial<Moment>) => void;
  videoID: string;
  filters: MomentFilter[];
  addFilter: (filter: MomentFilter) => void;
  removeFilter: (filter: MomentFilter) => void;
  videoInfo: any;
  pauseMedia: () => void;
};

type ClipRequest = {
  requestId: number;
  momentId: string;
};

export const UserMoments = (props: Props) => {
  const {
    moments,
    focusedCallback,
    focusedItem,
    deleteMoments,
    updateMoment,
    videoID,
    filters,
    addFilter,
    removeFilter,
    videoInfo,
    pauseMedia,
  } = props;
  const resultFetchInterval = 1000;
  const clipRequestTimeoutSeconds = 300;
  const toast = useToast();
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [downloadTotal, setDownloadTotal] = useState(0);
  const [downloadList, setDownloadList] = useState<number[]>([]);
  const downloadDisabled = false;
  const client = useApolloClient();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const toastIdRef = useRef<ToastId | null>(null);
  const [resetFilter, setResetFilter] = useState(false);
  const [getClipResults, { data }] = useLazyQuery(ClipRequestsByIDQuery);
  const [actionSetMoments, setActionSetMoments] = useState<string[] | null>(null);
  const momentsLoading = videoInfo === null;
  const [verticalFormatClipRequests, setVerticalFormatClipRequests] = useState<ClipRequest[]>([]);
  const [verticalFormatClips, setVerticalFormatClips] = useState<VerticalClip[]>([]);
  const downloadDone = downloadTotal - downloadList.length + verticalFormatClipRequests.length;
  const downloadProgress = (downloadDone / downloadTotal) * 100;
  const { getFlag } = useKindeAuth();
  const demoUser = getFlag("demo-user").value;

  useInterval(
    () => {
      getClipResults({ variables: { ids: downloadList } });
    },
    downloadList.length ? resultFetchInterval : null
  );

  const downloadFile = (url: string) => {
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.target = "_blank";
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  useEffect(() => {
    if (data) {
      let newVerticalFormatClips = verticalFormatClips;
      const found: number[] = [];
      data.clip_request.forEach((element: any) => {
        if (!downloadList.includes(element.id)) return;
        if (element.clip_results.length > 0) {
          const verticalFormatClip = verticalFormatClipRequests.find(
            (r) => r.requestId === element.id
          );
          if (verticalFormatClip)
            newVerticalFormatClips = newVerticalFormatClips.map((c) =>
              c.momentId === verticalFormatClip.momentId
                ? { ...c, url: element.clip_results[0].url }
                : c
            );
          else downloadFile(element.clip_results[0].url);
          found.push(element.id);
        } else if (
          new Date().getTime() - new Date(element.created_at).getTime() >
          clipRequestTimeoutSeconds * 1000
        ) {
          const moment = element.moment?.title ? ` "${element.moment.title}"` : "";
          toast({
            title: `Error while downloading${moment}`,
            description: "Please try again",
            status: "error",
            isClosable: true,
          });
          found.push(element.id);
        }
      });
      setDownloadList(downloadList.filter((id) => !found.includes(id)));
      setVerticalFormatClipRequests(
        verticalFormatClipRequests.filter((r) => !found.includes(r.requestId))
      );
      setVerticalFormatClips(newVerticalFormatClips);
    }
  }, [data]);

  useEffect(() => {
    if (!downloadList.length && toastIdRef.current) {
      toast.close(toastIdRef.current);
      toastIdRef.current = null;
      setDownloadTotal(0);
    }
  }, [downloadList]);

  const renderToast = () => (
    <motion.div
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -50, opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
    >
      <Box
        borderWidth={1}
        boxShadow="2xl"
        p={3}
        borderRadius={"md"}
        bg="gray.700"
        className="po-download-clips"
      >
        <Flex flexDirection="column">
          <HStack justifyContent="space-between" width="100%">
            <Text fontSize="md" fontWeight={"medium"}>
              Generating clips...
            </Text>
            <Box>
              <Text id="txtCounts" fontSize="md" fontWeight={"medium"}>
                {downloadDone}/{downloadTotal}
              </Text>
            </Box>
          </HStack>
          <Box pt={2} pb={1}>
            <Progress
              borderRadius={"full"}
              size="xs"
              colorScheme="green"
              bg="gray.600"
              value={downloadProgress}
              className="po-download-clips-progress"
            />
            <Progress
              isIndeterminate
              borderRadius={"full"}
              size="xs"
              colorScheme="green"
              bg="gray.600"
              className="po-download-clips-progress"
              mt={-1}
            />
          </Box>
          <Text fontSize="sm" color="gray.300">
            Do not refresh or close this window.
          </Text>
        </Flex>
      </Box>
    </motion.div>
  );

  useEffect(() => {
    if (downloadTotal > 0 && !toastIdRef.current) {
      toastIdRef.current = toast({
        position: "top",
        duration: null,
        render: renderToast,
      });
    }
  }, [downloadTotal]);

  useEffect(() => {
    if (toastIdRef.current) {
      toast.update(toastIdRef.current, {
        render: renderToast,
      });
    }
  }, [downloadTotal, downloadDone]);

  const selectActions = (actions: Action[]) => {
    if (actionSetMoments !== null) downloadRequest(actionSetMoments, actions);
  };

  const verticalFormat = (action: Action) => {
    downloadRequest(
      verticalFormatClips.map((v) => v.momentId),
      [action]
    );
  };

  const downloadRequest = async (
    momentIds: string[],
    actions: Action[] = [defaultAction],
    forVerticalFormat = false
  ) => {
    if (momentIds.length === 0) return;
    const downloadMoments: Moment[] = [];
    momentIds.forEach((id) => {
      const moment = moments.find((item) => item.id === id);
      if (moment) downloadMoments.push(moment);
    });
    const response = await client.query({
      query: ClipRequestsBySearchIDQuery,
      variables: {
        id: videoID,
      },
    });
    const clipRequests: any = response && response.data ? response.data.clip_request : [];
    const found: ClipRequest[] = [];
    const notFound: any = [];
    downloadMoments.forEach((moment) => {
      actions.forEach((action) => {
        const requests = clipRequests.filter(
          (request: any) =>
            request.moment?.start_time === moment.start_time &&
            request.moment?.end_time === moment.end_time &&
            request.moment?.title === moment.title &&
            _.isEqual(request.action?.type?.config, action.type.config)
        );
        let requestId = null;
        for (const request of requests) {
          if (request.clip_results.length === 0) {
            if (
              new Date().getTime() - new Date(request.created_at).getTime() <
              clipRequestTimeoutSeconds * 1000
            ) {
              requestId = request.id;
              break;
            }
          } else if (new Date(request.clip_results[0].expires_at) > new Date()) {
            requestId = request.id;
            break;
          }
        }
        if (requestId) {
          found.push({ requestId, momentId: moment.id });
        } else {
          delete (action as any).__typename;
          notFound.push({ moment, action });
        }
      });
    });
    if (notFound.length > 0) {
      const insertResponse = await client.query({
        query: ClipRequestMutation,
        variables: { requests: notFound },
      });
      if (insertResponse && insertResponse.data) {
        insertResponse.data.clipRequest.forEach((element: any) => {
          found.push({ requestId: element.id, momentId: element.moment_id });
        });
      } else {
        if (insertResponse.errors && insertResponse.errors.length > 0) {
          console.log("Insertion Failed", insertResponse.errors[0].message);
        } else {
          console.log("Insertion Failed");
        }
      }
    }
    setDownloadList([...downloadList, ...found.map((f) => f.requestId)]);
    if (forVerticalFormat) setVerticalFormatClipRequests([...verticalFormatClipRequests, ...found]);
    else setDownloadTotal(downloadTotal + found.length);
  };

  useEffect(() => {
    setCheckedItems(checkedItems.filter((id) => moments.some((moment) => moment.id === id)));
  }, [moments]);

  const orderedMoments = [...moments].sort((a, b) => a.start_time - b.start_time);

  const checkCallback = (id: string) => {
    if (checkedItems.includes(id)) setCheckedItems(checkedItems.filter((item) => item !== id));
    else setCheckedItems([...checkedItems, id]);
  };

  const selectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) setCheckedItems(moments.map((result) => result.id));
    else setCheckedItems([]);
  };

  const deselectMoment = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      focusedCallback(null);
    }
  };

  const addFilterInter = (filter: MomentFilter) => {
    setResetFilter(false);
    addFilter(filter);
  };

  const removeFilterInter = (filter: MomentFilter) => {
    setResetFilter(true);
    removeFilter(filter);
  };

  const verticalReformat = (momentId: string) => {
    downloadRequest([momentId], [defaultAction], true);
    setVerticalFormatClips([{ momentId }]);
    pauseMedia();
  };

  const handleAmplitudeClick = (e: string) => {
    amplitude.track(e);
  };

  const canAccessMoments = videoInfo && videoInfo.status === "stopped";

  return (
    <Flex
      className={"results-column"}
      flexDirection={"column"}
      w={"100%"}
      h={"100%"}
      onClick={deselectMoment}
      pt={1}
    >
      {filters.length > 0 && (
        <HStack alignItems={"center"} mt={2}>
          <Text
            color={"gray.300"}
            fontSize={"xs"}
            fontWeight={"medium"}
            textTransform={"uppercase"}
          >
            Filters:
          </Text>
          {filters.map((filter, index) => (
            <Badge
              key={index}
              display={"flex"}
              alignItems={"center"}
              height={"fit-content"}
              mt={2}
              width={"fit-content"}
              colorScheme={"green"}
              variant={"subtle"}
              fontWeight={"medium"}
              borderRadius={"sm"}
              whiteSpace={"normal"}
            >
              {filter.mode === "all" ? "All of" : "Any of"}: {filter.tags.join(", ")}
              <IconButton
                aria-label={"button"}
                colorScheme={"gray"}
                variant={"ghost"}
                minW={"fit-content"}
                h={"fit-content"}
                size={"sm"}
                ml={0.5}
                bg={"transparent"}
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                onClick={() => removeFilterInter(filter)}
                icon={<SmallCloseIcon />}
              />
            </Badge>
          ))}
        </HStack>
      )}
      <ApplyActionSet
        isOpen={actionSetMoments !== null}
        onClose={() => setActionSetMoments(null)}
        headerText={"Apply Action Set"}
        descriptionText={
          "Apply an Action Set to perform all of its Actions on the selected Moments."
        }
        selectLabel={"Action Set"}
        listLabel={"Actions to be performed:"}
        selectActions={selectActions}
      />
      {verticalFormatClips.length > 0 && (
        <VerticalFormatModal
          verticalFormatClips={verticalFormatClips}
          onClose={() => setVerticalFormatClips([])}
          onConfirm={verticalFormat}
        />
      )}
      {!canAccessMoments || moments.length === 0 ? (
        <Center flexDirection="column" height="100%" onClick={() => focusedCallback(null)}>
          {!canAccessMoments ? (
            <>
              <HStack>
                <Text fontSize="lg" fontWeight="medium" textAlign="center">
                  Analyzing...
                </Text>
                <Spinner size="sm" />
              </HStack>
              <Text
                mt="2"
                color="gray.300"
                fontSize="md"
                textAlign={"center"}
                sx={{ textWrap: "balance" }}
              >
                Create Moments by clicking on the Timeline then selecting the{" "}
                <IconButton
                  aria-label="Add Moment Button"
                  colorScheme={"green"}
                  variant="solid"
                  size="xs"
                  icon={<AddIcon />}
                  pointerEvents={"none"}
                />{" "}
                button once analysis has completed.
              </Text>
            </>
          ) : momentsLoading ? (
            <HStack>
              <Text fontSize="lg" fontWeight="medium" textAlign="center">
                Loading...
              </Text>
              <Spinner size="sm" />
            </HStack>
          ) : (
            <>
              <Text fontSize="lg" fontWeight="medium" textAlign={"center"}>
                No Moments
              </Text>
              <Text
                mt="2"
                color="gray.300"
                fontSize="md"
                textAlign={"center"}
                sx={{ textWrap: "balance" }}
              >
                Create Moments by clicking on the Timeline then selecting the{" "}
                <IconButton
                  aria-label="Add Moment Button"
                  colorScheme={"green"}
                  variant="solid"
                  size="xs"
                  icon={<AddIcon />}
                  pointerEvents={"none"}
                />{" "}
                button.
              </Text>
            </>
          )}
        </Center>
      ) : (
        <>
          <HStack flex="0 0 100%" justifyContent="space-between" maxHeight="32px" mt="3">
            <Checkbox
              className="inp-select-all-results"
              w="auto"
              ml="4"
              colorScheme={"teal"}
              size="md"
              color="gray.400"
              onChange={selectAll}
              isChecked={checkedItems.length > 0 && checkedItems.length === moments.length}
            >
              <Text color="gray.400" fontSize="xs" fontWeight="medium" textTransform="uppercase">
                Select all
              </Text>
            </Checkbox>
            <Flex alignItems="center">
              {checkedItems.length > 0 && (
                <Text fontSize="xs" mr="3" fontWeight="medium" color="gray.400">
                  {checkedItems.length} Selected
                </Text>
              )}
              <Box pr={2}>
                <FilterPopover addFilter={addFilterInter} resetFilter={resetFilter} />
              </Box>
              <Menu isLazy>
                <MenuButton
                  isDisabled={checkedItems.length === 0}
                  as={IconButton}
                  className="btn-select-all-results-menu"
                  colorScheme="gray"
                  variant="ghost"
                  size="sm"
                  bg="gray.700"
                  _hover={{ bg: "gray.600" }}
                  _active={{ bg: "gray.600" }}
                  icon={<MoreOne color="currentColor" size={24} />}
                />
                <MenuList>
                  {downloadDisabled ? (
                    <MenuItem
                      opacity="0.3"
                      cursor="not-allowed"
                      className="po-btn-select-all-download"
                    >
                      Download
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={() => {
                        downloadRequest(checkedItems);
                        handleAmplitudeClick("Download Bulk Moment");
                      }}
                      className="po-btn-select-all-download"
                    >
                      Download
                    </MenuItem>
                  )}
                  {!demoUser && (
                    <MenuItem
                      onClick={() => setShowDeleteConfirmation(true)}
                      className="po-btn-select-all-delete-moment"
                    >
                      Delete
                    </MenuItem>
                  )}
                </MenuList>
                <DeleteConfirmation
                  confirm={() => deleteMoments(checkedItems)}
                  isOpen={showDeleteConfirmation}
                  onClose={() => setShowDeleteConfirmation(false)}
                  headerText={"Delete Moments"}
                  bodyText={"Are you sure you want to delete all selected Moments?"}
                />
              </Menu>
            </Flex>
          </HStack>
          <VStack
            className="result-list"
            mt="4"
            overflowY="auto"
            overflowX="hidden"
            flex="1"
            onClick={deselectMoment}
          >
            {orderedMoments.map((moment) => (
              <MomentItem
                key={moment.id}
                inputs={{
                  kind: "UserMoment",
                  moment,
                  downloadDisabled,
                  downloadCallback: () => downloadRequest([moment.id]),
                  deleteMoments,
                  updateMoment,
                  applyActionSet: () => setActionSetMoments([moment.id]),
                  verticalReformat: () => verticalReformat(moment.id),
                }}
                checkCallback={checkCallback}
                focusedCallback={focusedCallback}
                isChecked={checkedItems.some((item) => item === moment.id)}
                isFocused={focusedItem === moment.id}
              />
            ))}
          </VStack>
        </>
      )}
    </Flex>
  );
};
