import { Alert, AlertDescription, AlertIcon, Box, Kbd } from "@chakra-ui/react";
import KbLayout from "../../components/KnowledgeBase/KbLayout";
import { ExternalLinkIcon, SettingsIcon } from "@chakra-ui/icons";
import KbButton from "../../components/KnowledgeBase/KbButton";
import LinkedHeading from "../../components/KnowledgeBase/KbLinkedHeading";
import RelatedArticles from "../../components/KnowledgeBase/KbRelated";
import { KBSubPages, RivrLocations } from "../../models/navigation";
import {
  Analysis,
  CloseOne,
  Flag,
  FullScreenPlay,
  InboxIn,
  Like,
  Save,
  Search,
} from "@icon-park/react";

import { Text, Link, UnorderedList, ListItem } from "../../components/KnowledgeBase/KbElements";

const KbDiscover: React.FC = () => {
  return (
    <KbLayout>
      <Box w={"full"} h={"full"}>
        <LinkedHeading as={"h1"} id={"discover"} py={2}>
          Discover
        </LinkedHeading>
        <Text>
          Discover makes it easy to find value at scale. In the Moments view, uncover standout
          Moments—whether recommended by Rivr or manually created by your team or creators. In the
          Mentions view, effortlessly search for keywords across all videos in your campaign.
        </Text>

        <LinkedHeading as={"h2"} id={"discover-moments"} pt={6} pb={4}>
          Moments
        </LinkedHeading>
        <Text>
          In the Moments view, you can find key video highlights called Moments, which are
          categorized as either Recommended by Rivr or User Generated by members of your team or
          creators. The view provides information for each Moment:
        </Text>
        <UnorderedList spacing={2} pl={6} pb={4}>
          <ListItem>Rank: An indicator of the significance of each Moment.</ListItem>
          <ListItem>Channel: The source channel where the Moment was captured.</ListItem>
          <ListItem>Hype Score: The excitement level associated with the Moment.</ListItem>
          <ListItem>
            Chat Sentiment: Sentiment analysis based on chat interactions during the Moment.
          </ListItem>
          <ListItem>Date: The date when the Moment occurred.</ListItem>
          <ListItem>Timestamp: The exact time within the video where the Moment occured.</ListItem>
          <ListItem>Moment: The title of the Moment.</ListItem>
          <ListItem>
            Type: Identifies if the Moment is Recommended by Rivr or User Generated.
          </ListItem>
          <ListItem>VOD: The title of the video that contains the Moment.</ListItem>
        </UnorderedList>
        <Text>You can apply filters to refine your view, such as:</Text>
        <UnorderedList spacing={2} pl={6} pb={4}>
          <ListItem>Date Range: Select specific time frames to display Moments from.</ListItem>
          <ListItem>Type: Choose between Recommended or User Generated Moments.</ListItem>
          <ListItem>Status: Filter by Favorited or Archived Moments.</ListItem>
          <ListItem>Language: Narrow your search to Moments in specific languages.</ListItem>
        </UnorderedList>

        <LinkedHeading as={"h3"} id={"discover-moments-favorite-archive"} pt={6} pb={4}>
          Favoriting & Archiving
        </LinkedHeading>
        <Text>
          Moments can be marked as Favorited by clicking the favorite{" "}
          <KbButton
            isIconButton={true}
            icon={<Like />}
            colorScheme={"gray"}
            borderRadius={"full"}
          />{" "}
          button on the left-most column of the Moments view.
        </Text>
        <Text>
          Moments can be marked as Archived and hidden by default by clicking the archive{" "}
          <KbButton isIconButton={true} icon={<InboxIn />} colorScheme={"gray"} /> button on the
          right-most column of the Moments view while hovering over a Moment.
        </Text>

        <LinkedHeading as={"h3"} id={"discover-moments-view"} pt={6} pb={4}>
          Viewing Moments
        </LinkedHeading>
        <Text>
          When you click on a Moment, it will take you to the Results page, automatically skipping
          to that part of the video. If the Mini-player is enabled, you can access the Results page
          by clicking the view{" "}
          <KbButton isIconButton={true} icon={<ExternalLinkIcon />} colorScheme={"gray"} /> button
          in the right-most column of the Moments view while hovering over a Moment.
        </Text>

        <LinkedHeading as={"h4"} id={"discover-moments-view-mini-player"} pt={6} pb={4}>
          Mini-player
        </LinkedHeading>
        <Text>
          The Mini-player can be enabled by clicking the mini-player{" "}
          <KbButton
            isIconButton={true}
            icon={<FullScreenPlay theme="filled" />}
            colorScheme={"gray"}
          />{" "}
          button at the top-right corner of the Moments view. Once enabled, clicking on a Moment
          will open a preview window in the bottom-right of the Moments view. In this preview, you
          can scale the player, favorite or archive the Moment, or navigate to the Results page. To
          close the player, click the close{" "}
          <KbButton
            isIconButton={true}
            icon={<CloseOne size={"16px"} />}
            colorScheme={"gray"}
            borderRadius={"full"}
          />{" "}
          button in the top-right corner of the player.
        </Text>

        <LinkedHeading as={"h2"} id={"discover-metions"} pt={6} pb={4}>
          Mentions
        </LinkedHeading>
        <Text>
          The Mentions view allows you to quickly search and identify specific keywords or phrases
          across all the videos in your campaign. This view provides a comprehensive breakdown of
          where the searched keywords are mentioned in your video content, making it easier to track
          important discussions or key terms. The view provides information for each Mention:
        </Text>
        <UnorderedList spacing={2} pl={6} pb={4}>
          <ListItem>
            Rank: Displays the relevance or importance of the Mention based on your search query.
          </ListItem>
          <ListItem>Channel: The source channel where the Mention was found.</ListItem>
          <ListItem>Date: Shows the date of the video containing the Mention.</ListItem>
          <ListItem>Timestamp: The exact time within the video where the Mention occured.</ListItem>
          <ListItem>Type: Whether the Mention is from Speech or a Chat message.</ListItem>
          <ListItem>
            Text: Displays the portion of the speech or chat where the keyword was mentioned, with
            the searched keyword highlighted for easy identification.
          </ListItem>
          <ListItem>VOD: The title of the video that contains the Mention.</ListItem>
        </UnorderedList>
        <Text>You can apply filters to refine your view, such as:</Text>
        <UnorderedList spacing={2} pl={6} pb={4}>
          <ListItem>Date Range: Select specific time frames to display Mentions from.</ListItem>
          <ListItem>Type: Choose between Speech or Chat Mentions.</ListItem>
        </UnorderedList>

        <LinkedHeading as={"h3"} id={"discover-mentions-search"} pt={6} pb={4}>
          Searching Mentions
        </LinkedHeading>
        <Text>
          To search for Mentions, simply enter keywords or phrases into the input field at the top
          of the Mentions view and click the{" "}
          <KbButton leftIcon={<Search />} text={"Search"} colorScheme={"green"} /> button or press{" "}
          <Kbd>Enter</Kbd>. You can enter multiple keywords by pressing <Kbd>Space</Kbd> or{" "}
          <Kbd>Tab</Kbd> after each one.
        </Text>
        <Text>
          The search function includes smart filtering. For example, when searching for the keyword
          "crash," results may include variations like "crashed," "crashing," or "crasher." To find
          exact matches, wrap your keywords in quotes (" "). For example, searching for "video
          games" will return results that contain that exact phrase.
        </Text>
        <Text>
          To clear all search terms from the input, click the{" "}
          <KbButton
            isIconButton={false}
            text={"Clear"}
            borderRadius={"full"}
            variant={"outline"}
            colorScheme={"red"}
          />{" "}
          button.
        </Text>
        <Alert status={"warning"} borderRadius={"md"} mb={8}>
          <AlertIcon />
          <AlertDescription>
            Speech transcriptions can sometimes be inaccurate. When searching, try alternative
            spellings and similar sounding words. For example, brand names like "BEACN" may be
            interpreted as "Beacon," "Beecon," or "bee can."
          </AlertDescription>
        </Alert>

        <LinkedHeading as={"h4"} id={"discover-mentions-search-saving"} pt={6} pb={4}>
          Saving keywords
        </LinkedHeading>
        <Text>
          Sets of keywords can be saved and re-used across campaigns. Any member of your
          organization can access these saved keywords. To save keywords, click the{" "}
          <KbButton leftIcon={<Save />} text={"Save"} colorScheme={"blue"} /> button to the right of
          the search input. You will be prompted to give the keyword set a title. Once saved, you
          can load keywords into the search input by selecting them from the dropdown menu to the
          left of the search input. Note: Selecting a saved set of keywords will overwrite any
          keywords currently in the search input.
        </Text>

        <LinkedHeading as={"h4"} id={"discover-mentions-search-managing-keywords"} pt={6} pb={4}>
          Managing keywords
        </LinkedHeading>
        <Text>
          You can create, edit, and delete sets of keywords by selecting the{" "}
          <KbButton leftIcon={<SettingsIcon />} text={"Manage keywords"} colorScheme={"gray"} />{" "}
          option at the bottom of the saved keywords dropdown menu. This will open the manage
          keywords menu, where you can adjust your sets as needed.
        </Text>

        <LinkedHeading as={"h3"} id={"discover-mentions-view"} pt={6} pb={4}>
          Viewing Mentions
        </LinkedHeading>
        <Text>
          When you click on a Mention, it will take you to the Results page, automatically skipping
          to that part of the video. If the Mini-player is enabled, you can access the Results page
          by clicking the view{" "}
          <KbButton isIconButton={true} icon={<ExternalLinkIcon />} colorScheme={"gray"} /> button
          in the right-most column of the Mentions view while hovering over a Mention.
        </Text>

        <LinkedHeading as={"h4"} id={"discover-mentions-view-mini-player"} pt={6} pb={4}>
          Mini-player
        </LinkedHeading>
        <Text>
          The Mini-player is also available for Mentions. Clicking on a Mention will open a preview
          window in the bottom-right of the Mentions view. In this preview, you can scale the player
          or navigate to the Results page, you can not favorite or archive Mentions. To close the
          player, click the close{" "}
          <KbButton
            isIconButton={true}
            icon={<CloseOne size={"16px"} />}
            colorScheme={"gray"}
            borderRadius={"full"}
          />{" "}
          button in the top-right corner of the player.
        </Text>

        <Box py={6}>
          <Text textAlign={"right"} fontSize={"sm"} pb={0}>
            <em>
              Last updated on Sept. 12, 2024 by{" "}
              <Link href={"https://x.com/marcfromrivr"} isExternal>
                @MarcFromRivr
              </Link>
            </em>
          </Text>
        </Box>
        <RelatedArticles
          articles={[
            {
              title: "Campaigns",
              description: "How to create and manage campaigns",
              icon: Flag,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.OrgCampaigns}`,
            },
            {
              title: "Analyze",
              description: "How to start analyzing videos at scale",
              icon: Analysis,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Analyze}`,
            },
          ]}
        />
      </Box>
    </KbLayout>
  );
};

export default KbDiscover;
