import { useState } from "react";
import {
  Button,
  IconButton,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverCloseButton,
  useDisclosure,
  Wrap,
  WrapItem,
  Tooltip,
  VStack,
  ButtonGroup,
  Divider,
} from "@chakra-ui/react";
import { Filter } from "@icon-park/react";
import { pillOptions } from "../../../models/tags";
import * as amplitude from "@amplitude/analytics-browser";

type Props = {
  addFilter: (filter: MomentFilter) => void;
  resetFilter: boolean;
};

export type MomentFilter = {
  mode: "all" | "one";
  tags: string[];
};

export const FilterPopover = (props: Props) => {
  const { addFilter, resetFilter } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [mode, setMode] = useState<"all" | "one">("all");
  const [tags, setTags] = useState<string[]>([]);

  const openPopover = () => {
    if (resetFilter) {
      setMode("all");
      setTags([]);
    }
    onOpen();
  };

  const createFilter = () => {
    addFilter({ mode, tags });
    onClose();
  };

  const togglePill = (label: string) => {
    if (tags.includes(label)) {
      setTags(tags.filter((tag) => tag !== label));
    } else {
      setTags([...tags, label]);
    }
  };

  const handleAmplitudeClick = (e: string) => {
    amplitude.track(e);
  };

  return (
    <Popover placement="bottom-start" isOpen={isOpen} onClose={onClose} isLazy={true}>
      <PopoverTrigger>
        <Tooltip label="Filter" placement={"right-end"}>
          <IconButton
            aria-label="Create filter"
            className="btn-results-filter"
            colorScheme="gray"
            variant="solid"
            size="sm"
            ml="1"
            onClick={openPopover}
            icon={<Filter theme="filled" color="currentColor" />}
          />
        </Tooltip>
      </PopoverTrigger>
      <PopoverContent width="md" sx={{ inset: "0px auto auto -40px" }}>
        <PopoverHeader>
          <Text color="gray.300" fontSize="xs" fontWeight="medium" textTransform="uppercase">
            Filters
          </Text>
        </PopoverHeader>
        <PopoverCloseButton className="btn-filter-close" />
        <PopoverBody>
          <VStack align={"flex-start"}>
            <Text fontSize={"sm"}>Show Moments containing:</Text>
            <ButtonGroup isAttached size={"sm"} spacing={0}>
              <Button
                colorScheme={mode === "all" ? "blue" : "gray"}
                variant={mode === "all" ? "solid" : "outline"}
                isActive={mode === "all"}
                onClick={() => setMode("all")}
                fontWeight={"normal"}
              >
                All of these tags
              </Button>
              <Button
                colorScheme={mode === "one" ? "blue" : "gray"}
                variant={mode === "one" ? "solid" : "outline"}
                isActive={mode === "one"}
                onClick={() => setMode("one")}
                fontWeight={"normal"}
              >
                Any of these tags
              </Button>
            </ButtonGroup>
            <Divider pt={1} />
          </VStack>
          <Wrap spacing={2} mt="3" mb="2">
            {pillOptions.map((pill) => (
              <WrapItem key={pill.label}>
                <Tooltip label={pill.tooltip}>
                  <Button
                    borderRadius={"full"}
                    size="sm"
                    fontWeight={"normal"}
                    variant={tags.includes(pill.label) ? "solid" : "outline"}
                    colorScheme={tags.includes(pill.label) ? "green" : "gray"}
                    border={tags.includes(pill.label) ? "1px solid transparent" : "1px"}
                    onClick={() => togglePill(pill.label)}
                  >
                    {pill.label}
                  </Button>
                </Tooltip>
              </WrapItem>
            ))}
          </Wrap>
        </PopoverBody>
        <PopoverFooter>
          <Button
            className="btn-filter-add-filter"
            onClick={() => {
              handleAmplitudeClick("Apply Filter to My Moments");
              createFilter();
            }}
            float="right"
            variant="solid"
            colorScheme={"green"}
            size="xs"
            disabled={tags.length === 0}
          >
            Apply filter
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
