export const allowedUserIds = [
  "kp:5f9de7529b0c410480e444cb67e12293",
  "kp:d2c640743b0944f18f13615a5d2c04ad",
  "kp:0a3511112af1445ba992546f0516fc7c",
  "kp:06b0170468324dd8b13b42b56fa4d5fb",
  "kp:d88f6d760727436b8ad2149327d64edc",
  "kp:fa820867c2954a0089236765f072015c",
  "kp:81ff9154ac1b4eebb833a96b58fc634e",
  "kp:ba5fca21d15e4c909a4990b6ae2cbcd1",
  "kp:1aab517d3c4346aa85064402a278befb",
  "kp:ffcdb79fa24e4a5591c38cca4763974e",
  "kp:21ba9d45dd7942c0a9c9d9ced83ba870",
  "kp:1a9e545d459f4fe0a58184347ccd2dcf",
  "kp:57ff4fbca48846c2bd9d0c04ab9f781a",
  "kp:21ba9d45dd7942c0a9c9d9ced83ba870",
  "kp:ff01d0d850be41819626b4b1290b100d",
  "kp:a26e955d3549406a8e6081da4b1916ac",
  "kp:e8f9d7a81d2d4039a49324c12e1e0fe6",
  "kp:e82c3753a52242a0b4271ed9ad7d28b2",

  // Development environment.
  "kp:1f1ab191463345ad877116c5a392ee46",
  "kp:66c0c56fc096441da707c140201d6664",
  "kp:985e2f7d249a4696948411cab3911ba1",
  "kp:2f555c19a03b4ac09dd9729e49ccce77",
  "kp:0dfb6a0f7cd641d09345e75409adc804",
  "kp:d3bee74730124395b864b772c425eb78",
  "kp:99f6f8108e634953a9cd787650ca0567",
  "kp:14f38d61a76b4870b160f133621739d0",
  "kp:2fbe72b3406d47a996d97c81341205f0",
  "kp:c8f6f0464cb34a11a00692633c9ab1a6",

  //Demo users.
  "kp:21ba9d45dd7942c0a9c9d9ced83ba870",
  "kp:9d9384a8b38a4f5f857e722a0fd917b1",
];

// Marc
// Also Marc
// Brandon
// Alex
// Fred
// Matt
// Max
// Steph
// Arnaud
// JSV
// Nolwenn
// Raymond
// Demo User
// Davis
// Lowco
// Liz
