import { gql } from "@apollo/client";

// Queries
export const ActionSetsQuery = gql`
  query ActionSets($user_id: String) {
    action_sets(where: { user_id: { _eq: $user_id } }) {
      id
      title
    }
  }
`;

// Get Action Sets with Actions
export const ActionSetsAggregatedQuery = gql`
  query ActionSetsAggregated($user_id: String) {
    action_sets(where: { user_id: { _eq: $user_id } }) {
      id
      title
      actions_aggregate {
        aggregate {
          count
        }
        nodes {
          id
          title
          type
          output_destination
        }
      }
    }
  }
`;

export const ActionSetByIDQuery = gql`
  query ActionSetByID($id: Int!) {
    action_sets(where: { id: { _eq: $id } }) {
      id
      title
    }
    actions(where: { action_set_id: { _eq: $id } }) {
      id
      type
      title
      output_destination
      action_set_id
    }
  }
`;

// Mutations
export const UpdateActionSetMutation = gql`
  mutation UpdateActionSet(
    $actionSetId: Int!
    $title: String
    $deleteActionIDs: [Int!]
    $createActions: [actions_insert_input!]!
    $updateActions: [actions_updates!]!
  ) {
    update_action_sets(where: { id: { _eq: $actionSetId } }, _set: { title: $title }) {
      affected_rows
    }
    delete_actions(where: { id: { _in: $deleteActionIDs }, action_set_id: { _eq: $actionSetId } }) {
      affected_rows
      returning {
        id
      }
    }
    insert_actions(objects: $createActions) {
      affected_rows
      returning {
        id
      }
    }
    update_actions_many(updates: $updateActions) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

// Delete Actions Set and its Actions
export const DeleteActionSetByIDMutation = gql`
  mutation DeleteActionSetByID($id: Int) {
    delete_actions(where: { action_set_id: { _eq: $id } }) {
      affected_rows
    }
    delete_action_sets(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

// Duplicate Action Set With Actions
export const InsertActionSetWithActionsMutation = gql`
  mutation InsertActionSetWithActions($actionSets: action_sets_insert_input!) {
    insert_action_sets_one(object: $actionSets) {
      id
      title
      user_id
    }
  }
`;
