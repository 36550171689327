import { Table, Tbody, Td, Th, Thead, Tr, Link, TableContainer } from "@chakra-ui/react";
import { AccessKey } from "../../models/keyManager";
import { ExternalLinkIcon } from "@chakra-ui/icons";

interface KeyTableProps {
  data: AccessKey[] | null;
  accounts: { [key: string]: string };
  viewFilter: "all" | "claimed" | "unclaimed";
  keysClaimed: number;
  keysUnclaimed: number;
}

const KeyTable = ({ data, accounts, viewFilter }: KeyTableProps) => {
  const filteredData =
    data?.filter((key) => {
      return (
        viewFilter === "all" ||
        (viewFilter === "claimed" && key.claimed_by !== null) ||
        (viewFilter === "unclaimed" && key.claimed_by === null)
      );
    }) || [];

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime()) || date.getTime() === 0) {
      return "";
    }
    return `${date.toLocaleDateString("en-US", {
      month: "short",
    })}. ${date.getDate()}, ${date.getFullYear()}: ${date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })}`;
  };

  return (
    <TableContainer
      w={"100%"}
      mt={"0 !important"}
      h={"100%"}
      flex={1}
      overflowY={"auto"}
      sx={{ scrollbarWidth: "thin" }}
    >
      <Table variant="striped" size="sm" overflow={"scroll"}>
        <Thead position="sticky" top={0} zIndex={1} bg={"gray.800"}>
          <Tr pointerEvents={"none"}>
            <Th p={2}>Key Name</Th>
            <Th p={2}>Access Key</Th>
            <Th p={2}>Claimed By</Th>
            <Th p={2}>Twitch Username</Th>
            <Th p={2}>Campaign Title</Th>
            <Th p={2}>Campaign ID</Th>
            <Th p={2} isNumeric>
              Duration
            </Th>
            <Th p={2} isNumeric>
              Claimed At
            </Th>
            <Th p={2} isNumeric>
              Expires At
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredData.map((key) => (
            <Tr key={key.access_key}>
              <Td px={2}>{key.key_name}</Td>
              <Td px={2} fontFamily="mono" textTransform="uppercase">
                {key.access_key}
              </Td>
              <Td px={2} fontFamily="mono" textTransform="uppercase">
                {key.claimed_by}
              </Td>
              <Td px={2}>
                {accounts[key.claimed_by] ? (
                  <>
                    <Link
                      href={`https://www.twitch.tv/${accounts[key.claimed_by]}`}
                      isExternal
                      _hover={{ color: "blue.300", textDecor: "underline" }}
                    >
                      {accounts[key.claimed_by]}
                      <ExternalLinkIcon ml={1.5} mb={0.5} boxSize={3} />
                    </Link>
                  </>
                ) : (
                  ""
                )}
              </Td>
              <Td px={2} overflow={"hidden"} textOverflow={"ellipsis"} title={key.campaign_title}>
                {key.campaign_title}
              </Td>
              <Td px={2} fontFamily="mono" textTransform="uppercase">
                {key.campaign_id}
              </Td>
              <Td px={2} isNumeric>
                {key.active_duration}
              </Td>
              <Td px={2} isNumeric>
                {formatDate(key.claimed_at)}
              </Td>
              <Td px={2} isNumeric>
                {formatDate(key.expires_at)}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default KeyTable;
