import Clip from "../assets/clips.svg";

export const getActionTypes = () => {
  const actionTypes = [
    {
      title: "Clip",
      image: Clip,
      description: "MP4 video clips in 16:9 or 9:16",
    },
  ];

  return actionTypes;
};
