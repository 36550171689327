import { useQuery } from "@apollo/client";
import { Stat, StatLabel, StatNumber, StatHelpText, Box } from "@chakra-ui/react";
import { SearchesThisMonthQuery } from "../../api/stats";
import { allowedUserIds } from "../../utils/allowedUserIds";

function SearchesThisMonth() {
  // Get the timestamp for 7 days ago
  const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
  const { data } = useQuery(SearchesThisMonthQuery, {
    variables: {
      timestamp_thirty_days_ago: thirtyDaysAgo.toISOString().split(".")[0],
      excludedUserIDs: allowedUserIds,
    },
  });

  // Add check for data and data.search before trying to access data.search.length
  const numSearchesThisMonth = data && data.search ? data.search.length : 0;

  return (
    <Box p="6" backgroundColor={"gray.700"} w={"full"} borderRadius={8}>
      <Stat>
        <StatLabel>Searches This Month</StatLabel>
        <StatNumber>{numSearchesThisMonth}</StatNumber>
        <StatHelpText mb="0">In the last 30 days</StatHelpText>
      </Stat>
    </Box>
  );
}

export default SearchesThisMonth;
