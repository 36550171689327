import { useState, useContext } from "react";
import {
  VStack,
  Box,
  HStack,
  Badge,
  Text,
  Icon,
  List,
  ListItem,
  Tooltip,
  Button,
  ButtonGroup,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { RocketOne } from "@icon-park/react";
import { PlanType, planDetails } from "../../models/plan";
import { Loading } from "../Loading/Loading";
import { AccountContext } from "../../context/AccountContext";
import { PaymentPortalQuery } from "../../api/actions";
import { useQuery } from "@apollo/client";
import * as amplitude from "@amplitude/analytics-browser";

type PlanCardProps = {
  orgId?: string;
  planType: PlanType;
  planAvailable: boolean;
  showSubscribe: boolean;
  subscriptionExists: boolean;
  displayVariant?: "monthly" | "yearly";
};

const PlanCard = ({
  orgId,
  planType,
  planAvailable,
  showSubscribe,
  subscriptionExists,
  displayVariant,
}: PlanCardProps) => {
  const selectedPlan = planDetails[planType];
  const [isYearly, setIsYearly] = useState(
    displayVariant === undefined ? !Boolean(selectedPlan.monthly) : displayVariant === "yearly"
  );
  const { account } = useContext(AccountContext);

  const { loading, data: urls } = useQuery(PaymentPortalQuery, {
    variables: orgId ? { org: orgId } : {},
    skip: !subscriptionExists || !showSubscribe,
  });

  if (loading) return <Loading variant={"small"} />;

  return (
    <VStack w={"100%"} align={"center"} maxW={"32rem"} spacing={4}>
      {selectedPlan.monthly && selectedPlan.yearly && !displayVariant && (
        <HStack>
          <ButtonGroup size="sm" isAttached variant="outline">
            <Button onClick={() => setIsYearly(false)} isActive={!isYearly}>
              Monthly
            </Button>
            <Button onClick={() => setIsYearly(true)} isActive={isYearly}>
              Yearly
              <Badge colorScheme="green" ml={2} py={0.5}>
                17% Off
              </Badge>
            </Button>
          </ButtonGroup>
        </HStack>
      )}
      <Box
        bg="blackAlpha.500"
        borderRadius={"lg"}
        h="100%"
        p={6}
        borderWidth={2}
        borderColor={selectedPlan.borderColor}
        w={"100%"}
      >
        <VStack alignItems={"flex-start"} spacing={4} flex={1}>
          <Badge
            colorScheme={selectedPlan.badgeColor}
            fontSize={16}
            letterSpacing={"widest"}
            w={"100%"}
            textAlign={"center"}
            borderRadius={"md"}
            flexWrap={"wrap"}
          >
            {selectedPlan.planHeader}
          </Badge>
          <HStack justifyContent={"space-between"} alignItems={"center"} w={"100%"}>
            <VStack spacing={-1} py={3} align={"flex-start"}>
              <Text fontSize={32} fontWeight={"semibold"}>
                {selectedPlan.planName}
              </Text>
              <Text fontSize={"xs"} color={"gray.400"}>
                {selectedPlan.planNameSub}
              </Text>
            </VStack>
            {planAvailable && (
              <VStack spacing={-1} alignItems={"flex-end"}>
                <HStack>
                  <Text
                    fontSize={20}
                    fontWeight={"semibold"}
                    textDecoration={"line-through"}
                    color="gray.400"
                  >
                    {isYearly
                      ? selectedPlan.yearly?.price.previous
                      : selectedPlan.monthly?.price.previous}
                  </Text>
                  <Text fontSize={32} fontWeight={"semibold"}>
                    {isYearly
                      ? selectedPlan.yearly?.price.regular
                      : selectedPlan.monthly?.price.regular}
                  </Text>
                </HStack>
                <Text fontSize={"xs"} color={"gray.400"}>
                  billed {isYearly ? "yearly" : "monthly"}
                </Text>
              </VStack>
            )}
          </HStack>
          <List spacing={2}>
            {selectedPlan.features.map((feature, index) => (
              <ListItem key={index}>
                <HStack alignItems={"flex-start"} justifyContent={"flex-start"}>
                  <Icon as={CheckCircleIcon} color="green.300" boxSize={4} mt={1} />
                  {feature.tooltip ? (
                    <Tooltip label={feature.tooltip} placement="right" hasArrow>
                      <Text textDecoration={"underline dotted gray"} textUnderlineOffset={5}>
                        {feature.name}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text>{feature.name}</Text>
                  )}
                </HStack>
              </ListItem>
            ))}
          </List>
        </VStack>
      </Box>
      {showSubscribe &&
        (subscriptionExists ? (
          <Button
            colorScheme={"green"}
            onClick={() => {
              window.open(urls?.getPaymentPortal.customerPortal, "_blank");
            }}
            size={"lg"}
            w={"full"}
            minH={12}
          >
            Manage subscription
          </Button>
        ) : (
          <Button
            colorScheme={"green"}
            onClick={() => {
              window.location.href = `${
                isYearly ? selectedPlan.yearly?.url : selectedPlan.monthly?.url
              }${orgId ?? account.id}`;
              amplitude.track("Subscribe Button Clicked");
            }}
            leftIcon={<RocketOne theme="filled" />}
            size={"lg"}
            w={"full"}
            minH={12}
          >
            Subscribe to {selectedPlan.planName} {selectedPlan.planNameSub}
          </Button>
        ))}
    </VStack>
  );
};

export default PlanCard;
