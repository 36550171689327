export type MentionType = "Speech" | "Chat";

export type RivrMention = {
  rank: number;
  channel: string;
  date: string;
  timestamp: number;
  type: MentionType;
  text: string;
  vod: string;
  searchId: number;
  url: string;
};

export function speakerMentionFromQueryResult(mention: any): RivrMention {
  return {
    rank: mention.rank,
    channel: mention.search[0].user_name,
    date: mention.search[0].published_at,
    timestamp: mention.start_time,
    type: "Speech",
    text: mention.line,
    vod: mention.search[0].video_title,
    searchId: mention.search[0].id,
    url: mention.search[0].url,
  };
}

export function getMaxRank(mentions: RivrMention[]): number {
  return mentions.reduce((max, mention) => Math.max(max, mention.rank), 0);
}
