import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import Plotly from "plotly.js-dist-min";
import { secondsToDate, dateToSeconds } from "../../utils/time";
import {
  Moment,
  RecommendedMoment,
  dimmedMomentOpacity,
  momentOpacity,
  selectedMomentColor,
  selectedMomentOpacity,
  userMomentColor,
  userMomentShape,
} from "../../models/moment";
import { DragHandleIcon } from "@chakra-ui/icons";
import { signals, signalMap, signalReplacements } from "../../models/signals";
import { Marker } from "./Marker";
import { SelectedMoment } from "../../models/selectedMoment";
import { useToast } from "@chakra-ui/react";
import { useTriggerInit } from "../../hooks/useTriggerInit";

type Props = {
  signalData: Map<string, { x: Date[]; y: number[] }>;
  graphSeekCallback: (seconds: number) => void;
  moments: Moment[];
  recommendedMoments: RecommendedMoment[];
  playTime: Date;
  momentClickCallback: (selectedMoment: SelectedMoment | null) => void;
  selectedMoment: Pick<Moment, "id" | "start_time" | "end_time"> | null;
  selectedMomentType: "temporary" | "user" | "recommended" | null;
  updateSelectedMoment: (update: Pick<Moment, "start_time" | "end_time">) => void;
  isSearchStopped: boolean;
};

type Handle = "left" | "middle" | "right";

function TimelinePlot(props: Props) {
  const {
    signalData,
    graphSeekCallback,
    moments,
    recommendedMoments,
    playTime,
    momentClickCallback,
    selectedMoment,
    selectedMomentType,
    updateSelectedMoment,
    isSearchStopped,
  } = props;
  const [minMomentMillis, maxMomentMillis] = [1000, 600000];
  const zeroPoint = new Date(0, 0);
  const zeroTime = zeroPoint.getTime();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const plotRef = useRef<HTMLDivElement | null>(null);
  const drawRef = useRef<HTMLElement | null>(null);
  const previousMomentRef = useRef<HTMLDivElement | null>(null);
  const [movingHandle, setMovingHandle] = useState<Handle | null>(null);
  const [leftHandlePos, setLeftHandlePos] = useState(zeroPoint);
  const [rightHandlePos, setRightHandlePos] = useState(zeroPoint);
  const [handleOffsetMillis, setHandleOffsetMillis] = useState(0);
  const [moveStartMillis, setMoveStartMillis] = useState(0);
  const plotBoundingRect = plotRef.current?.getBoundingClientRect();
  const drawBoundingRect = drawRef.current?.getBoundingClientRect();
  const leftHandleDisplay =
    (movingHandle === "left" || movingHandle === "middle") && selectedMomentType !== "recommended"
      ? new Date(leftHandlePos.getTime() + handleOffsetMillis)
      : leftHandlePos;
  const rightHandleDisplay =
    (movingHandle === "right" || movingHandle === "middle") && selectedMomentType !== "recommended"
      ? new Date(rightHandlePos.getTime() + handleOffsetMillis)
      : rightHandlePos;
  const clamp = (min: number, value: number, max: number) => Math.min(Math.max(min, value), max);
  const dataRange = useRef(0);
  const [isPlotReady, setIsPlotReady] = useState(false);
  const [yRange, setYRange] = useState<[number, number]>([0, 0]);
  const [availableSignals, setAvailableSignals] = useState<string[]>([]);
  const toast = useToast();

  useEffect(() => {
    new ResizeObserver(() => Plotly.Plots.resize(plotRef.current as HTMLDivElement)).observe(
      plotRef.current as HTMLDivElement
    );
  }, []);

  useEffect(() => {
    if (!plotRef.current) return;

    const colorMode = localStorage.getItem("colorMode");

    const data = signals.map((signal) => ({
      x: [],
      y: [],
      mode: "lines",
      type: "scattergl",
      name: signal[1].display,
      line: {
        color:
          colorMode === "alt1"
            ? signal[1].colorAlt1
            : colorMode === "alt2"
            ? signal[1].colorAlt2
            : signal[1].color,
        width: 1.15,
      },
      hoverinfo: "none",
      visible: signal[1].visible ? null : "legendonly",
    }));

    const layout = {
      showlegend: true,
      uirevision: true,
      paper_bgcolor: "transparent",
      plot_bgcolor: "transparent",
      autosize: true,
      modebar: {
        orientation: "h",
      },
      dragmode: "pan",
      xaxis: {
        color: "white",
        nticks: 10,
        tickangle: 0,
        gridcolor: "#FFFFFF30",
        zeroline: true,
        tickformat: "%H:%M:%S",
        tickfont: {
          family:
            "'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'",
          size: 13,
          color: "white",
        },
      },
      yaxis: {
        color: "white",
        fixedrange: true,
        gridcolor: "#FFFFFF30",
        tickfont: {
          family:
            "'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'",
          size: 13,
          color: "white",
        },
      },
      margin: {
        l: 40,
        r: 0,
        t: 16,
        b: 24,
        pad: 8,
      },
      legend: {
        traceorder: "reversed",
        font: {
          color: "white",
          family:
            "'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'",
          size: 13,
        },
        y: 0.5,
      },
    };

    const config = {
      displayModeBar: true,
      responsive: true,
      displayLogo: false,
      modeBarButtonsToRemove: ["lasso2d", "select2d", "resetScale"],
      scrollZoom: true,
    };

    Plotly.newPlot(plotRef.current, data as any, layout as any, config as any).then(() => {
      drawRef.current = document.querySelector(".nsewdrag") as HTMLElement;
      (plotRef.current as any).on("plotly_afterplot", constrainOnData);
      (plotRef.current as any).on("plotly_relayouting", forceUpdate);
      setIsPlotReady(true);
    });
    return () => {
      if (plotRef.current) Plotly.purge(plotRef.current);
    };
  }, []);

  useEffect(() => {
    if (drawRef.current) drawRef.current.addEventListener("click", handleClick);
    return () => {
      drawRef.current?.removeEventListener("click", handleClick);
    };
  }, [graphSeekCallback, isPlotReady]);

  function parsePlotlyDate(date: string | Date) {
    if (date instanceof Date) return date;
    if (date.includes(" ")) return new Date(date);
    return new Date(date + " ");
  }

  function constrainOnData() {
    if (plotRef.current && dataRange.current) {
      const xaxis = (Plotly as any).makeTemplate(plotRef.current).layout.xaxis;
      if (!xaxis.autorange) {
        const range = xaxis.range;
        const left = parsePlotlyDate(range[0]);
        const right = parsePlotlyDate(range[1]);
        const timeRange = right.getTime() - left.getTime();
        let update = null;
        if (timeRange >= dataRange.current) {
          update = { "xaxis.autorange": true };
        } else if (left < zeroPoint) {
          const max = new Date(zeroTime + timeRange);
          update = { "xaxis.range": [zeroPoint, max] as [Date, Date] };
        } else if (right.getTime() - zeroTime > dataRange.current) {
          const min = new Date(zeroTime + dataRange.current - timeRange);
          update = { "xaxis.range": [min, new Date(zeroTime + dataRange.current)] as [Date, Date] };
        }
        if (update) Plotly.relayout(plotRef.current, update);
      }
    }
    forceUpdate();
  }

  useTriggerInit(() => {
    if (selectedMoment) {
      const startTime = secondsToDate(selectedMoment.start_time);
      const endTime = secondsToDate(selectedMoment.end_time);
      setLeftHandlePos(startTime);
      setRightHandlePos(endTime);
    }
  }, [selectedMoment]);

  // Zoom in when a moment is selected
  useEffect(() => {
    if (selectedMoment && selectedMoment.id !== "temp" && plotRef.current) {
      const startTime = secondsToDate(selectedMoment.start_time);
      const endTime = secondsToDate(selectedMoment.end_time);
      const duration = selectedMoment.end_time - selectedMoment.start_time;
      const xMin = new Date(startTime.getTime() - duration * 1000);
      const xMax = new Date(endTime.getTime() + duration * 1000);
      const update = { "xaxis.range": [xMin, xMax] as [Date, Date] };
      Plotly.relayout(plotRef.current, update);
    }
  }, [selectedMoment && selectedMoment.id]);

  const graphLimitTimes: { left: Date; right: Date } | null = drawBoundingRect
    ? {
        left: getPlotCoord(drawBoundingRect.left),
        right: getPlotCoord(drawBoundingRect.right),
      }
    : null;

  const startMoving = (event: React.MouseEvent, handle: Handle) => {
    setMoveStartMillis(getPlotCoord(event.clientX).getTime());
    setMovingHandle(handle);
    setHandleOffsetMillis(0);
  };

  useEffect(() => {
    if (!movingHandle) return;

    const resizeBox = (event: MouseEvent) => {
      if (!graphLimitTimes) return;

      const graphLeftMillis = graphLimitTimes.left.getTime();
      const graphRightMillis = graphLimitTimes.right.getTime();
      const leftHandleMillis = leftHandlePos.getTime();
      const rightHandleMillis = rightHandlePos.getTime();

      let moveMillis = getPlotCoord(event.x).getTime() - moveStartMillis;
      let leftMinLimits = null;
      let leftMaxLimits = null;
      let rightMinLimits = null;
      let rightMaxLimits = null;

      if (movingHandle === "left") {
        leftMinLimits = [graphLeftMillis, rightHandleMillis - maxMomentMillis, zeroTime];
        leftMaxLimits = [graphRightMillis, rightHandleMillis - minMomentMillis];
      } else if (movingHandle === "middle") {
        leftMinLimits = [zeroTime];
        leftMaxLimits = [graphRightMillis];
        rightMinLimits = [graphLeftMillis];
        rightMaxLimits = [zeroTime + dataRange.current];
      } else {
        rightMinLimits = [graphLeftMillis, leftHandleMillis + minMomentMillis];
        rightMaxLimits = [
          graphRightMillis,
          leftHandleMillis + maxMomentMillis,
          zeroTime + dataRange.current,
        ];
      }

      if (leftMinLimits)
        moveMillis = Math.max(Math.max(...leftMinLimits) - leftHandleMillis, moveMillis);
      if (leftMaxLimits)
        moveMillis = Math.min(Math.min(...leftMaxLimits) - leftHandleMillis, moveMillis);
      if (rightMinLimits)
        moveMillis = Math.max(Math.max(...rightMinLimits) - rightHandleMillis, moveMillis);
      if (rightMaxLimits)
        moveMillis = Math.min(Math.min(...rightMaxLimits) - rightHandleMillis, moveMillis);

      setHandleOffsetMillis(moveMillis);
    };

    const stopHandleMove = (event: MouseEvent) => {
      if (handleOffsetMillis === 0) {
        // Click handler
        const seekDate = getPlotCoord(event.x);
        graphSeekCallback((seekDate.getTime() - zeroTime) / 1000);
      } else {
        // Drag handler
        if (selectedMomentType === "recommended")
          toast({
            title: "Can't modify Recommended Moment",
            description: "Add to My Moments to modify",
            status: "error",
          });
        else
          updateSelectedMoment({
            start_time: dateToSeconds(leftHandleDisplay),
            end_time: dateToSeconds(rightHandleDisplay),
          });
      }
      setMovingHandle(null);
    };

    document.body.style.cursor = "grabbing";
    document.body.style.userSelect = "none";
    window.addEventListener("mousemove", resizeBox);
    window.addEventListener("mouseup", stopHandleMove);

    return () => {
      document.body.style.cursor = "auto";
      document.body.style.userSelect = "initial";
      window.removeEventListener("mousemove", resizeBox);
      window.removeEventListener("mouseup", stopHandleMove);
    };
  }, [movingHandle, handleOffsetMillis]);

  function getPlotCoord(screenX: number) {
    if (!plotRef.current || !drawRef.current) return zeroPoint;
    const plotPosition = screenX - drawRef.current.getBoundingClientRect().left;
    return new Date((plotRef.current as any)._fullLayout.xaxis.p2d(plotPosition));
  }

  function getRelativeCoord(value: Date | number, axis: "x" | "y") {
    if (!plotRef.current || !drawBoundingRect || !plotBoundingRect) return 0;
    if (axis === "x") {
      const coord = Math.round((plotRef.current as any)._fullLayout.xaxis.d2p(value));
      return coord + drawBoundingRect.left - plotBoundingRect.left;
    } else {
      const coord = Math.round((plotRef.current as any)._fullLayout.yaxis.d2p(value));
      return coord + drawBoundingRect.top - plotBoundingRect.top;
    }
  }

  function handleClick(event: MouseEvent) {
    const seekDate = getPlotCoord(event.x);
    graphSeekCallback((seekDate.getTime() - zeroTime) / 1000);
  }

  useEffect(() => {
    if (!plotRef.current) return;
    const update: any = {
      x: [],
      y: [],
    };
    const traces: number[] = [];
    let newRange = yRange;
    const dataSignals = [...availableSignals];
    signalData.forEach((_value, key) => {
      if (!dataSignals.includes(key)) dataSignals.push(key);
    });
    signalData.forEach((value, key) => {
      const signal = signalMap.get(key);
      if (signal !== undefined) {
        const replacement = signalReplacements.get(key);
        if (!replacement || (isSearchStopped && !dataSignals.includes(replacement))) {
          update.x.push(value.x);
          update.y.push(value.y);
          dataRange.current = Math.max(
            dataRange.current,
            (value.x[value.x.length - 1] as Date).getTime() - zeroTime
          );
          traces.push(signal.index);
          newRange = [
            Math.min(newRange[0], signal.range[0]),
            Math.max(newRange[1], signal.range[1]),
          ];
        }
      }
    });
    if (traces.length) Plotly.restyle(plotRef.current, update, traces);
    if (newRange[0] < yRange[0] || newRange[1] > yRange[1]) {
      setYRange(newRange);
      const update = {
        "yaxis.range": newRange,
        "yaxis.dtick": Math.min(1, (newRange[1] - newRange[0]) / 8),
      };
      Plotly.relayout(plotRef.current, update);
    }
    if (dataSignals.length !== availableSignals.length) setAvailableSignals(dataSignals);
  }, [signalData]);

  const handleMomentScroll = (event: WheelEvent) => {
    const newEvent = new WheelEvent("wheel", {
      clientX: event.clientX,
      clientY: event.clientY,
      deltaY: event.deltaY,
    });
    drawRef.current?.dispatchEvent(newEvent);
    event.preventDefault();
  };

  const momentRef = useCallback((node: HTMLDivElement | null) => {
    previousMomentRef.current?.removeEventListener("wheel", handleMomentScroll);
    previousMomentRef.current = node;
    previousMomentRef.current?.addEventListener("wheel", handleMomentScroll);
  }, []);

  let playPos = null;
  const clipBox: any = { show: false };
  const momentMarkers: any[] = [];
  let top = null;
  let height = null;
  let zero: number | null = null;
  if (graphLimitTimes) {
    const isInGraph = (time: Date) => time >= graphLimitTimes.left && time <= graphLimitTimes.right;

    const clampInGraph = (time: Date) =>
      new Date(
        clamp(graphLimitTimes.left.getTime(), time.getTime(), graphLimitTimes.right.getTime())
      );

    const calculateClipBoxSide = (handlePos: Date) => ({
      showHandle: isInGraph(handlePos),
      coord: getRelativeCoord(clampInGraph(handlePos), "x"),
    });

    if (isInGraph(playTime)) playPos = getRelativeCoord(playTime, "x");

    if (selectedMoment) {
      clipBox.left = calculateClipBoxSide(leftHandleDisplay);
      clipBox.right = calculateClipBoxSide(rightHandleDisplay);
      clipBox.width = Math.max(clipBox.right.coord - clipBox.left.coord, 2);
      clipBox.show =
        clipBox.left.showHandle ||
        clipBox.right.showHandle ||
        (leftHandleDisplay < graphLimitTimes.left && rightHandleDisplay > graphLimitTimes.right);
    }

    moments.forEach((moment) => {
      const momentDate = secondsToDate((moment.start_time + moment.end_time) / 2);
      if (isInGraph(momentDate)) {
        momentMarkers.push({
          pos: getRelativeCoord(momentDate, "x"),
          id: moment.id,
          title: moment.title,
          color: moment.id === selectedMoment?.id ? selectedMomentColor : userMomentColor,
          shape: userMomentShape,
          opacity:
            moment.id === selectedMoment?.id
              ? selectedMomentOpacity
              : recommendedMoments.length
              ? dimmedMomentOpacity
              : momentOpacity,
          kind: "user",
        });
      }
    });

    recommendedMoments.forEach((moment) => {
      const momentDate = secondsToDate((moment.start_time + moment.end_time) / 2);
      if (isInGraph(momentDate)) {
        momentMarkers.push({
          pos: getRelativeCoord(momentDate, "x"),
          id: moment.id,
          title: moment.title,
          color: moment.id === selectedMoment?.id ? selectedMomentColor : moment.color,
          shape: moment.shape,
          opacity: moment.id === selectedMoment?.id ? selectedMomentOpacity : momentOpacity,
          kind: "recommended",
        });
      }
    });

    if (playPos !== null || clipBox.show) {
      top = getRelativeCoord(yRange[1], "y");
      height = getRelativeCoord(yRange[0], "y") - top;
    }
    if (momentMarkers.length) zero = getRelativeCoord(0, "y");
  }

  return (
    <div style={{ position: "relative", height: "100%", minHeight: "200px" }}>
      <div ref={plotRef} style={{ width: "100%", height: "100%" }}></div>
      {playPos !== null && (
        <div
          className="play-marker"
          style={{
            left: `${playPos}px`,
            top: `${top}px`,
            height: `${height}px`,
          }}
        ></div>
      )}
      {momentMarkers.map((marker) => (
        <Marker
          key={marker.id}
          onClick={() => momentClickCallback({ kind: marker.kind, id: marker.id })}
          info={marker}
          zero={zero || 0}
        />
      ))}
      {clipBox.show && (
        <div
          className="moment-box"
          style={{
            left: `${clipBox.left.coord}px`,
            width: `${clipBox.width}px`,
            top: `${top}px`,
            height: `${height}px`,
            cursor:
              selectedMomentType === "recommended"
                ? "not-allowed"
                : movingHandle
                ? "grabbing"
                : "grab",
          }}
          onMouseDown={(e) => {
            if (e.target === e.currentTarget) startMoving(e, "middle");
          }}
          ref={momentRef}
        >
          <div className="moment-duration" style={{ pointerEvents: "none" }}>
            {Math.round((rightHandleDisplay.getTime() - leftHandleDisplay.getTime()) / 1000)}s
          </div>
          {clipBox.left.showHandle && (
            <div
              onMouseDown={(e) => startMoving(e, "left")}
              className="moment-handle moment-left-handle"
              style={{ cursor: selectedMomentType === "recommended" ? "not-allowed" : "ew-resize" }}
            >
              <DragHandleIcon color="black"></DragHandleIcon>
            </div>
          )}
          {clipBox.right.showHandle && (
            <div
              onMouseDown={(e) => startMoving(e, "right")}
              className="moment-handle moment-right-handle"
              style={{ cursor: selectedMomentType === "recommended" ? "not-allowed" : "ew-resize" }}
            >
              <DragHandleIcon color="black"></DragHandleIcon>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default TimelinePlot;
