export const pillOptions = [
  {
    label: "Milestone",
    tooltip: "Moments of celebration or significant achievement within the game",
  },
  {
    label: "Success",
    tooltip: "Successful completion of a challenging task within the game",
  },
  {
    label: "Failure",
    tooltip: "Moments of notable or fascinating failure within the game",
  },
  {
    label: "Unusual",
    tooltip: "Moments of peculiar, intriguing, or rare occurrences within the game",
  },
  {
    label: "Social",
    tooltip: "Noteworthy social interaction, trendy, meme, or viral Moment",
  },
  {
    label: "Humorous",
    tooltip: "Moments that sparked laughter or amusement",
  },
  { label: "Happiness", tooltip: "Moments of intense joy or positivity" },
  {
    label: "Sarcastic",
    tooltip: "Moments that include instances of irony or mockery",
  },
  {
    label: "Anger",
    tooltip: "Moments of pronounced annoyance, hostility, or frustration",
  },
  { label: "Sadness", tooltip: "Moments of sadness or disappointment" },
  { label: "Fear", tooltip: "Moments of unexpected danger or fear" },
  { label: "Surprise", tooltip: "Moments of surprising or unexpected events" },
  { label: "Dislike", tooltip: "Moments of aversion, disapproval, or displeasure" },
];
