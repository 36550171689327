import { Navigate, useSearchParams } from "react-router-dom";

export const TwitchOAuth = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const error = searchParams.get("error_description");
  const state = searchParams.get("state");
  const localState = localStorage.getItem("twitch-oauth.state");
  if (localState !== null && localState === state) {
    localStorage.removeItem("twitch-oauth.state");
    if (code !== null) localStorage.setItem("twitch-oauth.code", code);
    if (error !== null) localStorage.setItem("twitch-oauth.error", error);
  }
  return <Navigate replace to="/account?tab=campaigns" />;
};
