import { gql } from "@apollo/client";

// Queries
export const AccountByKindeIdQuery = gql`
  query AccountByKindeId($kinde_id: String!) {
    account(where: { kinde_id: { _eq: $kinde_id } }) {
      id
      kinde_id
      email
      name
      twitch_username
      subscription_status
      subscription_id
      email_notifications
      memberships {
        organization_id
        role
      }
      tutorial_progress {
        tutorial
      }
    }
  }
`;

// Mutations
export const CreateAccountMutation = gql`
  mutation CreateAccount($kinde_id: String!, $email: String!) {
    insert_account_one(object: { kinde_id: $kinde_id, email: $email }) {
      id
      kinde_id
      email
      name
      twitch_username
      subscription_status
      subscription_id
      email_notifications
      memberships {
        organization_id
        role
      }
      tutorial_progress {
        tutorial
      }
    }
  }
`;

export const ToggleEmailNotificationsMutation = gql`
  mutation ToggleEmailNotifications($id: uuid!, $email_notifications: Boolean!) {
    update_account_by_pk(
      pk_columns: { id: $id }
      _set: { email_notifications: $email_notifications }
    ) {
      id
      email_notifications
    }
  }
`;
