import { gql } from "@apollo/client";

// Queries
// Retrieve all In-progress Search records for a User
export const ActiveSearchesQuery = gql`
  query ActiveSearches($user_id: String) {
    search(
      order_by: { id: desc }
      where: {
        user_id: { _eq: $user_id }
        status: { _eq: "in-progress" }
        video_type: { _is_null: false }
      }
    ) {
      created_at
      id
      origin_type
      search_progress
      url
      video_title
      video_type
      video_duration
      metadata
    }
  }
`;

// Get Search Records By ID
export const SearchByIDQuery = gql`
  query SearchByID($id: Int!) {
    search_by_pk(id: $id) {
      search_progress
      status
      status_description
      url
      video_duration
      video_title
      video_type
      video_id
      origin_type
      metadata
      asr_transcript
      moments {
        updated_at
        title
        tags
        start_time
        search_id
        id
        end_time
        created_at
        deleted
        recommended_moment_id
        annotations
      }
    }
  }
`;

// Get Search History For User.
export const SearchHistoryQuery = gql`
  query SearchHistory($user_id: String, $limit: Int) {
    search(
      order_by: { id: desc }
      where: {
        user_id: { _eq: $user_id }
        deleted: { _eq: false }
        status: { _neq: "in-progress" }
      }
      limit: $limit
    ) {
      id
      status
      status_description
      workflow_status
      url
      video_title
      video_type
      video_duration
      created_at
      archived_at
      origin_type
      metadata
      moments_aggregate(where: { deleted: { _eq: false } }) {
        aggregate {
          count
        }
      }
    }
  }
`;

// Mutations
export const InsertSearchMutation = gql`
  mutation (
    $origin_type: String
    $url: String
    $user_id: String
    $asr_transcript: Boolean
    $asr_sentiment: Boolean
    $campaign_id: uuid
  ) {
    insert_search_one(
      object: {
        origin_type: $origin_type
        status: "in-progress"
        url: $url
        user_id: $user_id
        eda_enabled: true
        asr_transcript: $asr_transcript
        asr_sentiment: $asr_sentiment
        campaign_id: $campaign_id
      }
    ) {
      id
    }
  }
`;

// Stop Search. Update Search Record status to stopped
export const StopSearchMutation = gql`
  mutation StopSearch($id: Int) {
    update_search(
      where: { id: { _eq: $id } }
      _set: { status: "stopped", status_description: "stopped" }
    ) {
      returning {
        id
        created_at
        origin_type
        search_progress
        status
        status_description
        url
        user_id
        video_duration
        video_title
        video_type
      }
    }
  }
`;

// Set Search Workflow Status to desired state
export const SetSearchWorkflowMutation = gql`
  mutation SetSearchWorkflow($id: Int, $status: String) {
    update_search(where: { id: { _eq: $id } }, _set: { workflow_status: $status }) {
      returning {
        id
        workflow_status
      }
    }
  }
`;

// Set Search Archived at Status to desired state
export const SetSearchArchiveStatusMutation = gql`
  mutation SetSearchArchiveStatus($id: Int, $date: timestamptz) {
    update_search(where: { id: { _eq: $id } }, _set: { archived_at: $date }) {
      returning {
        id
        archived_at
      }
    }
  }
`;

// Mark Search Deleted. Update Search Record status to Deleted
export const DeleteSearchMutation = gql`
  mutation DeleteSearch($id: Int, $deleted_at: timestamp) {
    update_search(where: { id: { _eq: $id } }, _set: { deleted: "true", deleted_at: $deleted_at }) {
      returning {
        id
        deleted
        deleted_at
      }
    }
  }
`;

// Mark all Search item as Deleted for that user
export const DeleteAllSearchHistoryMutation = gql`
  mutation DeleteAllSearchHistory($user_id: String, $deleted_at: timestamp) {
    update_search(
      where: { user_id: { _eq: $user_id }, status: { _in: ["stopped", "error"] } }
      _set: { deleted: "true", deleted_at: $deleted_at }
    ) {
      affected_rows
      returning {
        id
        deleted
        deleted_at
      }
    }
  }
`;

// Subscriptions
// Get Search Status By User
export const UserSearchStatusSubscription = gql`
  subscription UserSearchStatus($user_id: String) {
    search(where: { user_id: { _eq: $user_id }, status: { _neq: "in-progress" } }) {
      id
      status
      status_description
    }
  }
`;
