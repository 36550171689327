import { Button, IconButton, ButtonProps, IconButtonProps } from "@chakra-ui/react";

type KbButtonProps = {
  isIconButton?: boolean;
  icon?: IconButtonProps["icon"];
  leftIcon?: ButtonProps["leftIcon"];
  colorScheme?: string;
  borderRadius?: string;
  variant?: ButtonProps["variant"];
  text?: string;
};

function KbButton({
  isIconButton = false,
  icon,
  leftIcon,
  colorScheme = "",
  borderRadius,
  variant,
  text = "",
}: KbButtonProps) {
  const props = {
    icon,
    leftIcon: !isIconButton ? leftIcon : undefined,
    colorScheme,
    borderRadius,
    variant,
    verticalAlign: "middle" as const,
    size: "xs" as const,
    pointerEvents: "none" as const,
    lineHeight: 1 as const,
  };

  return isIconButton ? (
    <IconButton {...props} aria-label={text} />
  ) : (
    <Button {...props} leftIcon={leftIcon}>
      {text}
    </Button>
  );
}

export default KbButton;
