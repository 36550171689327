import { useQuery } from "@apollo/client";
import { Stat, StatLabel, StatNumber, StatHelpText, Box } from "@chakra-ui/react";
import { SearchesThisWeekQuery } from "../../api/stats";
import { allowedUserIds } from "../../utils/allowedUserIds";

function SearchesThisWeek() {
  // Get the timestamp for 7 days ago
  const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  const { data } = useQuery(SearchesThisWeekQuery, {
    variables: {
      timestamp_seven_days_ago: sevenDaysAgo.toISOString().split(".")[0],
      excludedUserIDs: allowedUserIds,
    },
  });

  // Add check for data and data.search before trying to access data.search.length
  const numSearchesThisWeek = data && data.search ? data.search.length : 0;

  return (
    <Box p="6" backgroundColor={"gray.700"} w={"full"} borderRadius={8}>
      <Stat>
        <StatLabel>Searches This Week</StatLabel>
        <StatNumber>{numSearchesThisWeek}</StatNumber>
        <StatHelpText mb="0">In the last 7 days</StatHelpText>
      </Stat>
    </Box>
  );
}

export default SearchesThisWeek;
