import { useMutation } from "@apollo/client";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Tr,
  Td,
  Text,
  Badge,
  Tooltip,
  HStack,
  IconButton,
  Box,
  CircularProgress,
  Progress,
} from "@chakra-ui/react";
import { InboxIn, InboxOut, Like } from "@icon-park/react";
import { UpdateMomentMutation } from "../../../../api/moment";
import { UpdateRecommendedMomentMutation } from "../../../../api/recommended-moment";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import {
  CampaignURLParams,
  ResultsURLParams,
  RivrLocations,
  updateURLParams,
} from "../../../../models/navigation";
import { RivrMoment } from "../../../../models/moment";

interface Props {
  moment: RivrMoment;
  fetchSearches: any;
  miniPlayer: boolean;
  selectedMoment: RivrMoment | null;
  setSelectedMoment: React.Dispatch<React.SetStateAction<RivrMoment | null>>;
}

export const DiscoverMomentItem = ({
  moment,
  fetchSearches,
  miniPlayer,
  selectedMoment,
  setSelectedMoment,
}: Props) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const selectedCampaign = searchParams.get(CampaignURLParams.SelectedCampaign) || "";

  const isArchived = moment.archivedAt !== null;
  const viewLink = `${RivrLocations.Results}/${moment.searchId}?${updateURLParams(
    searchParams.toString(),
    [
      [ResultsURLParams.SelectedMoment, moment.id],
      [ResultsURLParams.SelectedTimestamp, ""],
      [ResultsURLParams.SelectedPanel, ""],
    ]
  )}`;

  const [momentUpdateAPI] = useMutation(UpdateMomentMutation, {
    onCompleted() {
      fetchSearches({ campaign: selectedCampaign });
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
  });

  const [recommendedMomentUpdateAPI] = useMutation(UpdateRecommendedMomentMutation, {
    onCompleted() {
      fetchSearches({ campaign: selectedCampaign });
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
  });

  const handleNavigate = () => {
    if (miniPlayer) {
      setSelectedMoment(moment);
    } else {
      navigate(viewLink);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
    if (miniPlayer) {
      e.preventDefault();
      handleNavigate();
    }
  };

  const handleFavorite = () => {
    moment.type === "User Generated"
      ? momentUpdateAPI({
          variables: { id: moment.id, object: { favorited: !moment.favorited } },
        })
      : recommendedMomentUpdateAPI({
          variables: { id: moment.id, object: { favorited: !moment.favorited } },
        });

    moment.favorited = !moment.favorited;
  };

  const handleArchive = (date: Date | null) => {
    moment.type === "User Generated"
      ? momentUpdateAPI({
          variables: { id: moment.id, object: { archived_at: date } },
        })
      : recommendedMomentUpdateAPI({
          variables: { id: moment.id, object: { archived_at: date } },
        });

    moment.archivedAt =
      date !== null
        ? date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })
        : null;
  };

  return (
    <Tr
      _hover={{ bg: "whiteAlpha.100", cursor: "pointer" }}
      sx={{
        "&:hover .actions": {
          opacity: 1,
        },
      }}
      display={"table-row"}
      onClick={handleNavigate}
      bg={selectedMoment === moment ? "whiteAlpha.100" : undefined}
    >
      <Td opacity={isArchived ? 0.4 : 1} px={2}>
        <Tooltip placement={"bottom"} label={"Favorite"}>
          <IconButton
            borderRadius={"full"}
            size="xs"
            variant={"ghost"}
            aria-label="Favorite Moment"
            icon={<Like size={16} theme={moment.favorited ? "filled" : "outline"} />}
            onClick={(e) => {
              e.stopPropagation();
              handleFavorite();
            }}
          />
        </Tooltip>
      </Td>
      <Td>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <CircularProgress
            className={"circular-progress"}
            min={0}
            max={3.5}
            value={moment.rank}
            color={"green.200"}
            trackColor={"blackAlpha.500"}
            thickness={16}
            size={6}
          />
        </Box>
      </Td>
      <Td px={2}>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Text overflow={"hidden"} textOverflow={"ellipsis"} title={moment.channel}>
            {moment.channel}
          </Text>
        </Box>
      </Td>
      <Td>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Progress
            value={Math.floor(moment.annotations["Hype"] * 100)}
            colorScheme={"yellow"}
            borderRadius={"sm"}
            maxW={28}
          />
        </Box>
      </Td>
      <Td>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <HStack w={"100%"} maxW={28} spacing={"px"}>
            <Tooltip label={"Chat Negativity"} placement={"bottom"}>
              <Progress
                aria-label="Negative chat sentiment"
                colorScheme="purple"
                value={Math.floor(moment.annotations["Chat negative"] * 100)}
                h={3}
                w={"100%"}
                transform={"scaleX(-1)"}
                borderRightRadius={"sm"}
              />
            </Tooltip>
            <Tooltip label={"Chat Positivity"} placement={"bottom"}>
              <Progress
                aria-label="Positive chat sentiment"
                colorScheme="blue"
                value={Math.floor(moment.annotations["Chat positive"] * 100)}
                h={3}
                w={"100%"}
                borderRightRadius={"sm"}
              />
            </Tooltip>
          </HStack>
        </Box>
      </Td>
      {/* <Td>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Tooltip label={moment.chatScore === 0 ? "0" : moment.chatScore}>
            <Progress value={moment.chatScore} colorScheme={"teal"} borderRadius={"sm"} maxW={24} />
          </Tooltip>
        </Box>
      </Td> */}
      <Td px={2}>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          {moment.date}
        </Box>
      </Td>
      <Td px={2}>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          {moment.timestamp}
        </Box>
      </Td>
      <Td maxW={64} px={2}>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Text overflow={"hidden"} textOverflow={"ellipsis"} title={moment.title}>
            {moment.title}
          </Text>
        </Box>
      </Td>
      <Td px={2}>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Badge
            colorScheme={moment.type === "User Generated" ? "green" : "blue"}
            letterSpacing={1}
          >
            {moment.type}
          </Badge>
        </Box>
      </Td>
      <Td maxW={64} px={2}>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Text overflow={"hidden"} textOverflow={"ellipsis"} title={moment.vod}>
            {moment.vod}
          </Text>
        </Box>
      </Td>
      <Td maxW={"fit-content"} isNumeric px={2}>
        <HStack justify={"flex-end"} spacing={1} className="actions" opacity={0}>
          <Tooltip label={"View"}>
            <Box
              opacity={isArchived ? 0.4 : 1}
              as={Link}
              to={viewLink}
              onClick={(e: any) => {
                e.stopPropagation();
                handleClick(e);
              }}
            >
              <IconButton
                size="xs"
                variant={"ghost"}
                aria-label="View Moment"
                icon={<ExternalLinkIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!miniPlayer) navigate(viewLink);
                }}
              />
            </Box>
          </Tooltip>
          <Tooltip label={isArchived ? "Unarchive" : "Archive"}>
            <IconButton
              size="xs"
              variant={"ghost"}
              aria-label="Archive Moment"
              icon={isArchived ? <InboxOut /> : <InboxIn />}
              onClick={(e) => {
                e.stopPropagation();
                handleArchive(isArchived ? null : new Date());
              }}
            />
          </Tooltip>
        </HStack>
      </Td>
    </Tr>
  );
};
