import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  IconButton,
  Tooltip,
  ButtonGroup,
  Text,
  HStack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Flex,
  Checkbox,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  Spinner,
  Image,
  Select,
  VStack,
} from "@chakra-ui/react";
import {
  Pause,
  PlayOne,
  Square,
  ToBottom,
  ToTop,
  VolumeMute,
  VolumeNotice,
  VolumeSmall,
} from "@icon-park/react";
import { CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInterval } from "../../hooks/useInterval";
import { Action, ContentBox, Dimensions } from "../../models/action";
import { SourceSelector } from "./SourceSelector";
import { PreviewSelector } from "./PreviewSelector";
import { changeRatio, relativeToAbsolute } from "../../utils/contentBox";
import SafeZone from "../../assets/safe-zone.png";
import "./VerticalFormatModal.css";
import * as amplitude from "@amplitude/analytics-browser";

type Template = "freeform" | "full" | "split";
type FacecamType = "disabled" | "square";
type FacecamRatio = "4:3" | "16:9" | "9:8";
type FacecamPosition = "top" | "bottom";
type Settings = {
  template: Template;
  facecamType: FacecamType;
  facecamRatio: FacecamRatio;
  facecamPosition: FacecamPosition;
  safeZone: boolean;
  contentSource: ContentBox;
  contentPreview: ContentBox;
  facecamSource: ContentBox;
  facecamPreview: ContentBox;
};

const templates = {
  freeform: "Freeform",
  split: "Split",
  full: "Full",
};

const facecamTypes = {
  disabled: {
    label: "No facecam",
    display: <CloseIcon />,
    color: "red",
  },
  square: {
    label: "Square facecam",
    display: <Square size="22" />,
    color: "green",
  },
};

const facecamRatios = {
  "16:9": {
    label: "16:9 facecam",
    display: (
      <Text mx={2} fontWeight={"medium"}>
        16:9
      </Text>
    ),
    ratio: 16 / 9,
  },
  "4:3": {
    label: "4:3 facecam",
    display: (
      <Text mx={2} fontWeight={"medium"}>
        4:3
      </Text>
    ),
    ratio: 4 / 3,
  },

  "9:8": {
    label: "50% facecam",
    display: (
      <Text mx={2} fontWeight={"medium"}>
        50%
      </Text>
    ),
    ratio: 9 / 8,
  },
};

const facecamPositions = {
  top: {
    label: "Top",
    display: <ToTop size="20" />,
  },
  bottom: {
    label: "Bottom",
    display: <ToBottom size="20" />,
  },
};

export type VerticalClip = {
  momentId: string;
  url?: string;
};

type Props = {
  verticalFormatClips: VerticalClip[];
  onClose: () => void;
  onConfirm: (action: Action) => void;
};

export const VerticalFormatModal = ({ verticalFormatClips, onClose, onConfirm }: Props) => {
  const contentColor = "#B794F4"; // Purple 300
  const facecamColor = "#9AE6B4"; // Green 200
  const mediaSpacingPixels = 24;
  const sourcePreviewRatio = 16 / 11;
  const previewRatio = 9 / 16;
  const initialVolume = 0;
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [isRemoveFromQueueDialogOpen, setIsRemoveFromQueueDialogOpen] = useState(false);
  const [volume, setVolume] = useState(initialVolume);
  const [prevVolume, setPrevVolume] = useState(50);
  const [isPlaying, setIsPlaying] = useState(true);
  const isSeeking = useRef(false);
  const [seekTo, setSeekTo] = useState<number | null>(null);
  const [playerTime, setPlayerTime] = useState(0);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const isMuted = volume === 0;
  const [sourceRes, setSourceRes] = useState<Dimensions>({ width: 1920, height: 1080 });
  const sourceRatio = sourceRes.width / sourceRes.height;
  const [isMetadataLoaded, setIsMetadataLoaded] = useState(false);
  const [settings, setSettings] = useState<Settings>(() => {
    const verticalFormatSettings = localStorage.getItem("verticalFormatSettings");
    if (verticalFormatSettings) return JSON.parse(verticalFormatSettings);
    const contentSourceWidth = previewRatio / sourceRatio;
    return {
      template: "freeform",
      facecamType: "disabled",
      facecamRatio: "4:3",
      facecamPosition: "top",
      safeZone: false,
      contentSource: {
        x: (1 - contentSourceWidth) / 2,
        y: 0,
        width: contentSourceWidth,
        height: 1,
      },
      contentPreview: {
        x: 0,
        y: 0,
        width: 1,
        height: 1,
      },
      facecamSource: {
        x: 0,
        y: 0.68,
        width: 0.18,
        height: 0.32,
      },
      facecamPreview: {
        x: 0.18,
        y: 0.1,
        width: 0.64,
        height: 0.36,
      },
    };
  });
  const videoRef = useRef<HTMLVideoElement>(null);
  const backgroundRef = useRef<HTMLCanvasElement>(null);
  const contentRef = useRef<HTMLCanvasElement>(null);
  const facecamRef = useRef<HTMLCanvasElement>(null);
  const cancelCloseRef = useRef(null);
  const cancelRemoveRef = useRef(null);
  const initialRef = useRef(null);
  const [mediaDimensions, setMediaDimensions] = useState<Dimensions>({ width: 0, height: 0 });
  const current = 1;
  const total = 1;
  const clip = verticalFormatClips[0];
  const backgroundWidth = sourceRes.height * previewRatio;
  const backgroundX = (sourceRes.width - backgroundWidth) / 2;
  const isFacecamActive =
    (settings.template === "freeform" && settings.facecamType !== "disabled") ||
    settings.template === "split";
  const contentSource = settings.contentSource;
  const contentPreview = settings.contentPreview;
  const facecamSource = settings.facecamSource;
  const facecamPreview = settings.facecamPreview;
  const setContentSource = (contentSource: ContentBox) =>
    setSettings((prevSettings) => ({ ...prevSettings, contentSource }));
  const setContentPreview = (contentPreview: ContentBox) =>
    setSettings((prevSettings) => ({ ...prevSettings, contentPreview }));
  const setFacecamSource = (facecamSource: ContentBox) =>
    setSettings((prevSettings) => ({ ...prevSettings, facecamSource }));
  const setFacecamPreview = (facecamPreview: ContentBox) =>
    setSettings((prevSettings) => ({ ...prevSettings, facecamPreview }));

  const [sourceDims, previewDims] = useMemo(() => {
    const width = mediaDimensions.width - 3 * mediaSpacingPixels;
    const height = mediaDimensions.height - 2 * mediaSpacingPixels;
    const totalWidth = 1 + previewRatio / sourcePreviewRatio;
    const idealRatio = totalWidth * sourcePreviewRatio;
    const actualRatio = width / height;
    if (actualRatio > idealRatio) {
      const previewHeight = height;
      const previewWidth = previewHeight * previewRatio;
      const sourceWidth = height * sourcePreviewRatio;
      const sourceHeight = sourceWidth / sourceRatio;
      const sourceDims = { width: sourceWidth, height: sourceHeight };
      const previewDims = { width: previewWidth, height: previewHeight };
      return [sourceDims, previewDims];
    }
    const sourceWidth = width / totalWidth;
    const previewWidth = width - sourceWidth;
    const sourceHeight = sourceWidth / sourceRatio;
    const previewHeight = previewWidth / previewRatio;
    const sourceDims = { width: sourceWidth, height: sourceHeight };
    const previewDims = { width: previewWidth, height: previewHeight };
    return [sourceDims, previewDims];
  }, [mediaDimensions, sourceRes]);

  useEffect(() => {
    if (isMetadataLoaded) {
      const absContentPreview = relativeToAbsolute(contentPreview, previewDims);
      setContentSource(
        changeRatio(contentSource, absContentPreview.width / absContentPreview.height, sourceDims)
      );
      const absFacecamPreview = relativeToAbsolute(facecamPreview, previewDims);
      setFacecamSource(
        changeRatio(facecamSource, absFacecamPreview.width / absFacecamPreview.height, sourceDims)
      );
    }
  }, [sourceDims]);

  useEffect(() => {
    localStorage.setItem("verticalFormatSettings", JSON.stringify(settings));
  }, [settings]);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useInterval(
    () => {
      if (!videoRef.current || !backgroundRef.current) return;
      let ctx = backgroundRef.current.getContext("2d");
      if (!ctx) return;
      ctx.filter = "blur(20px)";
      ctx.drawImage(
        videoRef.current,
        backgroundX,
        0,
        backgroundWidth,
        sourceRes.height,
        0,
        0,
        previewDims.width,
        previewDims.height
      );
      if (!contentRef.current) return;
      ctx = contentRef.current.getContext("2d");
      if (!ctx) return;
      ctx.drawImage(
        videoRef.current,
        sourceRes.width * contentSource.x,
        sourceRes.height * contentSource.y,
        sourceRes.width * contentSource.width,
        sourceRes.height * contentSource.height,
        -2,
        -2,
        previewDims.width * contentPreview.width,
        previewDims.height * contentPreview.height
      );
      if (!facecamRef.current) return;
      ctx = facecamRef.current.getContext("2d");
      if (!ctx) return;
      ctx.drawImage(
        videoRef.current,
        sourceRes.width * facecamSource.x,
        sourceRes.height * facecamSource.y,
        sourceRes.width * facecamSource.width,
        sourceRes.height * facecamSource.height,
        -2,
        -2,
        previewDims.width * facecamPreview.width,
        previewDims.height * facecamPreview.height
      );
    },
    videoLoaded ? 20 : null
  );

  const convertToPercentages = (box: ContentBox) =>
    Object.keys(box).reduce((result: any, key) => {
      result[key] = box[key as keyof ContentBox] * 100;
      return result;
    }, {});

  const createClip = () => {
    const action: Action = {
      type: {
        kind: "Clip",
        config: {
          kind: "9:16",
          content: {
            source: convertToPercentages(contentSource),
            destination: convertToPercentages(contentPreview),
          },
          facecam: isFacecamActive
            ? {
                source: convertToPercentages(facecamSource),
                destination: convertToPercentages(facecamPreview),
              }
            : undefined,
        },
      },
      output_destination: {
        kind: "Browser",
      },
    };
    onConfirm(action);
    onClose();
  };

  const onMediaResize = (node: HTMLDivElement) => {
    setMediaDimensions({
      width: node.offsetWidth,
      height: node.offsetHeight,
    });
  };

  const mediaRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      onMediaResize(node);
      new ResizeObserver(() => onMediaResize(node)).observe(node);
    }
  }, []);

  const handleAlertClose = () => {
    setIsAlertDialogOpen(false);
    onClose();
  };

  const openRemoveFromQueueDialog = () => {
    setIsRemoveFromQueueDialogOpen(true);
  };

  const handleVolumeChange = (value: number) => {
    if (value > 0) setPrevVolume(value);
  };

  const toggleMute = () => {
    if (isMuted) setVolume(prevVolume);
    else setVolume(0);
  };

  const getVolumeIcon = () => {
    if (volume === 0) return <VolumeMute theme="filled" size="20px" />;
    if (volume < 70) return <VolumeSmall theme="filled" size="20px" />;
    return <VolumeNotice theme="filled" size="20px" />;
  };

  useEffect(() => {
    if (videoRef.current) videoRef.current.volume = volume / 100;
  }, [volume, videoLoaded]);

  useEffect(() => {
    if (seekTo !== null && videoLoaded && videoRef.current)
      videoRef.current.currentTime = seekTo * videoRef.current.duration;
  }, [seekTo, videoLoaded]);

  const updatePlayerTime = () => {
    if (videoRef.current && !isSeeking.current)
      setPlayerTime(videoRef.current.currentTime / videoRef.current.duration);
  };

  const startSeeking = () => {
    isSeeking.current = true;
  };

  const stopSeeking = (val: number) => {
    isSeeking.current = false;
    setSeekTo(val);
  };

  const adjustResolution = () => {
    if (videoRef.current) {
      setSourceRes({ width: videoRef.current.videoWidth, height: videoRef.current.videoHeight });
      setIsMetadataLoaded(true);
    }
  };

  useEffect(() => {
    if (mediaDimensions.width === 0) return;
    if (settings.template === "full") {
      setContentSource(changeRatio(contentSource, previewRatio, sourceDims));
      setContentPreview({ x: 0, y: 0, width: 1, height: 1 });
    } else if (settings.template === "split") {
      const facecamRatio = facecamRatios[settings.facecamRatio].ratio;
      const facecamHeight = previewRatio / facecamRatio;
      const sourceHeight = 1 - facecamHeight;
      const [facecamY, sourceY] =
        settings.facecamPosition === "top" ? [0, facecamHeight] : [sourceHeight, 0];
      setFacecamPreview({ x: 0, y: facecamY, width: 1, height: facecamHeight });
      const newContentPreview = { x: 0, y: sourceY, width: 1, height: sourceHeight };
      const absoluteContentPreview = relativeToAbsolute(newContentPreview, previewDims);
      setContentPreview(newContentPreview);

      setFacecamSource(changeRatio(facecamSource, facecamRatio, sourceDims));
      setContentSource(
        changeRatio(
          contentSource,
          absoluteContentPreview.width / absoluteContentPreview.height,
          sourceDims
        )
      );
    }
  }, [settings.template, settings.facecamRatio, settings.facecamPosition]);

  const handleAmplitudeClick = (e: string) => {
    amplitude.track(e);
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        closeOnEsc={false}
        blockScrollOnMount={true}
        scrollBehavior={"inside"}
        isOpen={true}
        onClose={onClose}
        isCentered
        initialFocusRef={initialRef}
      >
        <ModalOverlay />
        <ModalContent
          mt={32}
          maxHeight={"90dvh"}
          h={"90dvh"}
          maxWidth={"94vw"}
          w={"94vw"}
          bg="gray.900"
          borderWidth={1}
          zIndex={"modal"}
        >
          <ModalHeader>
            <HStack justifyContent={"space-between"}>
              <HStack spacing={4}>
                <HStack pl={1}>
                  <Text fontSize={"md"} fontWeight={"medium"}>
                    Template
                  </Text>
                  <Select
                    value={settings.template}
                    onChange={(e) =>
                      setSettings({ ...settings, template: e.target.value as Template })
                    }
                    size={"sm"}
                    variant={"filled"}
                    borderRadius={"md"}
                  >
                    {(Object.keys(templates) as Template[]).map((k) => (
                      <option value={k} key={k}>
                        {templates[k]}
                      </option>
                    ))}
                  </Select>
                </HStack>
                {settings.template === "freeform" && (
                  <HStack pl={1}>
                    <Text fontSize={"md"} fontWeight={"medium"}>
                      Facecam
                    </Text>
                    <ButtonGroup spacing={"2"} variant={"outline"} isAttached>
                      {(Object.keys(facecamTypes) as FacecamType[]).map((k) => (
                        <Tooltip label={facecamTypes[k].label} key={k}>
                          <IconButton
                            aria-label={facecamTypes[k].label}
                            icon={facecamTypes[k].display}
                            onClick={() => setSettings({ ...settings, facecamType: k })}
                            colorScheme={
                              settings.facecamType === k ? facecamTypes[k].color : "gray"
                            }
                            variant={"solid"}
                            size={"sm"}
                          />
                        </Tooltip>
                      ))}
                    </ButtonGroup>
                  </HStack>
                )}
                {settings.template === "split" && (
                  <>
                    <HStack pl={1}>
                      <Text fontSize={"md"} fontWeight={"medium"}>
                        Facecam
                      </Text>
                      <ButtonGroup spacing={"2"} variant={"outline"} isAttached>
                        {(Object.keys(facecamRatios) as FacecamRatio[]).map((k) => (
                          <Tooltip label={facecamRatios[k].label} key={k}>
                            <IconButton
                              aria-label={facecamRatios[k].label}
                              icon={facecamRatios[k].display}
                              onClick={() => setSettings({ ...settings, facecamRatio: k })}
                              colorScheme={settings.facecamRatio === k ? "green" : "gray"}
                              variant={"solid"}
                              size={"sm"}
                            />
                          </Tooltip>
                        ))}
                      </ButtonGroup>
                    </HStack>
                    <HStack pl={1}>
                      <Text fontSize={"md"} fontWeight={"medium"}>
                        Position
                      </Text>
                      <ButtonGroup spacing="2" variant="outline" isAttached>
                        {(Object.keys(facecamPositions) as FacecamPosition[]).map((k) => (
                          <Tooltip label={facecamPositions[k].label} key={k}>
                            <IconButton
                              aria-label={facecamPositions[k].label}
                              icon={facecamPositions[k].display}
                              onClick={() => setSettings({ ...settings, facecamPosition: k })}
                              colorScheme={settings.facecamPosition === k ? "green" : "gray"}
                              variant="solid"
                              size="sm"
                            />
                          </Tooltip>
                        ))}
                      </ButtonGroup>
                    </HStack>
                  </>
                )}
                <Checkbox
                  flexDir={"row-reverse"}
                  colorScheme={"red"}
                  size="lg"
                  isChecked={settings.safeZone}
                  onChange={(e) => setSettings({ ...settings, safeZone: e.target.checked })}
                  marginInlineStart={"3 !important"}
                >
                  <Text fontSize={"md"} fontWeight={"medium"} mr={2}>
                    Safe Zone
                  </Text>
                </Checkbox>
              </HStack>
              {total > 1 && (
                <HStack>
                  <Tooltip label="Remove from queue">
                    <IconButton
                      aria-label="Remove from queue"
                      icon={<DeleteIcon />}
                      colorScheme="red"
                      borderRadius="full"
                      variant={"outline"}
                      onClick={openRemoveFromQueueDialog}
                    />
                  </Tooltip>
                  <Text
                    fontSize={"md"}
                    fontWeight={"medium"}
                    bg={"gray.700"}
                    borderRadius={"full"}
                    py={2}
                    px={4}
                  >
                    {current} / {total}
                  </Text>
                </HStack>
              )}
            </HStack>
          </ModalHeader>
          <ModalBody p={0} overflow="visible" zIndex={"tooltip"}>
            <Flex w="100%" h="100%" ref={mediaRef} justifyContent={"center"} alignItems={"center"}>
              {clip.url ? (
                <>
                  <VStack>
                    <Box
                      w={`${sourceDims.width}px`}
                      h={`${sourceDims.height}px`}
                      zIndex={"tooltip"}
                    >
                      <video
                        width="100%"
                        height="100%"
                        autoPlay
                        loop
                        onLoadStart={() => setVideoLoaded(true)}
                        ref={videoRef}
                        onTimeUpdate={updatePlayerTime}
                        onLoadedData={adjustResolution}
                      >
                        <source src={clip.url} type="video/mp4" />
                      </video>
                      <SourceSelector
                        contentBox={contentSource}
                        setContentBox={setContentSource}
                        previewContentBox={contentPreview}
                        setPreviewContentBox={setContentPreview}
                        sourceDims={sourceDims}
                        previewDims={previewDims}
                        color={contentColor}
                        title={"Content"}
                        locked={settings.template !== "freeform"}
                      />
                      {isFacecamActive && (
                        <SourceSelector
                          contentBox={facecamSource}
                          setContentBox={setFacecamSource}
                          previewContentBox={facecamPreview}
                          setPreviewContentBox={setFacecamPreview}
                          sourceDims={sourceDims}
                          previewDims={previewDims}
                          color={facecamColor}
                          title={"Facecam"}
                          locked={settings.template !== "freeform"}
                        />
                      )}
                    </Box>
                    <HStack
                      spacing={2}
                      w={"100%"}
                      justifyContent={"space-between"}
                      p={2}
                      bg={"gray.800"}
                      borderRadius={"md"}
                    >
                      <IconButton
                        aria-label="Play and Pause toggle"
                        icon={
                          isPlaying ? (
                            <Pause size={22} strokeWidth={6} strokeLinecap="square" />
                          ) : (
                            <PlayOne theme="filled" size={22} />
                          )
                        }
                        onClick={togglePlayPause}
                        size={"sm"}
                        variant={"ghost"}
                        mr={1}
                      />
                      <Slider
                        aria-label="Video Seek Bar"
                        value={playerTime}
                        onChange={setPlayerTime}
                        onChangeStart={startSeeking}
                        onChangeEnd={stopSeeking}
                        focusThumbOnChange={false}
                        step={0.01}
                        min={0}
                        max={1}
                        w={"100%"}
                        colorScheme="green"
                      >
                        <SliderTrack>
                          <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                      </Slider>
                      <HStack spacing={3}>
                        <Slider
                          aria-label="Volume Slider"
                          value={volume}
                          onChange={setVolume}
                          onChangeEnd={handleVolumeChange}
                          min={0}
                          max={100}
                          w={20}
                          ml={4}
                          colorScheme={"red"}
                        >
                          <SliderTrack>
                            <SliderFilledTrack />
                          </SliderTrack>
                          <SliderThumb />
                        </Slider>
                        <IconButton
                          size="sm"
                          variant={"ghost"}
                          icon={getVolumeIcon()}
                          onClick={toggleMute}
                          aria-label="Mute Volume"
                        />
                      </HStack>
                    </HStack>
                  </VStack>
                  <Box
                    ml={`${mediaSpacingPixels}px`}
                    w={`${previewDims.width}px`}
                    h={`${previewDims.height}px`}
                    pos={"relative"}
                  >
                    <canvas
                      ref={backgroundRef}
                      width={previewDims.width}
                      height={previewDims.height}
                    ></canvas>
                    <PreviewSelector
                      contentBox={contentPreview}
                      setContentBox={setContentPreview}
                      previewDims={previewDims}
                      color={contentColor}
                      canvasRef={contentRef}
                      disabled={settings.template !== "freeform"}
                    />
                    {isFacecamActive && (
                      <PreviewSelector
                        contentBox={facecamPreview}
                        setContentBox={setFacecamPreview}
                        previewDims={previewDims}
                        color={facecamColor}
                        canvasRef={facecamRef}
                        disabled={settings.template !== "freeform"}
                      />
                    )}
                    {settings.safeZone && (
                      <Image
                        src={SafeZone}
                        pos={"absolute"}
                        top={0}
                        left={0}
                        w={previewDims.width}
                        h={previewDims.height}
                        pointerEvents={"none"}
                      />
                    )}
                  </Box>
                </>
              ) : (
                <VStack>
                  <HStack spacing={0}>
                    <Text fontSize="xl" mr={2}>
                      Loading...
                    </Text>
                    <Spinner size="sm" />
                  </HStack>
                  <Text color={"gray.300"} textAlign={"center"}>
                    Please wait while your clip is loaded.
                    <br />
                    Long clips may take several minutes.
                  </Text>
                </VStack>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter justifyContent={"space-between"} bg="gray.800" borderTopWidth={1}>
            <HStack>
              <Button colorScheme="gray" variant="ghost" onClick={() => setIsAlertDialogOpen(true)}>
                Close
              </Button>
            </HStack>
            <HStack ml={"auto"}>
              {current > 1 && (
                <Button colorScheme="gray" variant="ghost" onClick={() => 0}>
                  Back
                </Button>
              )}
              <Button
                ref={initialRef}
                colorScheme={"green"}
                onClick={() => {
                  handleAmplitudeClick("Complete Reformat to Vertical");
                  createClip();
                }}
              >
                {current === total ? "Finish" : "Next"}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isAlertDialogOpen}
        leastDestructiveRef={cancelCloseRef}
        onClose={() => setIsAlertDialogOpen(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Close reformatter
            </AlertDialogHeader>
            <AlertDialogBody sx={{ textWrap: "balance" }}>
              Are you sure you want to end the reformatting process? You will lose your current
              work.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelCloseRef}
                onClick={() => setIsAlertDialogOpen(false)}
                variant={"ghost"}
              >
                Continue
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  handleAmplitudeClick("Cancel Reformat to Vertical");
                  handleAlertClose();
                }}
                ml={3}
              >
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isRemoveFromQueueDialogOpen}
        leastDestructiveRef={cancelRemoveRef}
        onClose={() => setIsRemoveFromQueueDialogOpen(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remove from queue
            </AlertDialogHeader>
            <AlertDialogBody sx={{ textWrap: "balance" }}>
              Are you sure you want to remove this item from the queue?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRemoveRef}
                onClick={() => setIsRemoveFromQueueDialogOpen(false)}
                variant={"ghost"}
              >
                Cancel
              </Button>
              <Button onClick={() => 0} colorScheme="red" ml={3}>
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
