import { useState, useEffect, useRef } from "react";
import { Box } from "@chakra-ui/react";

type Props = {
  videoInfo: any;
  seekTime: { seconds: number } | null;
  pausePlayer: { pause: boolean };
  updatePlayTime: (seconds: number) => void;
};

export const YouTubeRivrPlayer = (props: Props) => {
  const { videoInfo, seekTime, pausePlayer, updatePlayTime } = props;
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [isAPIReady, setIsAPIReady] = useState(false);
  const playerID = "youtube-player";
  const scriptID = "youtube-player-script";
  const playerRef = useRef<any>();

  useEffect(() => {
    if (
      isPlayerReady &&
      seekTime &&
      seekTime.seconds >= 0 &&
      seekTime.seconds < playerRef.current.getDuration()
    )
      playerRef.current.seekTo(seekTime.seconds, true);
  }, [seekTime, isPlayerReady]);

  useEffect(() => {
    if (isPlayerReady && pausePlayer.pause) playerRef.current.pauseVideo();
  }, [pausePlayer, isPlayerReady]);

  useEffect(() => {
    if (!playerRef.current && videoInfo && isAPIReady && videoInfo.video_id)
      playerRef.current = new (window as any).YT.Player(playerID, {
        videoId: videoInfo.video_id,
        events: {
          onReady: () => {
            setIsPlayerReady(true);
            playerRef.current.mute();
            playerRef.current.playVideo();
          },
        },
      });
  }, [videoInfo, isAPIReady]);

  useEffect(() => {
    if (!isPlayerReady) return;

    const interval = setInterval(() => {
      updatePlayTime(playerRef.current.getCurrentTime());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [isPlayerReady]);

  useEffect(() => {
    if (document.getElementById(scriptID)) setIsAPIReady(true);
    else {
      const script = document.createElement("script");
      (window as any).onYouTubeIframeAPIReady = () => {
        setIsAPIReady(true);
      };
      document.body.appendChild(script);
      script.async = true;
      script.src = "https://www.youtube.com/iframe_api";
      script.id = scriptID;
    }
    return () => {
      if (playerRef.current) playerRef.current.destroy();
    };
  }, []);

  return <Box id={playerID} height="100%" width="100%"></Box>;
};
