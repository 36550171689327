import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Heading,
  Text,
  VStack,
  Box,
  HStack,
  Badge,
  ListItem,
  Icon,
  List,
  Tooltip,
  ButtonGroup,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Lightning } from "@icon-park/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { AccountURLParams, RivrLocations, updateURLParams } from "../../models/navigation";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const UpgradeCta = ({ isOpen, onClose }: Props) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [isYearly, setIsYearly] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl" motionPreset="scale" autoFocus>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <VStack spacing={5} alignItems="center">
            <Heading size="md" fontWeight={"semibold"} textAlign={"center"}>
              Step up your content game. Upgrade today.
            </Heading>
            <ButtonGroup size="sm" isAttached variant="outline">
              <Button mr="-px" onClick={() => setIsYearly(false)} isActive={!isYearly}>
                Monthly
              </Button>
              <Button onClick={() => setIsYearly(true)} isActive={isYearly}>
                Yearly
                <Badge colorScheme="green" ml={2} py={0.5}>
                  20% Off
                </Badge>
              </Button>
            </ButtonGroup>
            <Box
              bg="blackAlpha.400"
              borderRadius={"md"}
              w="100%"
              h="100%"
              p={6}
              borderWidth={2}
              borderColor={"orange.300"}
            >
              <VStack alignItems={"flex-start"} w={"100%"} spacing={4}>
                <Badge
                  colorScheme="green"
                  fontSize={16}
                  letterSpacing={"widest"}
                  w={"100%"}
                  textAlign={"center"}
                  borderRadius={"md"}
                  flexWrap={"wrap"}
                >
                  Pro is 50% off for a limited time
                </Badge>
                <HStack justifyContent={"space-between"} alignItems={"center"} w={"100%"}>
                  <Text fontSize={32} fontWeight={"semibold"}>
                    Pro
                  </Text>
                  <VStack spacing={0} alignItems={"flex-end"}>
                    <HStack>
                      <Text
                        fontSize={20}
                        fontWeight={"semibold"}
                        textDecoration={"line-through"}
                        color="gray.400"
                      >
                        {isYearly ? "$346" : "$36"}
                      </Text>
                      <Text fontSize={32} fontWeight={"semibold"}>
                        {isYearly ? "$173" : "$18"}
                      </Text>
                    </HStack>
                    <Text fontSize={"xs"} color={"gray.400"}>
                      billed {isYearly ? "yearly" : "monthly"}
                    </Text>
                  </VStack>
                </HStack>
                <List spacing={2} pb={2}>
                  <ListItem>
                    <HStack>
                      <Icon as={CheckCircleIcon} color="green.300" boxSize={4} />
                      <Text>Priority access to beta features</Text>
                    </HStack>
                  </ListItem>
                  <ListItem>
                    <HStack>
                      <Icon as={CheckCircleIcon} color="green.300" boxSize={4} />
                      <Text>Process media up to 10x faster</Text>
                    </HStack>
                  </ListItem>
                  <ListItem>
                    <HStack>
                      <Icon as={CheckCircleIcon} color="green.300" boxSize={4} />
                      <Text>Process Twitch and YouTube VODs</Text>
                    </HStack>
                  </ListItem>
                  <ListItem>
                    <HStack>
                      <Icon as={CheckCircleIcon} color="green.300" boxSize={4} />
                      <Text>Process YouTube Livestreams</Text>
                    </HStack>
                  </ListItem>
                  <ListItem>
                    <HStack alignItems={"flex-start"} justifyContent={"flex-start"}>
                      <Icon as={CheckCircleIcon} color="green.300" boxSize={4} mt={1.5} />
                      <VStack alignItems={"flex-start"} spacing={0}>
                        <Tooltip
                          label={"Process up to 10 links at a time."}
                          placement="right"
                          hasArrow
                        >
                          <Text textDecoration={"underline dotted gray"} textUnderlineOffset={5}>
                            Active analysis limit: 10
                          </Text>
                        </Tooltip>
                      </VStack>
                    </HStack>
                  </ListItem>
                  <ListItem>
                    <HStack alignItems={"flex-start"} justifyContent={"flex-start"}>
                      <Icon as={CheckCircleIcon} color="green.300" boxSize={4} mt={1.5} />
                      <HStack>
                        <Tooltip label={"Support for up to 4k output."} placement="right" hasArrow>
                          <Text textDecoration={"underline dotted gray"} textUnderlineOffset={5}>
                            Reformat to Vertical{" "}
                            <Badge colorScheme="green" variant={"outline"}>
                              4k
                            </Badge>
                          </Text>
                        </Tooltip>
                      </HStack>
                    </HStack>
                  </ListItem>
                  <ListItem>
                    <HStack alignItems={"flex-start"} justifyContent={"flex-start"}>
                      <Icon as={CheckCircleIcon} color="green.300" boxSize={4} mt={1.5} />
                      <VStack alignItems={"flex-start"} spacing={0}>
                        <Tooltip
                          label={"Retain analyzed media for up to 90 days."}
                          placement="right"
                          hasArrow
                        >
                          <Text textDecoration={"underline dotted gray"} textUnderlineOffset={5}>
                            90-day history
                          </Text>
                        </Tooltip>
                      </VStack>
                    </HStack>
                  </ListItem>
                </List>
              </VStack>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="orange"
            onClick={() => {
              onClose();
              navigate(
                `${RivrLocations.Account}?${updateURLParams(searchParams.toString(), [
                  [AccountURLParams.SelectedTab, "main"],
                ])}`
              );
            }}
            leftIcon={<Lightning theme="filled" />}
            w="100%"
          >
            Upgrade to Pro {isYearly ? "yearly" : "monthly"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpgradeCta;
