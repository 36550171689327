import { createContext } from "react";
import { OrganizationMembership } from "../models/rivrOrganization";
import { LazyQueryExecFunction, OperationVariables } from "@apollo/client";

type AccountContextData = {
  account: any;
  memberships: OrganizationMembership[];
  organizationsQuery: LazyQueryExecFunction<any, OperationVariables>;
  completeTutorial: (tutorial: string) => void;
  toggleEmailNotifications: (email: boolean) => void;
};

export const AccountContext = createContext<AccountContextData>(null as any);
