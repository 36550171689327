import { HStack } from "@chakra-ui/react";
import { cardio } from "ldrs";

type LoadingProps = {
  variant?: "small" | "large";
};

export const Loading = ({ variant = "large" }: LoadingProps) => {
  cardio.register();

  const size = variant === "large" ? "100" : "50";
  const stroke = variant === "large" ? "8" : "4";
  const speed = variant === "large" ? "2" : "2";
  const padding = variant === "large" ? "8" : "2";

  return (
    <HStack w={"100%"} h={"100%"} p={padding} justifyContent={"center"} zIndex={"tooltip"}>
      <l-cardio size={size} stroke={stroke} speed={speed} color={"white"} />
    </HStack>
  );
};
