import Plot from "./TimelinePlot";
import { Badge, Flex, Skeleton, Text, Spinner, VStack, HStack } from "@chakra-ui/react";
import { secondsToDate, stringDurationToHMSDuration, secondsToHMSDuration } from "../../utils/time";
import "./Timeline.css";
import { Moment, RecommendedMoment } from "../../models/moment";
import { SelectedMoment } from "../../models/selectedMoment";

type Props = {
  graphSeekCallback: (seconds: number) => void;
  moments: Moment[];
  recommendedMoments: RecommendedMoment[];
  playTime: number;
  videoInfo: any;
  focusedCallback: (selectedMoment: SelectedMoment | null) => void;
  selectedMoment: Pick<Moment, "id" | "start_time" | "end_time"> | null;
  selectedMomentType: "temporary" | "user" | "recommended" | null;
  updateSelectedMoment: (update: Pick<Moment, "start_time" | "end_time">) => void;
  signalLoading: boolean;
  signalError: boolean;
  signalData: Map<string, { x: Date[]; y: number[] }>;
};

export const Timeline = (props: Props) => {
  const {
    graphSeekCallback,
    moments,
    recommendedMoments,
    playTime,
    videoInfo,
    focusedCallback,
    selectedMoment,
    selectedMomentType,
    updateSelectedMoment,
    signalLoading,
    signalError,
    signalData,
  } = props;

  if (signalError) return <p>Error</p>;

  const datePlayTime = secondsToDate(playTime);
  const formattedTime = secondsToHMSDuration(playTime);

  return (
    <>
      {!videoInfo || signalLoading ? (
        <>
          <VStack mt="3" ml="3" mb="3" mr="1" w="100%" alignItems={"flex-start"}>
            <HStack>
              <Text fontSize="sm" fontWeight="semibold" pb={0.5}>
                Analyzing...
                {videoInfo?.search_progress ? ` ${videoInfo.search_progress}%` : ""}
              </Text>
              <Spinner size="sm" ml="2" alignSelf={"center"} mb={0.5} />
            </HStack>
            <Skeleton height="100%" py="24" w="100%" borderRadius="md" />
          </VStack>
        </>
      ) : (
        <Flex flexDirection="column" mt="3" ml="3" mb="3" w="100%">
          <Flex flexDirection="row" justifyContent="space-between">
            <Flex alignItems={"center"}>
              <Badge
                display="flex"
                flexDirection="column"
                justifyContent="center"
                borderRadius="2px"
                height="6"
                colorScheme={videoInfo.video_type === "VOD" ? "green" : "red"}
                py="1"
                px="2"
                variant="subtle"
                mr="2"
              >
                {videoInfo.video_type}
              </Badge>
              {videoInfo.status === "in-progress" && (
                <>
                  <Text fontSize="sm" fontWeight="semibold" pb={0.5}>
                    Analyzing...
                    {videoInfo.search_progress ? ` ${videoInfo.search_progress}%` : ""}
                  </Text>
                  <Spinner size="sm" ml="2" alignSelf={"center"} mb={0.5} />
                </>
              )}
            </Flex>
            <Flex>
              <Text color="green.400" fontSize="sm" fontWeight="semibold" mr="1">
                {formattedTime}
              </Text>
              {videoInfo.video_type === "VOD" && (
                <Text fontSize="sm" fontWeight="semibold" mr="1">
                  / {stringDurationToHMSDuration(videoInfo.video_duration)}
                </Text>
              )}
            </Flex>
          </Flex>
          <Plot
            signalData={signalData}
            graphSeekCallback={graphSeekCallback}
            moments={moments}
            recommendedMoments={recommendedMoments}
            playTime={datePlayTime}
            momentClickCallback={focusedCallback}
            selectedMoment={selectedMoment}
            selectedMomentType={selectedMomentType}
            updateSelectedMoment={updateSelectedMoment}
            isSearchStopped={videoInfo.status === "stopped"}
          />
        </Flex>
      )}
    </>
  );
};

export default Timeline;
