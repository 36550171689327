import { useQuery } from "@apollo/client";
import { Box, VStack, Text } from "@chakra-ui/react";
import { TopUsersThisWeekQuery } from "../../api/stats";
import { userNames } from "../../utils/knownUserIds";
import { allowedUserIds } from "../../utils/allowedUserIds";

function UsersNoSearchesThisWeek() {
  const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  const { data } = useQuery(TopUsersThisWeekQuery, {
    variables: {
      timestamp_seven_days_ago: sevenDaysAgo.toISOString().split(".")[0],
      excludedUserIDs: allowedUserIds,
    },
  });

  const searchedUserIds = data ? data.search.map((record) => record.user_id) : [];
  const allKnownUserIds = Object.keys(userNames);
  const usersToCheck = allKnownUserIds.filter((userId) => !allowedUserIds.includes(userId));
  const noSearchUserIds = usersToCheck.filter((userId) => !searchedUserIds.includes(userId));

  return (
    <Box p="6" backgroundColor={"gray.700"} w={"full"} borderRadius={8}>
      <Text fontWeight="medium" fontSize="sm" mb={4}>
        No Searches (Last 7 Days)
      </Text>
      <VStack spacing={1} alignItems={"flex-start"}>
        {noSearchUserIds.map((userId, index) => (
          <Box key={index}>
            <Text fontSize="sm">{userNames[userId] || userId}</Text>
          </Box>
        ))}
      </VStack>
    </Box>
  );
}

export default UsersNoSearchesThisWeek;
