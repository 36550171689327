import { gql } from "@apollo/client";

// Queries
export const PaymentPortalQuery = gql`
  query PaymentPortal($org: String) {
    getPaymentPortal(org: $org) {
      customerPortal
      paymentMethod
    }
  }
`;

// Mutations
export const ClipRequestMutation = gql`
  mutation ClipRequest($requests: [ClipRequestDataInput!]!) {
    clipRequest(requestsInput: $requests) {
      id
      moment_id
    }
  }
`;
