import { useQuery } from "@apollo/client";
import { Box, Wrap, WrapItem, Text, LinkOverlay, LinkBox, Badge } from "@chakra-ui/react";
import { MomentsThisWeekQuery } from "../../api/stats";
import { allowedUserIds } from "../../utils/allowedUserIds";

function MomentsThisWeek() {
  const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

  const { data } = useQuery(MomentsThisWeekQuery, {
    variables: {
      tags: [
        "Milestone",
        "Success",
        "Failure",
        "Unusual",
        "Social",
        "Humorous",
        "Happiness",
        "Sarcastic",
        "Anger",
        "Sadness",
        "Fear",
        "Surprise",
        "Dislike",
      ],
      timestamp_seven_days_ago: sevenDaysAgo.toISOString().split(".")[0],
      excludedUserIDs: allowedUserIds,
    },
  });

  const moments = data?.moment || [];

  return (
    <Box p="6" backgroundColor="gray.700" borderRadius={8} w="full">
      <Text fontWeight="medium" fontSize="sm" mb={4}>
        Moments (Last 7 Days)
      </Text>
      <Wrap spacing={4}>
        {moments.map((moment, index) => (
          <LinkBox key={index}>
            <LinkOverlay href={`/results/${moment.search_id}`}>
              <WrapItem bg={"gray.600"} p={3} borderRadius={8}>
                <Box>
                  <Text fontSize="sm">{moment.title}</Text>
                  <Text fontSize="sm">Search: {moment.search_id}</Text>
                  {moment.tags.length > 0 ? (
                    moment.tags.map((tag, tagIndex) => (
                      <Badge colorScheme="blue" key={tagIndex} mr={1} mb={1}>
                        {tag}
                      </Badge>
                    ))
                  ) : (
                    <Badge colorScheme="red" mr={1} mb={1}>
                      No Tags
                    </Badge>
                  )}
                </Box>
              </WrapItem>
            </LinkOverlay>
          </LinkBox>
        ))}
      </Wrap>
    </Box>
  );
}

export default MomentsThisWeek;
