import { Box, Badge, Alert, AlertIcon, AlertDescription } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import KbLayout from "../../components/KnowledgeBase/KbLayout";
import { AddIcon, CheckIcon, CloseIcon, DownloadIcon, SmallCloseIcon } from "@chakra-ui/icons";
import KbButton from "../../components/KnowledgeBase/KbButton";
import LinkedHeading from "../../components/KnowledgeBase/KbLinkedHeading";
import RelatedArticles from "../../components/KnowledgeBase/KbRelated";
import { KBSubPages, RivrLocations } from "../../models/navigation";
import {
  ChartLine,
  Filter,
  Message,
  MoreOne,
  Redo,
  ScreenRotation,
  SortThree,
} from "@icon-park/react";
import { Text, Link, UnorderedList, ListItem } from "../../components/KnowledgeBase/KbElements";

const KbMoments: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <KbLayout>
      <Box w={"full"} h={"full"}>
        <LinkedHeading as={"h1"} id={"results-moments"} py={2}>
          Moments
        </LinkedHeading>
        <Text>
          Moments are exciting, interesting, noteworthy, or otherwise valuable parts of a video that
          have a start and end. They’re a core aspect of Rivr—the platform is designed to help you
          find the ones that matter most to you.
        </Text>
        <Text>
          Using the{" "}
          <Link
            onClick={() =>
              navigate(
                `${RivrLocations.KnowledgeBase}/${KBSubPages.Timeline}?${searchParams.toString()}`
              )
            }
          >
            Timeline
          </Link>
          , you can find and <Link href={"#results-moments-creating"}>create Moments</Link> that can
          be <Link href={"#results-moments-downloading"}>downloaded</Link> and{" "}
          <Link href={"#results-moments-vertical"}>reformatted</Link>.
        </Text>
        <LinkedHeading as={"h2"} id={"results-moments-marking"} pt={6} pb={4}>
          Marking Moments
        </LinkedHeading>
        <Text>
          To mark a Moment, first bring up the Moment region by clicking anywhere on the Timeline.
          You can then move the Moment region left or right by clicking and dragging it.
        </Text>
        <Text>
          To change the length of your Moment, click and drag the handles on either end of the
          Moment region. The top of the Moment region will indicate the length of your selected
          clip. When clicking inside the Moment region, the play marker will move to that position.
          Clicking outside the Moment region will move and center it at that position.
        </Text>
        <LinkedHeading as={"h2"} id={"results-moments-creating"} pt={6} pb={4}>
          Creating and tagging new Moments
        </LinkedHeading>
        <Text>
          After you’ve marked out your Moment, click the add Moment{" "}
          <KbButton isIconButton={true} icon={<AddIcon />} colorScheme={"green"} /> button in the
          top-left corner of the Timeline. This will bring up a window where you can title and tag
          your Moment.
        </Text>
        <Text>
          Moments must have a title, but tags are optional. We recommend tagging your Moments so
          that you can better organize and <Link href={"#results-moments-filtering"}>filter</Link>{" "}
          them in your My Moments list.
        </Text>
        <LinkedHeading as={"h2"} id={"results-moments-list"} pt={6} pb={4}>
          My Moments
        </LinkedHeading>
        <Text>
          The My Moments list is located at the top-left of the Results page and tracks all of the
          Moments you’ve created from your video. It allows you to edit, delete, download, reformat,
          and filter your Moments. Clicking on a Moment in your My Moments list highlights that
          Moment in the list and on the Timeline. Once selected, you can edit the length of your
          Moment using the Moment region. To deselect a Moment, click on it again, in an open area
          of the My Moments list, or another area of the Timeline.
        </Text>

        <LinkedHeading as={"h3"} id={"results-moments-filtering"} pt={6} pb={4}>
          Filtering by tag
        </LinkedHeading>
        <Text>
          To filter by tag, first open the filter{" "}
          <KbButton isIconButton={true} icon={<Filter theme={"filled"} />} colorScheme={"gray"} />{" "}
          menu.
        </Text>
        <Text>
          If you want your filter to show Moments with all of the selected tags, use the{" "}
          <Badge>All of these tags</Badge> option. If you only want your filter to show Moments with
          at least one of the selected tags, use the <Badge>Any of these tags</Badge> option.
        </Text>
        <Text>
          Once complete, click the <KbButton text="Apply filter" colorScheme={"green"} /> button.
          Only one filter can be applied at once. To remove a filter, click the{" "}
          <KbButton isIconButton={true} icon={<SmallCloseIcon />} colorScheme={"green"} /> on the
          filter at the top of the My Moments list.
        </Text>

        <LinkedHeading as={"h3"} id={"results-moments-editing"} pt={6} pb={4}>
          Editing Moments
        </LinkedHeading>
        <Text>
          To edit the title or tags of a Moment, hover over the Moment and select the more options{" "}
          <KbButton isIconButton={true} icon={<MoreOne size={"20px"} />} colorScheme={"gray"} />{" "}
          menu, then <Badge>Edit</Badge>. Only one Moment can be edited at a time.
        </Text>

        <LinkedHeading as={"h3"} id={"results-moments-deleting"} pt={6} pb={4}>
          Deleting Moments
        </LinkedHeading>
        <Text>
          To delete a Moment, hover over the Moment and select the more options{" "}
          <KbButton isIconButton={true} icon={<MoreOne size={"20px"} />} colorScheme={"gray"} />{" "}
          menu, then <Badge>Delete</Badge>. Deleting a Moment cannot be undone.
        </Text>
        <Text>
          To delete many Moments at once, select the Moments you wish to delete using the
          checkboxes, or use the <Badge>Select All</Badge> option. Then, click the more options{" "}
          <KbButton isIconButton={true} icon={<MoreOne size={"20px"} />} colorScheme={"gray"} />{" "}
          menu at the top-right of the My Moments list and select <Badge>Delete</Badge>. Deleting
          multiple Moments cannot be undone.
        </Text>

        <LinkedHeading as={"h3"} id={"results-moments-downloading"} pt={6} pb={4}>
          Downloading Moments
        </LinkedHeading>
        <Text>
          To download a Moment, hover over the Moment and click the download{" "}
          <KbButton isIconButton={true} icon={<DownloadIcon />} colorScheme={"gray"} /> button.
        </Text>
        <Text>
          To download many Moments at once, select the Moments you wish to download using the
          checkboxes, or use the <Badge>Select All</Badge> option. Then, click the more options{" "}
          <KbButton isIconButton={true} icon={<MoreOne size={"20px"} />} colorScheme={"gray"} />{" "}
          menu at the top-right of the My Moments list and select <Badge>Download</Badge>.
        </Text>
        <Text>
          When downloading Moments, a progress indicator will appear at the top of the screen.
          Ensure that your browser isn’t blocking multiple downloads—this setting is often enabled
          by default. Downloaded Moments are MP4 files named with the Moment title and timestamp
          from the video.
        </Text>

        <LinkedHeading as={"h2"} id={"results-moments-recommended"} pt={6} pb={4}>
          Recommended Moments
        </LinkedHeading>
        <Text>
          To access Recommended Moments, click the Recommended tab at the top-left of the Results
          page. Recommended Moments can be sorted by rank or timestamp using the{" "}
          <KbButton isIconButton={true} icon={<SortThree />} colorScheme={"gray"} /> button. When
          sorting by rank, the Recommended Moments are in order based on the total amount of Signal
          activity.
        </Text>
        <Text>
          Recommended Moments can be accepted or rejected but not modified. When accepting by
          clicking the accept{" "}
          <KbButton isIconButton={true} icon={<CheckIcon />} colorScheme={"gray"} /> button, the
          recommendation creates a new Moment in your My Moments list where it can be renamed,
          tagged, and modified. When rejecting by clicking the reject{" "}
          <KbButton isIconButton={true} icon={<CloseIcon />} colorScheme={"gray"} /> button , the
          recommendation is removed from the Timeline and moved to the bottom of the Recommended
          Moments list. Rejected recommendations can be undone and re-added to the list by hover
          over the recommendation and clicking the undo{" "}
          <KbButton isIconButton={true} icon={<Redo />} colorScheme={"gray"} /> button.
        </Text>
        <Alert status={"info"} borderRadius={"md"} mb={8}>
          <AlertIcon />
          <AlertDescription>
            Recommended Moments are not guaranteed to be good content. What makes content good is
            subjective and specific to you and your audience. They simply point out heavy activity
            and outliers in the Signals. While Recommended Moments can be a great starting point, we
            highly suggest navigating the Signal data, chat, and speech to identify Moments that
            best suit your content style.
          </AlertDescription>
        </Alert>

        <LinkedHeading as={"h2"} id={"results-moments-vertical"} pt={6} pb={4}>
          Vertical Clip Editor
        </LinkedHeading>
        <Text>
          The Vertical Clip Editor is a handy tool that allows you to reformat your Moments, making
          them fit for posting on TikTok, YouTube Shorts, and other platforms. It comes with several
          features that enable creative flexibility in how the final video will look.
        </Text>
        <Text>
          To reformat a Moment using the Vertical Clip Editor, hover over a Moment and click the
          Vertical Clip Editor{" "}
          <KbButton isIconButton={true} icon={<ScreenRotation />} colorScheme={"gray"} /> button.
        </Text>
        <Text>
          When you’ve finished configuring your vertical clip, click the{" "}
          <KbButton isIconButton={false} text={"Finish"} colorScheme={"green"} /> button to download
          it.
        </Text>

        <LinkedHeading as={"h3"} id={"results-moments-vertical-templates"} pt={6} pb={3}>
          Templates
        </LinkedHeading>
        <Text>There are currently three templates to pick from:</Text>
        <UnorderedList spacing={2} pl={6} pb={4}>
          <ListItem>Full: One content area fully scaled to take up the entire area.</ListItem>
          <ListItem>Split: Two content areas with customizable facecam ratios.</ListItem>
          <ListItem>
            Freeform: Two fully customizable content areas with the option to disable the second
            content area.
          </ListItem>
        </UnorderedList>
        <Text>
          When using Freeform, any space not covered by a content area will be blurred for a
          professional look and feel.
        </Text>

        <LinkedHeading as={"h4"} id={"results-moments-vertical-facecam"} pt={6} pb={3}>
          Facecam size
        </LinkedHeading>
        <Text>When using Split, there are three facecam size options:</Text>
        <UnorderedList spacing={2} pl={6} pb={4}>
          <ListItem>16:9: The facecam content area will be shaped to a 16:9 aspect ratio.</ListItem>
          <ListItem>4:3: The facecam content area will be shaped to a 4:3 aspect ratio.</ListItem>
          <ListItem>
            50%: The facecam content area will be shaped to take up 50% of the total vertical space.
          </ListItem>
        </UnorderedList>

        <LinkedHeading as={"h4"} id={"results-moments-vertical-position"} pt={6} pb={3}>
          Facecam position
        </LinkedHeading>
        <Text>
          When using Split, the facecam content area can be placed at the top or bottom of the
          vertical space.
        </Text>

        <LinkedHeading as={"h4"} id={"results-moments-vertical-safezone"} pt={6} pb={3}>
          Safe Zone
        </LinkedHeading>
        <Text>
          When using any template, you can enable Safe Zones to visualize areas of the video that
          may be obscured by the interfaces of popular vertical social platforms.
        </Text>
        <Box py={6}>
          <Text textAlign={"right"} fontSize={"sm"} pb={0}>
            <em>
              Last updated on Sept. 12, 2024 by{" "}
              <Link href={"https://x.com/marcfromrivr"} isExternal>
                @MarcFromRivr
              </Link>
            </em>
          </Text>
        </Box>
        <RelatedArticles
          articles={[
            {
              title: "The Timeline",
              description: "How to navigate and understand Signals",
              icon: ChartLine,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Timeline}`,
            },
            {
              title: "Chat & Speech",
              description: "How to search chat and speech transcripts",
              icon: Message,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.ChatSpeech}`,
            },
          ]}
        />
      </Box>
    </KbLayout>
  );
};

export default KbMoments;
