import { Text, Kbd, VStack, ListItem, UnorderedList } from "@chakra-ui/react";
import { AddIcon, CheckIcon, DownloadIcon, QuestionIcon } from "@chakra-ui/icons";
import KbButton from "../KnowledgeBase/KbButton";
import { ScreenRotation } from "@icon-park/react";

export interface VideoStep {
  type: "video";
  content: string;
  videoHeadline: string;
  videoText: any;
}

export interface ContentStep {
  type: "content";
  image: string;
  contentHeadline: string;
  contentText: any;
}

export type TourStep = VideoStep | ContentStep;

export const RivrWalkthrough: TourStep[] = [
  {
    type: "video",
    content: "https://www.youtube.com/embed/ltw_X1p3mbY",
    videoHeadline: "Welcome to Rivr!",
    videoText: (
      <Text>
        This video will help you quickly understand the various aspects of Rivr. If you ever need a
        reminder, this and other tutorials can be accessed from the{" "}
        <KbButton
          isIconButton={true}
          borderRadius={"full"}
          icon={<QuestionIcon />}
          colorScheme={"yellow"}
        />{" "}
        Help menu.
      </Text>
    ),
  },
];

export const SearchTour: TourStep[] = [
  {
    type: "video",
    content: "https://www.youtube.com/embed/ltw_X1p3mbY?start=23&end=85&autoplay=1",
    videoHeadline: "Analyzing Videos",
    videoText: (
      <VStack align={"start"}>
        <Text>
          To start analyzing videos for Moments, paste a link to the video into the Search box and
          click <KbButton colorScheme={"green"} text={"Go"} /> or press <Kbd>Enter</Kbd>. After a
          few seconds, your video will appear in the Active analyzing section.
        </Text>
        <Text>
          When analysis is complete, it will appear in your History section and a notification will
          be sent to your email. Results will only appear once the video has finished analyzing. To
          view the Results page, click the thumbnail or title of a video.
        </Text>
        <Text>
          If you've been given a key to Rivr through a creator program or campaign, go to Account
          Settings, then the Campaigns tab, connect your Twitch account, then add your key to join a
          Campaign. You may not have access to the Search box, but do not worry, relevant VODs will
          automatically be analyzed for you when you finish streaming.
        </Text>
      </VStack>
    ),
  },
];

export const ResultsTour: TourStep[] = [
  {
    type: "video",
    content: "https://www.youtube.com/embed/ltw_X1p3mbY?start=86&end111&autoplay=1",
    videoHeadline: "Results: Workspace Overview",
    videoText: (
      <VStack align={"start"}>
        <Text>
          The Results page has several sections. In the center, you’ll find the video replay. Below
          that is the Timeline, where Signals visualize the stream. To the right, you have the Chat
          replay and Speech transcription, and on the left are your Moments.
        </Text>
      </VStack>
    ),
  },
  {
    type: "video",
    content: "https://www.youtube.com/embed/ltw_X1p3mbY?start=112&end205&autoplay=1",
    videoHeadline: "Results: The Timeline",
    videoText: (
      <VStack align={"start"}>
        <Text>
          The Timeline lets you control video playback like other editing tools, but Rivr’s Timeline
          stands out with Signal data, offering a unique way to understand video content.
        </Text>
        <UnorderedList pl={8}>
          <ListItem>
            Red Line: Tracks audio intensity. High peaks mean loud moments; dips may show where you
            left the stream.
          </ListItem>
          <ListItem>
            Green Line: Captures chat intensity. Peaks happen when chat is most active, like during
            funny or intense moments.
          </ListItem>
          <ListItem>
            Blue/Purple Lines: Indicate chat sentiment. Blue means positive reactions; purple
            indicates negative ones.
          </ListItem>
          <ListItem>
            Yellow Line (Hype): Points out your stream’s most exciting moments, especially when it
            spikes with other Signals.
          </ListItem>
        </UnorderedList>
      </VStack>
    ),
  },
  {
    type: "video",
    content: "https://www.youtube.com/embed/ltw_X1p3mbY?start=206&end245&autoplay=1",
    videoHeadline: "Results: Making Moments",
    videoText: (
      <VStack align={"start"}>
        <Text>
          To make a Moment, select the desired area on the Timeline. Adjust the Moment's length by
          dragging the handles on either side, or reposition it by clicking and dragging. When
          you're ready to save, click the{" "}
          <KbButton isIconButton={true} icon={<AddIcon />} colorScheme={"green"} />, give your
          Moment a title, and add tags to organize it. To edit a Moment's title or tags, click the
          triple-dot button and select "Edit." This menu also lets you delete any Moments you no
          longer need.
        </Text>
      </VStack>
    ),
  },
  {
    type: "video",
    content: "https://www.youtube.com/embed/ltw_X1p3mbY?start=246&end273&autoplay=1",
    videoHeadline: "Results: Recommended Moments",
    videoText: (
      <VStack align={"start"}>
        <Text>
          Rivr also recommends Moments for you, making it easier to find key points in your video.
          Click a recommended Moment to watch it. If you like it, click the{" "}
          <KbButton
            isIconButton={true}
            icon={<CheckIcon />}
            variant={"outline"}
            colorScheme={"gray"}
          />{" "}
          to move it to the My Moments tab. There, you can rename, tag with keywords, or adjust its
          start and end points.
        </Text>
        <Text>
          Note: Recommended Moments are not meant to indicate "good content", they simply help
          identify parts of your video where the Signal data is reacting strongly and are often the
          places we recommend looking at first.
        </Text>
      </VStack>
    ),
  },
  {
    type: "video",
    content: "https://www.youtube.com/embed/ltw_X1p3mbY?start=274&end311&autoplay=1",
    videoHeadline: "Results: Downloading Moments",
    videoText: (
      <VStack align={"start"}>
        <Text>
          To download a Moment, hover over it and click the{" "}
          <KbButton
            isIconButton={true}
            icon={<DownloadIcon />}
            variant={"outline"}
            colorScheme={"gray"}
          />{" "}
          icon. The Moment will be downloaded in the highest quality available for the VOD. A
          progress indicator will appear at the top of the screen. Make sure your browser isn’t
          blocking multiple downloads or pop-ups, as these settings are often enabled by default. To
          download several Moments at once, use the checkboxes or Select All, then go to the More
          Options menu and select Download.
        </Text>
      </VStack>
    ),
  },
  {
    type: "video",
    content: "https://www.youtube.com/embed/ltw_X1p3mbY?start=312&end377&autoplay=1",
    videoHeadline: "Results: Vertical Clip Editor",
    videoText: (
      <VStack align={"start"}>
        <Text>
          To create a vertical clip from your Moment, hover over it, click the{" "}
          <KbButton
            isIconButton={true}
            icon={<ScreenRotation style={{ transform: "scaleY(-1)rotate(75deg)" }} />}
            variant={"outline"}
            colorScheme={"gray"}
          />{" "}
          to open the editor. This editor is designed for quick vertical posting. Choose from three
          templates:
        </Text>
        <UnorderedList pl={8}>
          <ListItem>Full: A single layer that fills the entire vertical space.</ListItem>
          <ListItem>Split: Two layers with different size presets for the facecam.</ListItem>
          <ListItem>Freeform: Adjust two layers to any size you want.</ListItem>
        </UnorderedList>
        <Text>
          In the vertical preview, you can resize layers and customize alignment. Rivr automatically
          adds a blur to the background for a polished look. Use the safe zone option to visualize
          areas that might be obscured on social platforms. When you're ready, click{" "}
          <KbButton colorScheme={"green"} text={"Finish"} /> to download your clip.
        </Text>
      </VStack>
    ),
  },
  {
    type: "video",
    content: "https://www.youtube.com/embed/ltw_X1p3mbY?start=378&end408&autoplay=1",
    videoHeadline: "Results: Searching Chat & Speech",
    videoText: (
      <VStack align={"start"}>
        <Text>
          Chat Search allows you to search through chat messages from your stream. Look up any text,
          usernames, or keywords like "LOL" to find funny Moments. You can also set a command phrase
          like "clip it" for your community to type, so you can quickly locate specific Moments.
          Click on any line of text to jump straight to that point in the video.
        </Text>
        <Text>
          Speech Search functions similarly but focuses on spoken words during your stream.
        </Text>
      </VStack>
    ),
  },
];
