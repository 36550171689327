import { useMemo, useState } from "react";
import { TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";
import {
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Box,
  Tooltip,
  Icon,
  Select,
  FormControl,
  MenuGroup,
  FormHelperText,
  MenuDivider,
  Button,
  InputLeftElement,
} from "@chakra-ui/react";
import {
  Close,
  DoubleLeft,
  DoubleRight,
  FullScreenPlay,
  Filter,
  Left,
  Like,
  Right,
  Search,
} from "@icon-park/react";
import "../../Campaigns.css";
import { RivrChannel } from "../../../../models/rivrChannel";
import { useSearchParams } from "react-router-dom";
import {
  CampaignURLParams,
  ResultsURLParams,
  RivrLocations,
  updateURLParams,
} from "../../../../models/navigation";
import { RivrSearch } from "../../../../models/rivrSearch";
import { MomentType, RivrMoment } from "../../../../models/moment";
import { usePagination } from "../../../../hooks/usePagination";
import { durationToSeconds, offsetDate } from "../../../../utils/time";
import "../CampaignDiscover.css";
import MiniPlayer from "../../../MiniPlayer/MiniPlayer";
import { useMutation } from "@apollo/client";
import { UpdateMomentMutation } from "../../../../api/moment";
import { UpdateRecommendedMomentMutation } from "../../../../api/recommended-moment";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import DiscoverViewSelect from "../DiscoverViewSelect";
import { DiscoverMomentItem } from "./DiscoverMomentItem";
import { RivrLanguage } from "../../../../models/rivrLanguage";

interface Props {
  searches: RivrSearch[];
  fetchSearches: any;
  languages: RivrLanguage[];
  channels: RivrChannel[];
}

const CampaignDiscoverMoments = ({ searches, fetchSearches, languages, channels }: Props) => {
  const { getFlag } = useKindeAuth();

  const miniPlayerAllowed = getFlag("mini-player", false).value as boolean;
  const mentionsAllowed = getFlag("allow-mentions", false).value as boolean;

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedCampaign = searchParams.get(CampaignURLParams.SelectedCampaign) || "";
  const selectedChannels = searchParams.getAll(CampaignURLParams.SelectedChannels) || [];
  const sortOption = searchParams.get(CampaignURLParams.MomentSortOption) || "rank";
  const sortDirection = searchParams.get(CampaignURLParams.MomentSortDirection) || "desc";
  const momentTypeFilter = searchParams.get(CampaignURLParams.MomentTypeFilter) || "all";
  const momentStatusFilter = searchParams.getAll(CampaignURLParams.MomentStatusFilter) || [
    "favorited",
  ];
  const filterText = searchParams.get(CampaignURLParams.MomentFilterText) || "";
  const dateFilterStart = searchParams.get(CampaignURLParams.DateFilterStart) || "";
  const dateFilterEnd = searchParams.get(CampaignURLParams.DateFilterEnd) || "";
  const pageSize = searchParams.get(CampaignURLParams.MomentPageSize) || "50";
  const selectedLanguages = searchParams.getAll(CampaignURLParams.SelectedLanguages) || [];

  const [miniPlayer, setMiniPlayer] = useState(() => {
    const storedState = localStorage.getItem("miniPlayer");
    return storedState ? JSON.parse(storedState) : false;
  });
  const [selectedMoment, setSelectedMoment] = useState<RivrMoment | null>(null);
  const [playerScale, setPlayerScale] = useState(1);

  const [momentUpdateAPI] = useMutation(UpdateMomentMutation, {
    onCompleted() {
      fetchSearches({ campaign: selectedCampaign });
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
  });

  const [recommendedMomentUpdateAPI] = useMutation(UpdateRecommendedMomentMutation, {
    onCompleted() {
      fetchSearches({ campaign: selectedCampaign });
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
  });

  const handleFavorite = (moment: RivrMoment) => {
    moment.type === "User Generated"
      ? momentUpdateAPI({
          variables: { id: moment.id, object: { favorited: !moment.favorited } },
        })
      : recommendedMomentUpdateAPI({
          variables: { id: moment.id, object: { favorited: !moment.favorited } },
        });

    moment.favorited = !moment.favorited;
  };

  const handleArchive = (moment: RivrMoment, date: Date | null) => {
    moment.type === "User Generated"
      ? momentUpdateAPI({
          variables: { id: moment.id, object: { archived_at: date } },
        })
      : recommendedMomentUpdateAPI({
          variables: { id: moment.id, object: { archived_at: date } },
        });

    moment.archivedAt =
      date !== null
        ? date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })
        : null;
  };

  const toggleMiniPlayer = () => {
    const newState = !miniPlayer;
    setMiniPlayer(newState);
    localStorage.setItem("miniPlayer", JSON.stringify(newState));
  };

  const tableData: RivrMoment[] = useMemo(() => {
    let result: RivrMoment[] = [];

    searches.forEach((search) => {
      result = result.concat(search.userMoments);
      result = result.concat(search.recommendedMoments);
    });

    return result;
  }, [channels, searches]);

  const handleSort = (field: string) => {
    const newParams: Array<[string, string]> = [];
    if (sortOption === field) {
      newParams.push([
        CampaignURLParams.MomentSortDirection,
        sortDirection === "asc" ? "desc" : "asc",
      ]);
    } else {
      newParams.push(
        [CampaignURLParams.MomentSortOption, field],
        [CampaignURLParams.MomentSortDirection, "asc"]
      );
    }
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleMomentTypeFilter = (option: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.MomentTypeFilter, option]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleMomentStatusFilter = (options: string | string[]) => {
    const newParams: Array<[string, string | string[]]> = [
      [CampaignURLParams.MomentStatusFilter, options],
    ];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  let debounceTimer: any;
  const handleFilterText = (text: string) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      const newParams: Array<[string, string]> = [[CampaignURLParams.MomentFilterText, text]];
      setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
    }, 250);
  };

  const handleDateFilterStart = (date: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.DateFilterStart, date]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleDateFilterEnd = (date: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.DateFilterEnd, date]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handlePageSize = (size: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.MomentPageSize, size]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleLanguageSelect = (selection: string | string[]) => {
    const newParams: Array<[string, string | string[]]> = [];
    if (selection.length === 0) {
      newParams.push([CampaignURLParams.SelectedLanguages, []]);
    } else if (typeof selection === "string") {
      newParams.push([CampaignURLParams.SelectedLanguages, selection]);
    } else {
      newParams.push([
        CampaignURLParams.SelectedLanguages,
        selection.sort((a, b) => {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        }),
      ]);
    }
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const sortMomentsData = useMemo(() => {
    return (a: RivrMoment, b: RivrMoment) => {
      if (!sortOption) return 0;
      let valueA: any = 0;
      let valueB: any = 0;

      if (sortOption === "hypeScore") {
        valueA = a.annotations["Hype"] ?? 0;
        valueB = b.annotations["Hype"] ?? 0;
      } else {
        valueA = a[sortOption as keyof RivrMoment];
        valueB = b[sortOption as keyof RivrMoment];
      }

      let comparison = 0;
      if (typeof valueA === "string" && typeof valueB === "string") {
        comparison =
          sortOption === "date"
            ? new Date(valueA).valueOf() - new Date(valueB).valueOf()
            : sortOption === "timestamp"
            ? durationToSeconds(a["timestamp"]) - durationToSeconds(b["timestamp"])
            : valueA.localeCompare(valueB);
      } else {
        comparison = Number(valueA) - Number(valueB);
      }

      // Back up sort by most recent date, then alphabetical by channel, then first timestamp
      if (comparison === 0) {
        return a["date"] !== b["date"]
          ? new Date(b["date"]).valueOf() - new Date(a["date"]).valueOf()
          : a["channel"] !== b["channel"]
          ? a["channel"].localeCompare(b["channel"])
          : durationToSeconds(a["timestamp"]) - durationToSeconds(b["timestamp"]);
      }

      return sortDirection === "asc" ? comparison : -comparison;
    };
  }, [searchParams]);

  const filterMomentsData = useMemo(() => {
    return (item: RivrMoment) => {
      // if (item.accepted) return false; // Filter out accepted recommendations

      if (selectedLanguages.length > 0 && selectedLanguages.includes(item.language) === false)
        return false;
      if (selectedChannels.length > 0 && !selectedChannels.includes(item.channel)) return false;
      if (!momentStatusFilter.includes("archived") && item.archivedAt !== null) return false;
      if (!momentStatusFilter.includes("favorited") && item.favorited === true) return false;
      if (momentTypeFilter !== "all" && item.type.toLowerCase() !== momentTypeFilter) return false;

      const momentDate = new Date(item.date);
      const filterStartDateUTC = offsetDate(dateFilterStart);
      const filterEndDateUTC = offsetDate(dateFilterEnd);
      filterEndDateUTC.setDate(filterEndDateUTC.getDate() + 1); // make the end date inclusive
      if (dateFilterStart && momentDate.valueOf() >= filterStartDateUTC.valueOf() === false)
        return false;
      if (dateFilterEnd && momentDate.valueOf() < filterEndDateUTC.valueOf() === false)
        return false;

      if (filterText.trim()) {
        const searchText = filterText.toLowerCase();
        return (
          item.channel.toLowerCase().includes(searchText) ||
          item.vod.toLowerCase().includes(searchText) ||
          item.title.toLowerCase().includes(searchText)
        );
      }
      return true;
    };
  }, [searchParams]);

  const limitRecommendedMomentsPerVOD = (moments: RivrMoment[]) => {
    const limit = 30;
    const recommendedMoments: Record<string, RivrMoment[]> = {};
    const otherMoments: RivrMoment[] = [];

    const sortedMoments = moments.sort((a, b) => b.rank - a.rank);
    sortedMoments.forEach((moment) => {
      if (moment.type !== "Recommended") {
        otherMoments.push(moment);
        return;
      }
      const vod = `${moment.vod} - ${moment.channel} - ${moment.date}`;
      if (!recommendedMoments[vod]) recommendedMoments[vod] = [moment];
      else {
        if (recommendedMoments[vod].length < limit) recommendedMoments[vod].push(moment);
      }
    });

    return otherMoments.concat(Object.values(recommendedMoments).flat());
  };

  const {
    elements,
    totalElements,
    page,
    totalPages,
    setPage,
    nextPage,
    previousPage,
    hasNextPage,
    hasPreviousPage,
    from,
    to,
  } = usePagination<RivrMoment>(
    limitRecommendedMomentsPerVOD(tableData),
    sortMomentsData,
    filterMomentsData,
    Number(pageSize)
  );

  const selectedChannelsHeaderText = useMemo(() => {
    const channelCutOff = 3; // Number of channels to display before truncating
    if (selectedChannels.length === 0) {
      return <></>;
    } else if (selectedChannels.length <= channelCutOff) {
      return (
        <Text noOfLines={1} cursor={"default"} fontWeight={"semibold"} fontSize={"xl"}>
          {selectedChannels.join(", ")}
        </Text>
      );
    } else {
      return (
        <Tooltip openDelay={600} label={selectedChannels.join(", ")}>
          <Text noOfLines={1} cursor={"default"} fontWeight={"semibold"} fontSize={"xl"}>
            {selectedChannels.slice(0, channelCutOff).join(", ")}, +
            {selectedChannels.length - channelCutOff}
          </Text>
        </Tooltip>
      );
    }
  }, [selectedChannels]);

  const resetFilters = () => {
    const newParams: Array<[string, string | string[]]> = [
      [CampaignURLParams.DateFilterStart, ""],
      [CampaignURLParams.DateFilterEnd, ""],
      [CampaignURLParams.MomentTypeFilter, "all"],
      [CampaignURLParams.MomentStatusFilter, ["favorited"]],
      [CampaignURLParams.MomentFilterText, ""],
      [CampaignURLParams.SelectedLanguages, []],
    ];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  return selectedCampaign === "" ? (
    <HStack w={"100%"} h={"100%"} spacing={4} px={8} py={8} justifyContent={"center"}>
      <Text fontSize="md" color="gray.300" textAlign="center">
        Select a campaign
      </Text>
    </HStack>
  ) : searches.length === 0 ? (
    <HStack w={"100%"} h={"100%"} spacing={4} px={8} py={8} justifyContent={"center"}>
      <Text fontSize="md" color="gray.300" textAlign="center">
        No videos in the selected campaign
      </Text>
    </HStack>
  ) : (
    <VStack
      h={"100%"}
      w={"100%"}
      alignItems={"flex-start"}
      overflowY={"auto"}
      className={"discover-moments"}
    >
      <HStack
        justifyContent={"space-between"}
        position={"sticky"}
        top={0}
        p={4}
        bg={"gray.800"}
        zIndex={2}
        w={"100%"}
      >
        <HStack w="100%" spacing={4} justifyContent={"flex-start"}>
          {mentionsAllowed && <DiscoverViewSelect />}
          {selectedChannelsHeaderText}
        </HStack>
        <HStack w="100%" justifyContent={"flex-end"}>
          <HStack>
            <Text w={"100%"} noOfLines={1} fontSize={"xs"} color={"gray.400"} textAlign={"right"}>
              {totalElements > 0
                ? `Showing ${from} - ${to} of ${totalElements} ${
                    totalElements === 1 ? "Moment" : "Moments"
                  }`
                : "Showing 0 Moments"}
            </Text>
            <InputGroup size={"sm"}>
              <InputLeftElement>
                <Search />
              </InputLeftElement>
              <Input
                className={"amp-unmask"}
                w={"360px"}
                type={"text"}
                size={"sm"}
                borderRadius={"md"}
                placeholder={"Filter by channel, VOD title, or Moment"}
                defaultValue={filterText}
                onChange={(e) => handleFilterText(e.target.value)}
              />

              {/* <InputRightElement>
              <IconButton
                aria-label="Clear input"
                icon={<Close />}
                size={"xs"}
                variant={"ghost"}
                borderRadius={"full"}
                onClick={() => handleFilterText("")}
                opacity={filterText === "" ? 0 : 1}
              />
            </InputRightElement> */}
            </InputGroup>
          </HStack>
          <HStack>
            <Box>
              <Menu closeOnSelect={false} placement="bottom-end">
                <MenuButton
                  as={IconButton}
                  colorScheme="gray"
                  icon={<Filter theme="filled" />}
                  size="sm"
                />
                <MenuList maxH={"60vh"} overflowY={"auto"} sx={{ scrollbarWidth: "thin" }}>
                  <MenuGroup title="Date">
                    <HStack px={4} pb={2}>
                      <FormControl>
                        <Input
                          className={"amp-unmask"}
                          type={"date"}
                          size={"sm"}
                          borderRadius={"md"}
                          value={dateFilterStart}
                          onChange={(e) => handleDateFilterStart(e.target.value)}
                        />
                        <FormHelperText>From</FormHelperText>
                      </FormControl>
                      <FormControl>
                        <Input
                          className={"amp-unmask"}
                          type={"date"}
                          size={"sm"}
                          borderRadius={"md"}
                          value={dateFilterEnd}
                          onChange={(e) => handleDateFilterEnd(e.target.value)}
                        />
                        <FormHelperText>To</FormHelperText>
                      </FormControl>
                    </HStack>
                  </MenuGroup>
                  <MenuDivider />
                  <MenuOptionGroup
                    value={momentTypeFilter}
                    title="Type"
                    type="radio"
                    onChange={(value) => handleMomentTypeFilter(value as "All" | MomentType)}
                  >
                    <MenuItemOption value="all">All</MenuItemOption>
                    <MenuItemOption value="recommended">Recommended</MenuItemOption>
                    <MenuItemOption value="user generated">User Generated</MenuItemOption>
                  </MenuOptionGroup>
                  <MenuDivider />
                  <MenuOptionGroup
                    value={momentStatusFilter}
                    title="Status"
                    type="checkbox"
                    onChange={(values) => handleMomentStatusFilter(values)}
                  >
                    <MenuItemOption value="favorited">Favorited</MenuItemOption>
                    <MenuItemOption value="archived">Archived</MenuItemOption>
                  </MenuOptionGroup>
                  {languages.length > 0 && (
                    <>
                      <MenuDivider />
                      <MenuOptionGroup title={"Language"} />
                      <MenuItemOption
                        type="checkbox"
                        key={"all"}
                        value={"all"}
                        isChecked={selectedLanguages.length === 0}
                        onClick={() => handleLanguageSelect([])}
                      >
                        All
                      </MenuItemOption>
                      <MenuOptionGroup
                        value={selectedLanguages}
                        type="checkbox"
                        onChange={(values) => handleLanguageSelect(values)}
                      >
                        {languages.map((language) => {
                          return (
                            <MenuItemOption key={language.code} value={language.code}>
                              {language.name}
                            </MenuItemOption>
                          );
                        })}
                      </MenuOptionGroup>
                    </>
                  )}
                  <MenuDivider />
                  <MenuGroup>
                    <Button
                      size={"sm"}
                      colorScheme={"gray"}
                      variant={"ghost"}
                      onClick={resetFilters}
                      mx={3}
                      fontWeight={"normal"}
                    >
                      Reset filters
                    </Button>
                  </MenuGroup>
                </MenuList>
              </Menu>
            </Box>
            {miniPlayerAllowed && (
              <Tooltip label={"Toggle Mini-player"} placement={"bottom-end"}>
                <IconButton
                  size={"sm"}
                  aria-label={"Toggle Mini-player"}
                  icon={<FullScreenPlay theme="filled" />}
                  onClick={toggleMiniPlayer}
                  colorScheme={miniPlayer ? "green" : "gray"}
                />
              </Tooltip>
            )}
          </HStack>
        </HStack>
      </HStack>

      {tableData.length > 0 ? (
        <>
          <TableContainer
            w={"100%"}
            p={4}
            pt={0}
            overflowY={"auto"}
            mt={"0 !important"}
            className={"moments-table campaign-discover"}
            justifyContent={"space-between"}
            h={"100%"}
            flex={1}
            alignContent={"flex-start"}
          >
            <Table size={"sm"} variant={"simple"}>
              <Thead>
                <Tr h={10}>
                  <Th bg={"gray.800"} onClick={() => handleSort("favorited")} w={1} pr={0} px={2}>
                    <HStack>
                      <Icon as={Like} size={16} theme={"filled"} mb={6} ml={4} />
                      <Box display="inline-block" width={4}>
                        {sortOption === "favorited" &&
                          (sortDirection === "asc" ? (
                            <TriangleUpIcon boxSize={3} />
                          ) : (
                            <TriangleDownIcon boxSize={3} />
                          ))}
                      </Box>
                    </HStack>
                  </Th>
                  <Th bg={"gray.800"} onClick={() => handleSort("rank")} w={4} px={2}>
                    Rank
                    <Box display="inline-block" ml={1} width={4}>
                      {sortOption === "rank" &&
                        (sortDirection === "asc" ? (
                          <TriangleUpIcon boxSize={3} />
                        ) : (
                          <TriangleDownIcon boxSize={3} />
                        ))}
                    </Box>
                  </Th>
                  <Th bg={"gray.800"} onClick={() => handleSort("channel")} w={32} px={2}>
                    Channel
                    <Box display="inline-block" ml={1} width={4}>
                      {sortOption === "channel" &&
                        (sortDirection === "asc" ? (
                          <TriangleUpIcon boxSize={3} />
                        ) : (
                          <TriangleDownIcon boxSize={3} />
                        ))}
                    </Box>
                  </Th>
                  <Th bg={"gray.800"} onClick={() => handleSort("hypeScore")} w={32}>
                    Hype Score
                    <Box display="inline-block" ml={1} width={4}>
                      {sortOption === "hypeScore" &&
                        (sortDirection === "asc" ? (
                          <TriangleUpIcon boxSize={3} />
                        ) : (
                          <TriangleDownIcon boxSize={3} />
                        ))}
                    </Box>
                  </Th>
                  <Th bg={"gray.800"} w={40} cursor={"default"}>
                    Chat Sentiment
                  </Th>
                  <Th bg={"gray.800"} onClick={() => handleSort("date")} w={32} px={2}>
                    Date
                    <Box display="inline-block" ml={1} width={4}>
                      {sortOption === "date" &&
                        (sortDirection === "asc" ? (
                          <TriangleUpIcon boxSize={3} />
                        ) : (
                          <TriangleDownIcon boxSize={3} />
                        ))}
                    </Box>
                  </Th>
                  <Th bg={"gray.800"} onClick={() => handleSort("timestamp")} w={24} px={2}>
                    Timestamp
                    <Box display="inline-block" ml={1} width={4}>
                      {sortOption === "timestamp" &&
                        (sortDirection === "asc" ? (
                          <TriangleUpIcon boxSize={3} />
                        ) : (
                          <TriangleDownIcon boxSize={3} />
                        ))}
                    </Box>
                  </Th>
                  <Th bg={"gray.800"} onClick={() => handleSort("title")} w={32} maxW={64} px={2}>
                    Moment
                    <Box display="inline-block" ml={1} width={4}>
                      {sortOption === "title" &&
                        (sortDirection === "asc" ? (
                          <TriangleUpIcon boxSize={3} />
                        ) : (
                          <TriangleDownIcon boxSize={3} />
                        ))}
                    </Box>
                  </Th>
                  <Th bg={"gray.800"} onClick={() => handleSort("type")} w={32} px={2}>
                    Type
                    <Box display="inline-block" ml={1} width={4}>
                      {sortOption === "type" &&
                        (sortDirection === "asc" ? (
                          <TriangleUpIcon boxSize={3} />
                        ) : (
                          <TriangleDownIcon boxSize={3} />
                        ))}
                    </Box>
                  </Th>
                  <Th bg={"gray.800"} onClick={() => handleSort("vod")} px={2}>
                    VOD
                    <Box display="inline-block" ml={1} width={4}>
                      {sortOption === "vod" &&
                        (sortDirection === "asc" ? (
                          <TriangleUpIcon boxSize={3} />
                        ) : (
                          <TriangleDownIcon boxSize={3} />
                        ))}
                    </Box>
                  </Th>
                  <Th bg={"gray.800"} isNumeric w={32} cursor={"default"} px={2}>
                    Actions
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {elements.map((item, index) => (
                  <DiscoverMomentItem
                    key={index}
                    moment={item}
                    fetchSearches={fetchSearches}
                    miniPlayer={miniPlayer}
                    selectedMoment={selectedMoment}
                    setSelectedMoment={setSelectedMoment}
                  />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <HStack w={"100%"} h={"100%"} spacing={4} px={8} py={8} justifyContent={"center"}>
          <Text fontSize="md" color="gray.300" textAlign="center">
            No moments in the selected campaign
          </Text>
        </HStack>
      )}

      <HStack
        py={4}
        bg={"gray.800"}
        mt={"0 !important"}
        pos={"sticky"}
        bottom={0}
        w={"100%"}
        borderTopWidth={1}
        zIndex={1}
      >
        <HStack w={"100%"} justifyContent={"center"}>
          <HStack w={"fit-content"}>
            <Text fontSize={"sm"} color={"gray.400"}>
              Showing
            </Text>
            <Select
              size={"sm"}
              borderRadius={"md"}
              color={"gray.400"}
              value={pageSize}
              minW={"max-content"}
              variant={"outline"}
              onChange={(e) => handlePageSize(e.target.value)}
            >
              <option key={10} value={10}>
                10
              </option>
              <option key={20} value={20}>
                20
              </option>
              <option key={30} value={30}>
                30
              </option>
              <option key={40} value={40}>
                40
              </option>
              <option key={50} value={50}>
                50
              </option>
            </Select>
          </HStack>
          <IconButton
            title={"First page"}
            size={"sm"}
            variant={"outline"}
            aria-label={"First page"}
            icon={<DoubleLeft />}
            onClick={() => setPage(1)}
            disabled={page === 1}
          />
          <IconButton
            title={"Previous page"}
            size={"sm"}
            variant={"outline"}
            aria-label={"Previous page"}
            icon={<Left />}
            onClick={previousPage}
            disabled={!hasPreviousPage}
          />
          <HStack px={2}>
            <Text fontSize={"sm"} color={"gray.400"}>
              Page
            </Text>
            <Input
              className={"amp-unmask"}
              textAlign={"right"}
              textColor={"gray.400"}
              value={page}
              size={"sm"}
              maxW={14}
              borderRadius={"md"}
              type={"number"}
              onChange={(e) => {
                setPage(Number(e.target.value));
              }}
            />
            <Text fontSize={"sm"} color={"gray.400"}>{` of ${totalPages}`}</Text>
          </HStack>
          <IconButton
            title={"Next page"}
            size={"sm"}
            variant={"outline"}
            aria-label={"Next page"}
            icon={<Right />}
            onClick={nextPage}
            disabled={!hasNextPage}
          />
          <IconButton
            title={"Last page"}
            size={"sm"}
            variant={"outline"}
            aria-label={"Last page"}
            icon={<DoubleRight />}
            onClick={() => setPage(totalPages)}
            disabled={page === totalPages}
          />
        </HStack>
      </HStack>

      {miniPlayerAllowed && miniPlayer && selectedMoment && (
        <MiniPlayer
          key={selectedMoment.id}
          timestamp={selectedMoment.timestamp}
          resultsLink={`${RivrLocations.Results}/${selectedMoment.searchId}?${updateURLParams(
            searchParams.toString(),
            [
              [ResultsURLParams.SelectedMoment, selectedMoment.id],
              [ResultsURLParams.SelectedTimestamp, ""],
              [ResultsURLParams.SelectedPanel, ""],
            ]
          )}`}
          videoUrl={selectedMoment.url}
          toggleVisibility={() => setSelectedMoment(null)}
          playerScale={playerScale}
          setPlayerScale={setPlayerScale}
          handleFavorite={() => handleFavorite(selectedMoment)}
          handleArchive={(date) => handleArchive(selectedMoment, date)}
          moment={selectedMoment}
        />
      )}
    </VStack>
  );
};

export default CampaignDiscoverMoments;
