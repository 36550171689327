export const secondsToDate = (seconds: number): Date => new Date(0, 0, 1, 0, 0, seconds);

const zeroTime = new Date(0, 0).getTime();
export const dateToSeconds = (time: Date): number => Math.round((time.getTime() - zeroTime) / 1000);

export const dateToInterval = (time: Date): string =>
  ("0" + time.getHours()).slice(-2) +
  ":" +
  ("0" + time.getMinutes()).slice(-2) +
  ":" +
  ("0" + time.getSeconds()).slice(-2);

export const secondsToInterval = (seconds: number): string =>
  dateToInterval(secondsToDate(seconds));

export const secondsToTwitchTime = (seconds: number): string => {
  const s = Math.floor(seconds % 60);
  const m = Math.floor((seconds % 3600) / 60);
  const h = Math.floor(seconds / 3600);
  const formattedS = ("0" + s).slice(-2);
  const formattedM = ("0" + m).slice(-2);
  if (h) return `${h}:${formattedM}:${formattedS}`;
  return `${m}:${formattedS}`;
};

export const stringDurationToHMSDuration = (duration: string): string => {
  if (duration.includes("day")) {
    const durationParts = duration.split(" ");
    const days = durationParts[0];
    if (durationParts.length === 2) {
      return `${Number(days) * 24}:00:00`;
    }
    const time = durationParts[2];
    const timeParts = time.split(":", 3);
    const h = timeParts[0];
    const m = timeParts[1];
    const s = timeParts[2];
    return `${Number(h) + Number(days) * 24}:${m}:${s}`;
  } else return duration;
};

export const durationToSeconds = (duration: string): number => {
  const values = stringDurationToHMSDuration(duration).split(":").map(Number);
  return values[0] * 3600 + values[1] * 60 + values[2];
};

export const secondsToHMSDuration = (seconds: number): string => {
  const s = Math.floor(seconds % 60);
  const m = Math.floor((seconds % 3600) / 60);
  const h = Math.floor(seconds / 3600);
  const formattedS = ("0" + s).slice(-2);
  const formattedM = ("0" + m).slice(-2);
  const formattedH = h < 10 ? "0" + h : String(h);
  return `${formattedH}:${formattedM}:${formattedS}`;
};

export const secondsToHMSFormat = (seconds: number): string => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  const formattedH = h > 0 ? `${h}h` : "";
  const formattedM = m > 0 ? `${m}m` : "";
  const formattedS = s > 0 ? `${s}s` : "";
  return `${formattedH}${formattedM}${formattedS}`;
};

export const offsetDate = (dateString: string): Date => {
  // The date pickers display dates in local time but stores them as if they were UTC
  // Adds the user's local offset to the dates used for filtering so that it better represents what the user intended
  const result = new Date(dateString);
  result.setMinutes(result.getMinutes() + result.getTimezoneOffset());
  return result;
};

export const formatDateForDisplay = (date: Date): string => {
  return date
    .toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    })
    .replace(/(\w+)(\s\d+, \d+)/, "$1. $2")
    .replace(/(May.)/, "May");
};
