import { useEffect, useCallback, useRef } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

const CHATWOOT_BASE_URL = "https://app.chatwoot.com";
const CHATWOOT_ID = process.env.REACT_APP_CHAT_ID;

const useChatwootScript = () => {
  const chatwootReady = useRef(false);

  const loadScript = useCallback(() => {
    if (!CHATWOOT_ID) return;

    window.chatwootSettings = {
      position: "right",
      type: "standard",
      launcherTitle: "Chat with us",
      darkMode: "light",
    };

    const script = document.createElement("script");
    script.src = `${CHATWOOT_BASE_URL}/packs/js/sdk.js`;
    script.async = true;
    script.onload = () => {
      window.chatwootSDK.run({
        websiteToken: CHATWOOT_ID,
        baseUrl: CHATWOOT_BASE_URL,
      });
      chatwootReady.current = true;
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const cleanup = loadScript();
    return cleanup;
  }, [loadScript]);

  return chatwootReady;
};

const useSetUserInfo = (user, chatwootReady) => {
  const userInfoSet = useRef(false);

  const setUserInChatwoot = useCallback(() => {
    if (!window.$chatwoot || !chatwootReady.current || !user || userInfoSet.current) return;

    try {
      window.$chatwoot.setUser(user.id, {
        name: `${user.given_name} ${user.family_name}`,
        email: user.email,
      });
      window.$chatwoot.setCustomAttributes({
        kinde_id: user.id,
        kinde_email: user.email,
        kinde_given_name: user.given_name,
        kinde_family_name: user.family_name,
        //placeholder rivr_plan
      });
      window.$chatwoot.setConversationCustomAttributes({
        current_page: window.location.href,
      });
      userInfoSet.current = true;
    } catch (error) {
      // Error handling could be added here if needed
    }
  }, [user, chatwootReady]);

  useEffect(() => {
    setUserInChatwoot();
  }, [setUserInChatwoot]);

  return setUserInChatwoot;
};

const useChatwootObserver = (callback) => {
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      for (let mutation of mutations) {
        for (let node of mutation.addedNodes) {
          if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains("chatwoot-widget")) {
            callback();
            observer.disconnect();
            return;
          }
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, [callback]);
};

const LiveChat = () => {
  const { user } = useKindeAuth();
  const chatwootReady = useChatwootScript();
  const setUserInChatwoot = useSetUserInfo(user, chatwootReady);

  const pollForChatwootAndUser = useCallback(() => {
    if (window.$chatwoot && user) {
      setUserInChatwoot();
    }
  }, [user, setUserInChatwoot]);

  useEffect(() => {
    const interval = setInterval(pollForChatwootAndUser, 1000);
    return () => clearInterval(interval);
  }, [pollForChatwootAndUser]);

  useChatwootObserver(pollForChatwootAndUser);

  return null;
};

export default LiveChat;
