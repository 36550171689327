import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  FormControl,
  FormLabel,
  Text,
  FormHelperText,
  Wrap,
  WrapItem,
  Tooltip,
} from "@chakra-ui/react";
import { useState } from "react";
import { Moment } from "../../models/moment";
import { pillOptions } from "../../models/tags";
import * as amplitude from "@amplitude/analytics-browser";

export type EditData = Pick<Moment, "title" | "tags">;

type Props = {
  confirm: (data: EditData) => void;
  onClose: () => void;
  confirmButtonText: string;
  initialData: EditData;
};

export const EditMoment = (props: Props) => {
  const { confirm, onClose, confirmButtonText, initialData } = props;
  const [title, setTitle] = useState(initialData.title);
  const [tags, setTags] = useState(initialData.tags);

  const togglePill = (label: string) => {
    if (tags.includes(label)) {
      setTags(tags.filter((tag) => tag !== label));
    } else {
      setTags([...tags, label]);
    }
  };
  const confirmAction = () => {
    confirm({ title: title.trim(), tags });
    onClose();
    amplitude.track("Moment Dialog Saved");
  };

  return (
    <Modal isOpen={true} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Moment Details</ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (title.trim() !== "") confirmAction();
            }}
          >
            <FormControl isRequired>
              <FormLabel>Title</FormLabel>
              <Input
                className={"inp-moment-title amp-unmask"}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                maxLength={100}
              />
            </FormControl>
            <FormControl mt="4">
              <FormLabel
                optionalIndicator={
                  <Text fontSize="sm" as="span" color="whiteAlpha.600" fontWeight={"normal"}>
                    {" "}
                    (Select all that apply)
                  </Text>
                }
              >
                Tags
              </FormLabel>
              <Wrap spacing={2}>
                {pillOptions.map((pill) => (
                  <WrapItem key={pill.label}>
                    <Tooltip label={pill.tooltip}>
                      <Button
                        borderRadius={"full"}
                        size="sm"
                        fontWeight={"normal"}
                        variant={tags.includes(pill.label) ? "solid" : "outline"}
                        colorScheme={tags.includes(pill.label) ? "green" : "gray"}
                        border={tags.includes(pill.label) ? "1px solid transparent" : "1px"}
                        onClick={() => togglePill(pill.label)}
                      >
                        {pill.label}
                      </Button>
                    </Tooltip>
                  </WrapItem>
                ))}
              </Wrap>

              <FormHelperText>
                Filter your Moments by tag and help us improve. Tags help improve future searches.
              </FormHelperText>
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose} className="btn-edit-moment-cancel">
            Cancel
          </Button>
          <Button
            className="btn-edit-moment-create"
            ml="2"
            colorScheme={"green"}
            onClick={confirmAction}
            isDisabled={title.trim() === ""}
          >
            {confirmButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
