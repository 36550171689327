import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  split,
  concat,
} from "@apollo/client";
import Cookies from "js-cookie";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { KindLoading } from "./components/KindLoading/KindLoading";

function getHeaders(token, requestedRole) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  if (requestedRole) headers["x-hasura-role"] = requestedRole;
  const role = Cookies.get("xHasuraRole");
  if (role === "admin") {
    headers["x-hasura-role"] = role;
  }

  headers["x-client-version"] = process.env.REACT_APP_VERSION;

  return headers;
}

const ApolloWrapper = ({ children }) => {
  const { getToken, isAuthenticated, isLoading } = useKindeAuth();

  if (isLoading) return <KindLoading />;

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  };

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_GRAPHQL_LINK}`,
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${process.env.REACT_APP_GRAPHQLS_LINK}`,
      connectionParams: async () => {
        if (!isAuthenticated) return { headers: {} };
        const token = await getToken();
        return {
          headers: getHeaders(token),
        };
      },
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink
  );

  const authMiddleware = setContext(async (_, { headers, role }) => {
    if (!isAuthenticated) return { headers };
    const token = await getToken();
    return {
      headers: {
        ...headers,
        ...getHeaders(token, role),
      },
    };
  });

  const client = new ApolloClient({
    link: concat(authMiddleware, splitLink),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
export default ApolloWrapper;
