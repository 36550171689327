import { useContext } from "react";
import { Box, Image, Button } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import KbLayout from "../../components/KnowledgeBase/KbLayout";
import LinkedHeading from "../../components/KnowledgeBase/KbLinkedHeading";
import LiveChatIcon from "../../assets/kb/live-chat-icon.svg";
import { Text, Link, UnorderedList, ListItem } from "../../components/KnowledgeBase/KbElements";
import { AccountContext } from "../../context/AccountContext";

const KbSupport: React.FC = () => {
  const { memberships } = useContext(AccountContext);
  const isOrgMember = memberships.length > 0;

  return (
    <KbLayout>
      <Box w={"full"} h={"full"}>
        <LinkedHeading as={"h1"} id={"support-top"} py={2}>
          Support
        </LinkedHeading>
        <LinkedHeading as={"h2"} id={"support-channels"} pt={6} pb={4}>
          Support channels
        </LinkedHeading>
        <Text>
          Rivr offers several support channels for reaching our team. Please be kind and patient
          with us—we’re all working hard to make your Rivr experience the best it can be.
        </Text>
        <LinkedHeading as={"h3"} id={"support-live-chat"} pt={6} pb={4}>
          Live chat
        </LinkedHeading>
        <Text>
          You can reach us directly from the Rivr platform by clicking the Chat Support{" "}
          <Image
            src={LiveChatIcon}
            boxSize={6}
            display={"inline-block"}
            verticalAlign={"sub"}
            aria-label="Live chat support icon"
            title="Live chat support icon"
            alt="Live chat support icon"
          />{" "}
          button in the bottom-right corner of any page. We respond fastest during business hours
          (Monday–Friday, 9:00 am – 5:00 pm ET). Outside these times, it may take a bit longer to
          respond.
        </Text>

        {isOrgMember ? (
          <>
            <LinkedHeading as={"h3"} id={"support-discord"} pt={6} pb={4}>
              Private Discord
            </LinkedHeading>
            <Text>
              Our private organization Discord provides you with direct access to Rivr developers
              and support. To set up a channel for your team, please contact your account manager.
            </Text>
            <Button
              as={"a"}
              colorScheme={"blue"}
              size={"sm"}
              href={"https://discord.gg/JgP46aS4dT"}
              rightIcon={<ExternalLinkIcon />}
              mb={4}
            >
              Join Private Discord
            </Button>

            <LinkedHeading as={"h3"} id={"support-discord"} pt={6} pb={4}>
              Slack
            </LinkedHeading>
            <Text>
              If your team primarily uses Slack for communication, we offer the option to connect
              with our team via Slack as well. To set up a channel for your team, please contact
              your account manager.
            </Text>

            <LinkedHeading as={"h3"} id={"support-email"} pt={6} pb={4}>
              Email
            </LinkedHeading>
            <Text>
              If Discord or Slack isn’t your thing, or if you feel your issue can’t be resolved
              through other channels, you’re welcome to reach out to us via email at{" "}
              <Link href={"mailto:support@rivr.stream"}>support@rivr.stream</Link>.
            </Text>
            <Button
              as={"a"}
              mb={4}
              colorScheme={"blue"}
              size={"sm"}
              href={"mailto:support@rivr.stream"}
              rightIcon={<ExternalLinkIcon />}
            >
              Email support
            </Button>
          </>
        ) : (
          <>
            <LinkedHeading as={"h3"} id={"support-discord"} pt={6} pb={4}>
              Discord
            </LinkedHeading>
            <Text>
              Discord is the best way to get support, not only from the Rivr team but also from
              other community members. It offers several support channels for general questions as
              well as a dedicated channel for creating private support tickets.
            </Text>
            <UnorderedList spacing={2} pl={6} pb={4}>
              <ListItem>
                #rivr-status: Check the status of Rivr—services may be down or under maintenance.
              </ListItem>
              <ListItem>
                #bug-reporting: See if another community member has already reported your issue and
                if it’s been resolved.
              </ListItem>
              <ListItem>
                #patch-notes: Find the latest changes to Rivr and check if the issue you’re
                experiencing has been addressed.
              </ListItem>
              <ListItem>
                #technical-support: Open a private support ticket with the Rivr team.
              </ListItem>
              <ListItem>
                #known-issues: View issues we’re already aware of that don’t require reporting.
              </ListItem>
            </UnorderedList>
            <Button
              as={"a"}
              colorScheme={"blue"}
              size={"sm"}
              href={"https://discord.gg/WTkWJKzyQK"}
              rightIcon={<ExternalLinkIcon />}
            >
              Join Discord
            </Button>

            <LinkedHeading as={"h3"} id={"support-email"} pt={12} pb={4}>
              Email
            </LinkedHeading>
            <Text>
              If Discord isn’t your thing, or if you feel your issue can’t be resolved through other
              channels, you’re welcome to reach out to us via email at{" "}
              <Link href={"mailto:support@rivr.stream"}>support@rivr.stream</Link>.
            </Text>
            <Button
              as={"a"}
              mb={4}
              colorScheme={"blue"}
              size={"sm"}
              href={"mailto:support@rivr.stream"}
              rightIcon={<ExternalLinkIcon />}
            >
              Email support
            </Button>
          </>
        )}

        <Box py={6}>
          <Text textAlign={"right"} fontSize={"sm"} pb={0}>
            <em>
              Last updated on Sept. 12, 2024 by{" "}
              <Link href={"https://x.com/marcfromrivr"} isExternal>
                @MarcFromRivr
              </Link>
            </em>
          </Text>
        </Box>
      </Box>
    </KbLayout>
  );
};

export default KbSupport;
