import { useState, useEffect } from "react";
import {
  Box,
  Center,
  Divider,
  Text,
  Stack,
  SimpleGrid,
  HStack,
  Tooltip,
  Badge,
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { ActiveSearchesQuery } from "../../api/search";
import { ActiveSearchItem } from "./ActiveSearchItem/ActiveSearchItem";
import { ActiveSearchSkeleton } from "./ActiveSearchSkeleton";
import UpgradeCta from "../UpgradeCta/UpgradeCta";

type Props = {
  skeletonCount: number;
  onNewSearches: (count: number) => void;
  activeSearchLimit: number;
  onActiveSearchCountChange: (count: number) => void;
};

export const ActiveSearches = (props: Props) => {
  const { skeletonCount, onNewSearches, activeSearchLimit, onActiveSearchCountChange } = props;
  const { user } = useKindeAuth();
  const [searchList, setSearchList] = useState<any[]>([]);
  const [knownActiveIds, setKnownActiveIds] = useState<number[]>([]);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  const handleCloseUpgradeModal = () => setIsUpgradeModalOpen(false);

  const { loading } = useQuery(ActiveSearchesQuery, {
    onCompleted(data) {
      const newSearches: number[] = [];
      for (const item of data.search) {
        if (!knownActiveIds.includes(item.id)) newSearches.push(item.id);
      }
      if (newSearches.length > 0) {
        onNewSearches(newSearches.length);
        setKnownActiveIds([...knownActiveIds, ...newSearches]);
      }
      setSearchList(data.search);
    },
    onError({ graphQLErrors, networkError }) {
      console.log("GetDataReceived ERROR: ", graphQLErrors, networkError);
    },
    variables: { user_id: (user as any).id },
    pollInterval: parseInt(process.env.REACT_APP_ACTIVE_SEARCHES_POLL_TIME || "10000"),
  });

  useEffect(() => {
    onActiveSearchCountChange(searchList.length);
  }, [searchList.length, onActiveSearchCountChange]);

  return (
    <>
      <UpgradeCta isOpen={isUpgradeModalOpen} onClose={handleCloseUpgradeModal} />
      <Center py={12} px={6}>
        <Box w="100%">
          <HStack justifyContent={"space-between"} alignItems={"baseline"}>
            <Box>
              <Text pb="3" fontSize="lg" fontWeight="medium">
                Active analyzing
              </Text>
            </Box>
            <HStack display={activeSearchLimit === 10 ? "none" : "flex"}>
              <Text>Active limit:</Text>
              <Tooltip label="Maximum number of videos that can be analyzed at once">
                <Badge
                  fontSize="md"
                  borderRadius={"full"}
                  py={0.5}
                  px={3}
                  colorScheme={
                    searchList.length >= activeSearchLimit
                      ? "red"
                      : searchList.length === activeSearchLimit - 1
                      ? "yellow"
                      : "gray"
                  }
                >
                  <Text fontWeight={"semibold"} cursor={"default"}>
                    {searchList.length} / {activeSearchLimit}
                  </Text>
                </Badge>
              </Tooltip>
              {/* <Tooltip label="Upgrade plan">
                <IconButton
                  size="xs"
                  borderRadius={"full"}
                  colorScheme="orange"
                  aria-label="Upgrade plan"
                  icon={<Lightning theme="filled" />}
                  onClick={() => setIsUpgradeModalOpen(true)}
                />
              </Tooltip> */}
            </HStack>
          </HStack>
          <Divider orientation="horizontal" border="1px" borderColor="rivr.100" mb={4} />
          {!loading && (
            <>
              <SimpleGrid columns={{ md: 2, lg: 3, xl: 4, "3xl": 5, "4xl": 5 }} spacing={4} my={4}>
                {Array.from(Array(skeletonCount)).map((_, index) => (
                  <ActiveSearchSkeleton key={index}></ActiveSearchSkeleton>
                ))}
                {searchList.map((item) => (
                  <ActiveSearchItem key={item.id} {...item} />
                ))}
              </SimpleGrid>
              {skeletonCount === 0 && searchList.length === 0 && (
                <Center pb="4">
                  <Stack align="center">
                    <Text mt="12" fontSize="lg" fontWeight="medium">
                      No media being analyzed
                    </Text>
                    <Text color="gray.300" mt="2" fontSize="sm" fontWeight="medium">
                      Media that is currently being analyzed will appear here
                    </Text>
                  </Stack>
                </Center>
              )}
            </>
          )}
        </Box>
      </Center>
    </>
  );
};
