import { useState, ChangeEvent, KeyboardEvent, useContext } from "react";
import {
  Box,
  Button,
  Center,
  Image,
  Input,
  Text,
  FormControl,
  HStack,
  useToast,
  Switch,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import TwitchLogo from "../../assets/twitch-logo.svg";
import YouTubeLogo from "../../assets/youtube-logo.svg";
import { InsertSearchMutation } from "../../api/search";
import { checkUrl } from "../../models/urls";
import Cookies from "js-cookie";
import * as amplitude from "@amplitude/analytics-browser";
import { demoUserIds } from "../../utils/demoUserIds";
import { AccountContext } from "../../context/AccountContext";
import BillingCta from "../BillingCta/BillingCta";

type SearchbarProps = {
  onSubmit: () => void;
  activeSearchCount: number;
  activeSearchLimit: number;
};

export const Searchbar = ({ onSubmit, activeSearchCount, activeSearchLimit }: SearchbarProps) => {
  const { account } = useContext(AccountContext);
  const toast = useToast();
  const { isOpen: isBillingOpen, onClose: onBillingClose, onOpen: onBillingOpen } = useDisclosure();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const isSearchDisabled = false;
  const [inputInvalid, setInputInvalid] = useState(false);
  const [inputURL, setInputURL] = useState("");
  const { user, getFlag } = useKindeAuth();
  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const liveTwitchUrlFlag = isAdmin || (getFlag("live-twitch-url", false).value as boolean);
  const youtubeUrlFlag = isAdmin || (getFlag("youtube_url", false).value as boolean);
  const isBetaUser = getFlag("beta-user", false).value as boolean;

  const hasSubscription = account.subscription_status === "active";
  // Allow choosing the EQE version to execute the Rivr run on.
  const [useSpeech, setUseSpeech] = useState(true);
  const [useSpeechSentiment, setUseSpeechSentiment] = useState(true);

  const flags = new Map([
    ["live-twitch-url", liveTwitchUrlFlag],
    ["youtube-url", youtubeUrlFlag],
  ]);
  const [searchInsertAPI] = useMutation(InsertSearchMutation, {
    onCompleted() {
      setInputURL("");
      setIsButtonLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      insertErrorCallback(graphQLErrors, networkError);
    },
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputURL(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSubmitLocal();
    }
  };

  const onSubmitLocal = () => {
    if (!isAdmin && !hasSubscription && !isBetaUser) {
      onBillingOpen();
      amplitude.track("Billing CTA Opened");
      return;
    }

    if (!isAdmin && activeSearchCount >= activeSearchLimit) {
      toast({
        title: "Limit reached",
        description: "You have reached your active analyzing limit. Wait for analysis to complete.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      amplitude.track("Search Limit Reached");
      return;
    }

    setIsButtonLoading(true);
    const parsed = checkUrl(inputURL, flags);
    if (typeof parsed === "string") {
      toast({
        title: "Error.",
        description: parsed,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsButtonLoading(false);
      setInputInvalid(true);
      amplitude.track("Search Start Error");
      return;
    }

    setInputInvalid(false);
    const searchObj = {
      origin_type: parsed.origin,
      url: parsed.cleanUrl,
      user_id: user?.id,
      asr_transcript: useSpeech,
      asr_sentiment: useSpeechSentiment,
    };
    searchInsertAPI({ variables: searchObj });
    onSubmit();
    amplitude.track("Search Start Successful");
  };

  const insertErrorCallback = (graphQLErrors: any, networkError: any) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        console.log("Error:", err.extensions.code);
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
    setIsButtonLoading(false);
  };

  if (!user || !user.id) return null;

  return (
    <>
      <BillingCta isOpen={isBillingOpen} onClose={onBillingClose} />
      <Center w="100%" opacity={!isAdmin && isSearchDisabled ? 0.66 : 1}>
        <Box w="100%" maxW="540px" mx={6}>
          <Box>
            <Text fontSize={"lg"} fontWeight={"medium"} textAlign={"center"} mb={3} mt={1}>
              Start discovering your best Moments. Paste a link to begin.
            </Text>
            <VStack
              p="4"
              bg="whiteAlpha.100"
              w="100%"
              borderWidth={1}
              borderRadius="md"
              overflow="hidden"
            >
              <HStack alignItems={"center"} w={"100%"}>
                <FormControl isInvalid={inputInvalid}>
                  <Input
                    className="inp-search-url amp-unmask"
                    variant="outline"
                    marginRight="2"
                    borderRadius="md"
                    bg={"gray.600"}
                    maxW="480px"
                    h="40px"
                    placeholder="Paste a link here"
                    color="gray.300"
                    size="sm"
                    value={inputURL}
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                    isDisabled={!isAdmin && isSearchDisabled}
                  />
                </FormControl>

                <Button
                  className="btn-start-search"
                  onClick={onSubmitLocal}
                  isLoading={isButtonLoading}
                  isDisabled={(!isAdmin && isSearchDisabled) || inputURL.trim() === ""}
                  colorScheme={"green"}
                  color="gray.900"
                  variant="solid"
                  size="md"
                >
                  Go
                </Button>
              </HStack>
              {isAdmin && (
                <HStack spacing={4} display={demoUserIds.includes(user.id) ? "none" : "flex"}>
                  <HStack>
                    <Text>ASR</Text>
                    <Switch
                      isChecked={useSpeech}
                      onChange={(event) => setUseSpeech(event.target.checked)}
                    />
                  </HStack>
                  <HStack>
                    <Text>ASR Sentiment</Text>
                    <Switch
                      isChecked={useSpeechSentiment}
                      onChange={(event) => setUseSpeechSentiment(event.target.checked)}
                    />
                  </HStack>
                </HStack>
              )}
            </VStack>
          </Box>
          <Box width="100%" display={demoUserIds.includes(user.id) ? "none" : "block"}>
            <Center>
              <HStack alignItems={"center"} mt={4}>
                <Text color="gray.300" fontSize="sm">
                  Supported platforms:
                </Text>
                <HStack
                  backgroundColor={"whiteAlpha.200"}
                  py={2}
                  px={3}
                  borderRadius={"full"}
                  spacing={2}
                >
                  <Image src={TwitchLogo} alt="Twitch Logo" title="Twitch" h="16px" />
                  {liveTwitchUrlFlag ? (
                    <Text fontSize={"xs"} fontWeight={"semibold"} color={"gray.300"}>
                      LIVE & VOD
                    </Text>
                  ) : (
                    <Text fontSize={"xs"} fontWeight={"semibold"} color={"gray.300"}>
                      VOD
                    </Text>
                  )}
                </HStack>
                {youtubeUrlFlag && (
                  <HStack
                    backgroundColor={"whiteAlpha.200"}
                    py={2}
                    px={3}
                    borderRadius={"full"}
                    spacing={2}
                  >
                    <Image src={YouTubeLogo} alt="YouTube Logo" title="YouTube" h="16px" />
                    <Text fontSize={"xs"} fontWeight={"semibold"} color={"gray.200"}>
                      LIVE & VOD
                    </Text>
                  </HStack>
                )}
              </HStack>
            </Center>
          </Box>
        </Box>
      </Center>
    </>
  );
};
