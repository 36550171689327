import { RecommendedMoment } from "../models/moment";

export const dedupeMoments = (moments: RecommendedMoment[]): RecommendedMoment[] => {
  const sortedMoments = [...moments].sort(
    (a, b) => a.time - b.time || Number(a.created_at) - Number(b.created_at)
  );
  const unique = [];
  for (const moment of sortedMoments)
    if (unique.length === 0 || unique[unique.length - 1].time !== moment.time) unique.push(moment);
  return unique;
};
