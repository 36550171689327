import { AspectRatio, Box, HStack, Skeleton, SkeletonText, VStack } from "@chakra-ui/react";

export const ActiveSearchSkeleton = () => (
  <VStack h="100%" w="100%" spacing={0}>
    <Box w="100%" bg="gray.600" borderTopRadius={"md"}>
      <AspectRatio ratio={16 / 9}>
        <Skeleton maxH={"100%"} h={"100%"} w={"100%"} maxW={"100%"} />
      </AspectRatio>
    </Box>
    <VStack
      alignItems="flex-start"
      w="100%"
      bg="gray.700"
      px={4}
      py={5}
      spacing={4}
      borderBottomRadius={"md"}
      marginTop={"-5px !important"}
    >
      <SkeletonText noOfLines={1} skeletonHeight={4} w="90%" />
      <HStack w="100%" spacing={4}>
        <SkeletonText noOfLines={1} skeletonHeight={4} w="35%" />
        <SkeletonText noOfLines={1} skeletonHeight={4} w="35%" />
      </HStack>
    </VStack>
  </VStack>
);
