import { Box, VStack, Heading, Text, Divider, HStack, Switch } from "@chakra-ui/react";
import { useContext } from "react";
import { AccountContext } from "../../../context/AccountContext";

const Emails = () => {
  const { account, toggleEmailNotifications } = useContext(AccountContext);

  return (
    <Box w={"100%"} height={"100%"} alignItems={"flex-start"}>
      <VStack alignItems="flex-start" spacing={12} pt={6}>
        <HStack
          alignItems="center"
          w="100%"
          justifyContent="space-between"
          flexWrap="wrap"
          spacing={0}
        >
          <VStack alignItems="flex-start">
            <Heading as="h1" fontSize={"3xl"}>
              Emails
            </Heading>
            <Text color="gray.300">Manage your preferences for receiving emails from Rivr.</Text>
          </VStack>
        </HStack>

        <Divider />

        <VStack alignItems="flex-start" w="100%" spacing={6}>
          <HStack
            w="100%"
            justifyContent="space-between"
            alignItems="flex-start"
            gap={6}
            wrap="wrap"
          >
            <VStack alignItems="flex-start" spacing={1}>
              <Text fontSize="lg">Completion notification</Text>
              <Text color="gray.300" fontSize="sm" mb={4}>
                Receive an email when Rivr is done analyzing your video.
              </Text>
            </VStack>
            <Switch
              title={"Get Emails from Rivr"}
              className={"amp-unmask"}
              ml="0 !important"
              id={"account-email-alerts"}
              size={"lg"}
              defaultChecked={account.email_notifications}
              onChange={(e) => toggleEmailNotifications(e.target.checked)}
            />
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
};
export default Emails;
