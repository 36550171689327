import React, { useContext, useMemo, useRef, useEffect, useState } from "react";
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel, Flex, IconButton } from "@chakra-ui/react";
import AccountMain from "../components/Account/AccountMain";
import Organization from "../components/Account/Organization/Organization";
import { AccountURLParams, updateURLParams } from "../models/navigation";
import { useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import { AccountContext } from "../context/AccountContext";
import { OrganizationMembership } from "../models/rivrOrganization";
import { OrgBilling } from "../components/Account/Billing/OrgBilling";
import CampaignKeys from "../components/Account/CampaignKeys/CampaignKeys";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Billing } from "../components/Account/Billing/Billing";
import Emails from "../components/Account/Emails/Emails";
import { OrgTeam } from "../components/Account/Team/OrgTeam";

const Account = () => {
  const { account, memberships } = useContext(AccountContext);
  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const isOrgMember = memberships.length > 0;

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedOrg = searchParams.get(AccountURLParams.SelectedOrganization) || "";

  const selectedMembership = useMemo(() => {
    return selectedOrg !== ""
      ? memberships.find((m: OrganizationMembership) => m.organization.id === selectedOrg)
      : memberships[0];
  }, [selectedOrg, memberships]);

  const tabData = [
    {
      name: "Account",
      label: "main",
      content: <AccountMain />,
      hidden: false,
    },
    {
      name: "Organization",
      label: "org",
      content: selectedMembership && <Organization />,
      hidden: !isOrgMember && !isAdmin,
    },
    {
      name: "Team",
      label: "team",
      content: selectedMembership && <OrgTeam orgId={selectedMembership.organization.id} />,
      hidden: !selectedMembership || (selectedMembership.role !== "owner" && !isAdmin),
    },
    {
      name: "Campaigns",
      label: "campaigns",
      content: <CampaignKeys />,
      hidden: isOrgMember && !isAdmin,
    },
    {
      name: isAdmin ? "Billing and Plans b2c" : "Billing and Plans",
      label: "billing",
      content: <Billing />,
      hidden: isOrgMember && !isAdmin,
    },
    {
      name: isAdmin ? "Billing and Plans b2b" : "Billing and Plans",
      label: "orgbilling",
      content: selectedMembership && (
        <OrgBilling
          key={selectedMembership.organization.id}
          organization={selectedMembership.organization}
        />
      ),
      hidden: !selectedMembership || (selectedMembership.role !== "owner" && !isAdmin),
    },
    {
      name: "Emails",
      label: "emails",
      content: <Emails />,
      hidden: false,
    },
    {
      name: "Integrations",
      label: "integration",
      hidden: !isAdmin,
    },
    {
      name: "Affiliates",
      label: "affiliate",
      hidden: !isAdmin,
    },
    {
      name: "Partnerships",
      label: "partnership",
      hidden: !isAdmin,
    },
  ];
  const filteredTabs = useMemo(() => tabData.filter((tab) => !tab.hidden), [tabData]);

  const selectedTab = filteredTabs.findIndex((tab) => {
    return tab.label === (searchParams.get(AccountURLParams.SelectedTab) || "main");
  });

  const handleTabSelect = (index: number) => {
    const newParams: Array<[string, string]> = [
      [AccountURLParams.SelectedTab, filteredTabs[index].label],
    ];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: false });
  };

  const tabListRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const checkForOverflow = () => {
    if (tabListRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = tabListRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    checkForOverflow();
    window.addEventListener("resize", checkForOverflow);
    return () => window.removeEventListener("resize", checkForOverflow);
  }, []);

  const scrollToTab = (direction: "left" | "right") => {
    if (tabListRef.current) {
      const tabList = tabListRef.current;
      const tabs = Array.from(tabList.children) as HTMLElement[];
      const { scrollLeft, clientWidth } = tabList;

      let targetTab: HTMLElement | undefined;
      if (direction === "right") {
        targetTab = tabs.find((tab) => tab.offsetLeft + tab.offsetWidth > scrollLeft + clientWidth);
      } else {
        targetTab = tabs.reverse().find((tab) => tab.offsetLeft < scrollLeft);
      }

      if (targetTab) {
        tabList.scrollTo({
          left:
            targetTab.offsetLeft - (direction === "left" ? clientWidth - targetTab.offsetWidth : 0),
          behavior: "smooth",
        });
      }

      setTimeout(checkForOverflow, 100);
    }
  };

  return (
    account && (
      <Box w={"100%"} height={"100%"} position={"relative"} mx={"auto"}>
        <Tabs index={selectedTab !== -1 ? selectedTab : 0} onChange={handleTabSelect} p={6}>
          <Flex align="center">
            {showLeftArrow && (
              <IconButton
                aria-label="More tabs left"
                icon={<ChevronLeftIcon />}
                onClick={() => scrollToTab("left")}
                size="sm"
                mr={2}
              />
            )}
            <TabList
              ref={tabListRef}
              w={"100%"}
              overflowX="auto"
              overflowY="hidden"
              whiteSpace="nowrap"
              css={{
                scrollbarWidth: "none",
                "::-webkit-scrollbar": {
                  display: "none",
                },
              }}
              onScroll={checkForOverflow}
            >
              {filteredTabs.map((tab: any) => (
                <React.Fragment key={tab.label}>
                  <Tab
                    fontWeight={"medium"}
                    isDisabled={!tab.content}
                    flex={"0 0 auto"}
                    _hover={{ bg: "whiteAlpha.200" }}
                    _selected={{
                      bg: "whiteAlpha.200",
                      color: "blue.300",
                      borderBottomColor: "blue.300",
                    }}
                    borderTopRadius={"md"}
                  >
                    {tab.name}
                  </Tab>
                </React.Fragment>
              ))}
            </TabList>
            {showRightArrow && (
              <IconButton
                aria-label="More tabs right"
                icon={<ChevronRightIcon />}
                onClick={() => scrollToTab("right")}
                size="sm"
                ml={2}
              />
            )}
          </Flex>
          <TabPanels py={2} maxW={"1400px"} mx={"auto"}>
            {filteredTabs.map((tab: any) => (
              <React.Fragment key={tab.label}>
                <TabPanel px={0}>{tab.content}</TabPanel>
              </React.Fragment>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    )
  );
};

export default Account;
