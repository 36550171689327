import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Tr,
  Td,
  Text,
  Badge,
  Tooltip,
  HStack,
  IconButton,
  Code,
  CircularProgress,
  Box,
} from "@chakra-ui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { RivrMention } from "../../../../models/mention";
import { formatDateForDisplay, secondsToHMSDuration } from "../../../../utils/time";
import { ResultsURLParams, RivrLocations, updateURLParams } from "../../../../models/navigation";

interface Props {
  mention: RivrMention;
  miniPlayer: boolean;
  isSelected: boolean;
  setSelectedMention: React.Dispatch<React.SetStateAction<RivrMention | null>>;
  maxRank: number;
}

export const DiscoverMentionItem = ({
  mention,
  miniPlayer,
  isSelected,
  setSelectedMention,
  maxRank,
}: Props) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const viewLink = `${RivrLocations.Results}/${mention.searchId}?${updateURLParams(
    searchParams.toString(),
    [
      [ResultsURLParams.SelectedTimestamp, String(Math.floor(mention.timestamp))],
      [ResultsURLParams.SelectedPanel, mention.type === "Speech" ? "speech" : "chat"],
      [ResultsURLParams.SelectedMoment, ""],
    ]
  )}`;

  const handleNavigate = () => {
    if (miniPlayer) {
      setSelectedMention(mention);
    } else {
      navigate(viewLink);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
    e.preventDefault();
    handleNavigate();
  };

  return (
    <Tr
      _hover={{ bg: "whiteAlpha.100", cursor: "pointer" }}
      sx={{
        "&:hover .actions": {
          opacity: 1,
        },
      }}
      display={"table-row"}
      onClick={handleNavigate}
      bg={isSelected ? "whiteAlpha.100" : undefined}
    >
      <Td verticalAlign={"middle"}>
        <Box
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <CircularProgress
            className={"circular-progress"}
            min={0}
            max={maxRank}
            value={mention.rank}
            color={"green.200"}
            trackColor={"blackAlpha.500"}
            thickness={16}
            size={6}
          />
        </Box>
      </Td>
      <Td px={2} verticalAlign={"middle"}>
        <Box
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Text>{mention.channel}</Text>
        </Box>
      </Td>
      <Td px={2} verticalAlign={"middle"}>
        <Box
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Text>{formatDateForDisplay(new Date(mention.date))}</Text>
        </Box>
      </Td>
      <Td px={2} verticalAlign={"middle"}>
        <Box
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Text>{secondsToHMSDuration(mention.timestamp)}</Text>
        </Box>
      </Td>
      <Td px={2} verticalAlign={"middle"}>
        <Box
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Badge colorScheme={mention.type === "Speech" ? "purple" : "orange"} letterSpacing={1}>
            {mention.type}
          </Badge>
        </Box>
      </Td>
      <Td px={2} verticalAlign={"middle"}>
        <Box
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Code
            px={2}
            py={1}
            borderRadius={"md"}
            sx={{
              textWrap: "wrap",
              lineHeight: "1.2rem",
              w: "100%",
              bg: "blackAlpha.500",
              pos: "relative",
              "& b": {
                fontWeight: "bold",
                textColor: "rivr.400",
                backgroundColor: "green.200",
                padding: "0 2px",
                borderRadius: "md",
              },
            }}
            className={"mention-line"}
          >
            <Text as="span" dangerouslySetInnerHTML={{ __html: mention.text }} />
          </Code>
        </Box>
      </Td>
      <Td maxW={64} px={2} verticalAlign={"middle"}>
        <Box
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Text overflow={"hidden"} textOverflow={"ellipsis"} title={mention.vod}>
            {mention.vod}
          </Text>
        </Box>
      </Td>
      <Td maxW={"fit-content"} isNumeric px={2} verticalAlign={"middle"}>
        <HStack justify={"flex-end"} spacing={1} className="actions" opacity={0}>
          <Tooltip label={"View"}>
            <Box
              as={Link}
              to={viewLink}
              onClick={(e: any) => {
                e.stopPropagation();
                handleClick(e);
              }}
            >
              <IconButton
                size="xs"
                variant={"ghost"}
                aria-label="View Mention"
                icon={<ExternalLinkIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!miniPlayer) navigate(viewLink);
                }}
              />
            </Box>
          </Tooltip>
        </HStack>
      </Td>
    </Tr>
  );
};
