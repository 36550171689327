import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  HStack,
  IconButton,
  Image,
  Text,
  Tooltip,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import RivrLogo from "../../assets/rivr-logo-mark.svg";
import CreditsMenu from "../Credits/CreditsMenu";
import OrgSelect from "./OrgSelect";
import CampaignsNavigation from "./CampaignsNavigation";
import CampaignSelect from "./CampaignSelect";
import NavMenu from "./NavMenu";
import { CampaignURLParams, RivrLocations, updateURLParams } from "../../models/navigation";
import { useContext, useEffect } from "react";
import { AccountContext } from "../../context/AccountContext";
import { Search } from "@icon-park/react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import DemoAlert from "../AlertMessage/DemoAlert";
import * as amplitude from "@amplitude/analytics-browser";
// import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getFlag } = useKindeAuth();
  const demoUser = getFlag("demo-user").value as boolean;

  const { account } = useContext(AccountContext);

  const [searchParams] = useSearchParams();

  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const isOrgMember = account.memberships.length > 0;
  const homeLink = isOrgMember
    ? `${RivrLocations.Campaigns}?${updateURLParams(searchParams.toString(), [
        [CampaignURLParams.SubPage, ""],
      ])}`
    : `${RivrLocations.Search}?${searchParams.toString()}`;

  const isMobile = useBreakpointValue({ base: true, sm: true, md: true, lg: false });

  const isLocation = (locations: string[]) =>
    locations.some((l) => location.pathname.startsWith(l));

  const renderNavButton = (page: string, label: string, icon: JSX.Element) => {
    if (isMobile) {
      return (
        <Tooltip placement={"bottom"} label={label}>
          <Link to={`${page}?${searchParams.toString()}`}>
            <IconButton
              aria-label={label}
              variant={"ghost"}
              size={"sm"}
              icon={icon}
              onClick={() => navigate(`${page}?${searchParams.toString()}`)}
              isActive={isLocation([page])}
              colorScheme={isLocation([page]) ? "blue" : "gray"}
              fontWeight={"medium"}
              cursor={"pointer"}
            />
          </Link>
        </Tooltip>
      );
    }

    return (
      <Link to={`${page}?${searchParams.toString()}`}>
        <Button
          variant={"ghost"}
          size={"sm"}
          fontSize={"sm"}
          onClick={() => navigate(`${page}?${searchParams.toString()}`)}
          isActive={isLocation([page])}
          colorScheme={isLocation([page]) ? "blue" : "gray"}
          leftIcon={icon}
          fontWeight={"medium"}
          cursor={"pointer"}
        >
          <Text>{label}</Text>
        </Button>
      </Link>
    );
  };

  useEffect(() => {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_ID || "", account?.id || undefined, {
      defaultTracking: true,
    });
  }, []);

  // const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 1 });
  // amplitude.add(sessionReplayTracking);

  return (
    <VStack w="100%" spacing={0} pos={"sticky"} top={0} zIndex={"tooltip"}>
      {demoUser && <DemoAlert />}
      <HStack w={"100%"} h={16} bg={"gray.900"} borderBottomWidth={1} className={"campaign-nav"}>
        <HStack p={4} w={"100%"} justifyContent={"space-between"}>
          <HStack spacing={4}>
            <Link to={homeLink}>
              <Image src={RivrLogo} boxSize={8} cursor={"pointer"} minW={8} minH={8} />
            </Link>
            {(isAdmin || isOrgMember) && <OrgSelect />}
            <HStack>
              {(isAdmin || !isOrgMember) &&
                renderNavButton(RivrLocations.Search, "Search", <Search />)}

              {isAdmin || isOrgMember ? <CampaignsNavigation /> : null}
            </HStack>
          </HStack>
          <HStack>
            {isLocation([RivrLocations.Campaigns]) && <CampaignSelect />}

            {isLocation([RivrLocations.Account, RivrLocations.Campaigns]) &&
              (isAdmin || isOrgMember) && <CreditsMenu />}
            <NavMenu />
          </HStack>
        </HStack>
      </HStack>
    </VStack>
  );
};

export default NavigationBar;
