import {
  Text,
  IconButton,
  Badge,
  Box,
  Image,
  VStack,
  Tooltip,
  HStack,
  Progress,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import {
  Videocamera,
  Heartbeat,
  MoreOne,
  Square,
  Check,
  InboxIn,
  InboxOut,
  Eyes,
  BackOne,
} from "@icon-park/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link, useSearchParams } from "react-router-dom";
import { originTwitch, originYouTube } from "../../../models/urls";
import TwitchLogo from "../../../assets/twitch-logo.svg";
import YouTubeLogo from "../../../assets/youtube-logo.svg";
import ImgNotFound from "../../../assets/image-not-found.png";
import { memo, useState } from "react";
import { useMutation } from "@apollo/client";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  SetSearchArchiveStatusMutation,
  SetSearchWorkflowMutation,
  StopSearchMutation,
} from "../../../api/search";
import { RivrSearch } from "../../../models/rivrSearch";
import {
  CampaignURLParams,
  ResultsURLParams,
  RivrLocations,
  updateURLParams,
} from "../../../models/navigation";
import { formatDateForDisplay } from "../../../utils/time";
import { ActiveSearchSkeleton } from "../../ActiveSearches/ActiveSearchSkeleton";

interface Props {
  search: RivrSearch;
  fetchSearches: any;
}

const CampaignSearchItem = memo(({ search, fetchSearches }: Props) => {
  const { getFlag } = useKindeAuth();
  const demoUser = getFlag("demo-user").value;

  const [isStatusLoading, setIsStatusLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const selectedCampaign = searchParams.get(CampaignURLParams.SelectedCampaign) || "";

  const viewLink = `${RivrLocations.Results}/${search.id}?${updateURLParams(
    searchParams.toString(),
    [
      [ResultsURLParams.SelectedMoment, ""],
      [ResultsURLParams.SelectedTimestamp, ""],
      [ResultsURLParams.SelectedPanel, ""],
    ]
  )}`;

  const [stopSearchAPI] = useMutation(StopSearchMutation, {
    onCompleted() {
      fetchSearches({ campaign: selectedCampaign });
      setIsStatusLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const [setWorkflowStatusAPI] = useMutation(SetSearchWorkflowMutation, {
    onCompleted() {
      fetchSearches({ campaign: selectedCampaign });
      setIsStatusLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const [archiveSearchAPI] = useMutation(SetSearchArchiveStatusMutation, {
    onCompleted() {
      fetchSearches({ campaign: selectedCampaign });
      setIsStatusLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const stopSearch = () => {
    setIsStatusLoading(true);
    stopSearchAPI({ variables: { id: search.id } });
  };

  const setSearchWorkflowStatus = (status: string | undefined) => {
    setIsStatusLoading(true);
    setWorkflowStatusAPI({
      variables: { id: search.id, status: status !== undefined ? status : null },
    });
  };

  const archiveSearch = (date: Date | undefined) => {
    setIsStatusLoading(true);
    archiveSearchAPI({ variables: { id: search.id, date: date !== undefined ? date : null } });
  };

  const isArchived = search.archivedAt !== undefined;

  const getBadgeForStatus = () => {
    if (isArchived) {
      return (
        <Badge
          display="flex"
          borderRadius="sm"
          colorScheme="gray"
          py={0.5}
          variant="solid"
          pointerEvents={"none"}
          letterSpacing={0.5}
        >
          Archived
        </Badge>
      );
    }
    if (search.status === "in-progress") {
      return (
        <Badge
          display="flex"
          borderRadius="sm"
          colorScheme="yellow"
          py={0.5}
          variant="solid"
          pointerEvents={"none"}
          letterSpacing={0.5}
        >
          Analyzing... {search.searchProgress ? `${search.searchProgress}%` : ""}
          <Spinner size="xs" ml={1} mt={0.5} />
        </Badge>
      );
    } else if (search.status === "error") {
      return (
        <Badge
          display="flex"
          borderRadius="sm"
          colorScheme="red"
          py={0.5}
          variant="solid"
          pointerEvents={"none"}
          letterSpacing={0.5}
        >
          Error
        </Badge>
      );
    } else {
      switch (search.workflowStatus) {
        case "completed":
          return (
            <Badge
              display="flex"
              borderRadius="sm"
              colorScheme="blue"
              py={0.5}
              variant="solid"
              pointerEvents={"none"}
              letterSpacing={0.5}
            >
              Completed
            </Badge>
          );
        case "reviewing":
          return (
            <Badge
              display="flex"
              borderRadius="sm"
              colorScheme="purple"
              py={0.5}
              variant="solid"
              pointerEvents={"none"}
              letterSpacing={0.5}
            >
              Reviewing
            </Badge>
          );
        case undefined:
          return (
            <Badge
              display="flex"
              borderRadius="sm"
              colorScheme="green"
              py={0.5}
              variant="solid"
              pointerEvents={"none"}
              letterSpacing={0.5}
            >
              Analyzed
            </Badge>
          );
      }
    }
  };

  if (search.metadata === undefined && search.status === "in-progress") {
    return <ActiveSearchSkeleton />;
  }

  return (
    <VStack
      spacing="0"
      alignItems={"flex-start"}
      className="search-item-wrapper"
      borderWidth={1}
      borderRadius={"md"}
      h={"fit-content"}
      _hover={{
        ".search-item-details": { bg: "gray.600", transition: "background-color 200ms" },
      }}
    >
      <Box position="relative" w="100%" h="auto" opacity={isArchived ? 0.4 : 1}>
        {search.metadata && (
          <Badge
            colorScheme={"purple"}
            variant={"solid"}
            position={"absolute"}
            top={3}
            right={3}
            py={0.5}
            textTransform={"none"}
            pointerEvents={"none"}
          >
            {search.metadata.userName}
          </Badge>
        )}
        <Link to={viewLink}>
          <Image
            src={search.metadata ? search.metadata.thumbnailUrl : undefined}
            borderTopLeftRadius={4}
            borderTopRightRadius={4}
            w="1920px"
            h="auto"
            fallbackSrc={ImgNotFound}
          />
        </Link>
      </Box>
      <VStack
        w="100%"
        spacing={0}
        mt={"-54px !important"}
        position={"relative"}
        pointerEvents={"none"}
      >
        <HStack
          justifyContent={"flex-start"}
          w={"100%"}
          bg={`linear-gradient(to top, #171923 25%, transparent 100%)`}
          p={3}
          className="search-item-status"
          pointerEvents={"none"}
        >
          <HStack alignItems={"flex-end"} pointerEvents={"none"}>
            {getBadgeForStatus()}

            {search.videoType === "VOD" && (
              <Badge
                colorScheme={"blackAlpha"}
                bg={"blackAlpha.800"}
                variant={"solid"}
                position={"absolute"}
                right={3}
                py={0.5}
                pointerEvents={"none"}
                opacity={isArchived ? 0.4 : 1}
              >
                {search.videoDuration}
              </Badge>
            )}

            {(!search.status || search.status !== "in-progress") && (
              <Box h="30px" bg="gray.900" pointerEvents={"none"} />
            )}
          </HStack>
        </HStack>
        {search.videoType === "VOD" && search.status === "in-progress" && (
          <VStack w="100%" spacing={-1}>
            <Progress
              size="sm"
              colorScheme="yellow"
              isIndeterminate={search.searchProgress ? false : true}
              value={search.searchProgress ?? 0}
              w="100%"
              className="search-item-progress"
              bg="gray.500"
            />
            <Progress
              size="sm"
              colorScheme="yellow"
              isIndeterminate
              w="100%"
              mt={"-8px !important"}
            />
          </VStack>
        )}
        {search.videoType === "LIVE" && search.status === "in-progress" && (
          <Progress value={60} size="sm" colorScheme="red" isIndeterminate w="100%" />
        )}
      </VStack>
      <HStack
        justifyContent="space-between"
        w="100%"
        bg={isArchived ? "whiteAlpha.50" : "gray.700"}
        p="4"
        className="search-item-details"
        borderBottomLeftRadius={4}
        borderBottomRightRadius={4}
        h="100%"
        alignItems={"flex-start"}
        transition={"background-color 200ms"}
      >
        <VStack
          alignItems={"flex-start"}
          alignContent={"flex-start"}
          justifyContent={"center"}
          spacing={1.5}
          w="100%"
        >
          <HStack
            alignItems="center"
            justifyContent={"flex-start"}
            className="search-item-details-icon-title"
            w="100%"
            spacing={1}
            opacity={isArchived ? 0.4 : 1}
          >
            {search.originType === originTwitch ? (
              <Image src={TwitchLogo} alt="Twitch Logo" title="Twitch" h="18px" mr={1} />
            ) : search.originType === originYouTube ? (
              <Image src={YouTubeLogo} alt="YouTube Logo" title="YouTube" h="14px" mr={1} />
            ) : (
              <></>
            )}
            <Link to={viewLink}>
              <Text
                className={`lnk-to-result-${search.id}`}
                fontSize={"sm"}
                fontWeight={"medium"}
                noOfLines={1}
                wordBreak={"break-all"}
                title={search.videoTitle || "N/A"}
              >
                {search.videoTitle || "N/A"}
              </Text>
            </Link>
          </HStack>
          <HStack
            w={"100%"}
            spacing={0}
            cursor={"default"}
            flexWrap={"wrap"}
            justifyContent={"space-between"}
          >
            <HStack spacing={0} opacity={isArchived ? 0.4 : 1}>
              <Tooltip label="Date published">
                <HStack alignItems="center" spacing={1.5} pr={3}>
                  <Videocamera size={"18px"} />
                  <Text fontSize="sm" color={"gray.300"} sx={{ textWrap: "nowrap" }}>
                    {search.metadata
                      ? formatDateForDisplay(search.metadata.publishedAt)
                      : "Not available"}
                  </Text>
                </HStack>
              </Tooltip>
              {search.userMoments.length > 0 && (
                <Tooltip
                  label={`${search.userMoments.length} user Moment${
                    search.userMoments.length === 1 ? "" : "s"
                  }`}
                  hasArrow
                >
                  <HStack alignItems="center" spacing={1.5} pr={3}>
                    <Heartbeat size={"18px"} theme="outline" />
                    <Text fontSize="sm" color={"gray.300"} sx={{ textWrap: "nowrap" }}>
                      {search.userMoments.length}
                    </Text>
                  </HStack>
                </Tooltip>
              )}
            </HStack>
            <Menu strategy="fixed" placement={"right"}>
              <MenuButton
                as={IconButton}
                aria-label={"More options"}
                icon={<MoreOne size={"20px"} />}
                size="xs"
                variant="ghost"
                disabled={isStatusLoading}
              />
              <MenuList>
                {["in-progress", "error"].includes(search.status) === false && (
                  <>
                    {!isArchived ? (
                      <>
                        {search.workflowStatus !== undefined && (
                          <MenuItem
                            icon={<BackOne />}
                            onClick={(e) => setSearchWorkflowStatus(undefined)}
                          >
                            Reset to Analyzed
                          </MenuItem>
                        )}
                        {search.workflowStatus !== "reviewing" && (
                          <MenuItem
                            icon={<Eyes />}
                            onClick={(e) => setSearchWorkflowStatus("reviewing")}
                          >
                            Mark Reviewing
                          </MenuItem>
                        )}
                        {search.workflowStatus !== "completed" && (
                          <MenuItem
                            icon={<Check />}
                            onClick={(e) => setSearchWorkflowStatus("completed")}
                          >
                            Mark Completed
                          </MenuItem>
                        )}
                        {!demoUser && (
                          <MenuItem
                            icon={<InboxIn />}
                            onClick={(e) => {
                              archiveSearch(new Date());
                            }}
                          >
                            Archive
                          </MenuItem>
                        )}
                      </>
                    ) : (
                      <MenuItem
                        icon={<InboxOut />}
                        onClick={(e) => {
                          archiveSearch(undefined);
                        }}
                      >
                        Unarchive
                      </MenuItem>
                    )}

                    <MenuDivider />
                  </>
                )}
                <MenuItem as={"a"} href={search.url} target={"_blank"} icon={<ExternalLinkIcon />}>
                  View on {search.originType === originTwitch ? "Twitch" : "YouTube"}
                </MenuItem>
                {search.status === "in-progress" && (
                  <MenuItem icon={<Square theme="filled" />} onClick={stopSearch}>
                    Stop analyzing
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </HStack>
        </VStack>
      </HStack>
    </VStack>
  );
});

export default CampaignSearchItem;
