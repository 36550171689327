import { Button, ButtonGroup } from "@chakra-ui/react";
import "../Campaigns.css";
import { useSearchParams } from "react-router-dom";
import { CampaignURLParams, updateURLParams } from "../../../models/navigation";
import { Endocrine, Message } from "@icon-park/react";

const DiscoverViewSelect = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedDiscoverView = searchParams.get(CampaignURLParams.DiscoverSelectedView) || "moment";

  const handleDiscoverViewSelect = (view: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.DiscoverSelectedView, view]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: false });
  };

  return (
    <ButtonGroup isAttached size={"sm"} fontSize={"sm"}>
      <Button
        leftIcon={<Endocrine />}
        onClick={() => handleDiscoverViewSelect("moment")}
        isActive={selectedDiscoverView === "moment"}
        colorScheme={selectedDiscoverView === "moment" ? "yellow" : "gray"}
        variant={selectedDiscoverView === "moment" ? "ghost" : "solid"}
        fontWeight={"normal"}
      >
        Moments
      </Button>
      <Button
        leftIcon={<Message />}
        onClick={() => handleDiscoverViewSelect("mention")}
        isActive={selectedDiscoverView === "mention"}
        colorScheme={selectedDiscoverView === "mention" ? "purple" : "gray"}
        variant={selectedDiscoverView === "mention" ? "ghost" : "solid"}
        fontWeight={"normal"}
      >
        Mentions
      </Button>
    </ButtonGroup>
  );
};

export default DiscoverViewSelect;
