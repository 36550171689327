import { useEffect, useState, useCallback } from "react";
import {
  IconButton,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  VStack,
  Text,
  Box,
  HStack,
} from "@chakra-ui/react";
import { ColorFilter } from "@icon-park/react";
import { signals } from "../../models/signals"; // Import the signals

const TimelineColorSchemer = () => {
  const [colorMode, setColorMode] = useState<string | null>(null);

  useEffect(() => {
    const storedColorMode = localStorage.getItem("colorMode");
    if (storedColorMode !== colorMode) {
      setColorMode(storedColorMode);
    }
  }, [colorMode]);

  const changeColorMode = useCallback((newColorMode: string | null) => {
    if (newColorMode) {
      localStorage.setItem("colorMode", newColorMode);
    } else {
      localStorage.removeItem("colorMode");
    }
    setColorMode(newColorMode);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }, []);

  const getColorBoxes = (mode: string | null) => {
    return signals
      .filter((signal) => signal[0] !== "Chat sentiment" && signal[0] !== "Hype")
      .reverse()
      .map((signal, index) => {
        const color =
          mode === "alt1"
            ? signal[1].colorAlt1
            : mode === "alt2"
            ? signal[1].colorAlt2
            : signal[1].color;
        return <Box key={index} bg={color} h={2} w={10} />;
      });
  };

  return (
    <Box>
      <Menu placement="right-start">
        <Tooltip label="Signal colors" placement="right">
          <MenuButton
            as={IconButton}
            icon={<ColorFilter />}
            aria-label="Alternative Timeline Colors"
            size="sm"
          />
        </Tooltip>
        <MenuList>
          <MenuItem onClick={() => changeColorMode(null)}>
            <VStack align={"flex-start"} justify={"flex-start"} spacing={0} pb={1}>
              <Text>Default</Text>
              <HStack w={"100%"} h={2} spacing={0}>
                {getColorBoxes(null)}
              </HStack>
            </VStack>
          </MenuItem>
          <MenuItem onClick={() => changeColorMode("alt1")}>
            <VStack align={"flex-start"} justify={"flex-start"} spacing={0} pb={1}>
              <Text>Contrast</Text>
              <HStack w={"100%"} h={2} spacing={0}>
                {getColorBoxes("alt1")}
              </HStack>
            </VStack>
          </MenuItem>
          <MenuItem onClick={() => changeColorMode("alt2")}>
            <VStack align={"flex-start"} justify={"flex-start"} spacing={0} pb={1}>
              <Text>Alternative</Text>
              <HStack w={"100%"} h={2} spacing={0}>
                {getColorBoxes("alt2")}
              </HStack>
            </VStack>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default TimelineColorSchemer;
