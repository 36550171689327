import { useState } from "react";
import {
  Text,
  IconButton,
  Badge,
  Box,
  Image,
  VStack,
  Tooltip,
  HStack,
  Progress,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import { MoreOne, Square, Videocamera } from "@icon-park/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link, useSearchParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { originTwitch, originYouTube } from "../../../models/urls";
import { StopSearchMutation } from "../../../api/search";
import TwitchLogo from "../../../assets/twitch-logo.svg";
import YouTubeLogo from "../../../assets/youtube-logo.svg";
import ImgNotFound from "../../../assets/image-not-found.png";
import * as amplitude from "@amplitude/analytics-browser";
import { formatDateForDisplay } from "../../../utils/time";
import { ResultsURLParams, RivrLocations, updateURLParams } from "../../../models/navigation";

export const ActiveSearchItem = ({
  id,
  url,
  video_title,
  video_type,
  search_progress,
  origin_type,
  metadata,
  video_duration,
}) => {
  const [searchParams] = useSearchParams();

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const info = !metadata
    ? {
        // No metadata
        thumbnailUrl: { ImgNotFound },
        published: "Not available",
      }
    : origin_type === originTwitch
    ? {
        // Twitch metadata
        thumbnailUrl: metadata.thumbnail_url.replace("%{width}", "480").replace("%{height}", "270"),
        published: formatDateForDisplay(new Date(metadata.published_at)),
      }
    : {
        // YouTube metadata
        thumbnailUrl: metadata.thumbnail,
        published: formatDate(metadata.upload_date),
      };

  const viewLink = `${RivrLocations.Results}/${id}?${updateURLParams(searchParams.toString(), [
    [ResultsURLParams.SelectedMoment, ""],
    [ResultsURLParams.SelectedTimestamp, ""],
    [ResultsURLParams.SelectedPanel, ""],
  ])}`;

  function formatDate(dateStr) {
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);
    const date = new Date(`${year}-${month}-${day}`);
    return formatDateForDisplay(date);
  }

  const stopSearch = (id) => {
    setIsButtonLoading(true);
    stopSearchAPI({ variables: { id: id } });
  };

  const [stopSearchAPI] = useMutation(StopSearchMutation, {
    onCompleted() {
      setIsButtonLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
  });

  const handleAmplitudeClick = () => {
    amplitude.track("Active Card Clicked");
  };

  return (
    <VStack
      spacing="0"
      alignItems={"flex-start"}
      className="search-item-wrapper"
      borderWidth={1}
      borderRadius={"md"}
      h={"fit-content"}
      _hover={{
        ".search-item-details": { bg: "gray.600", transition: "background-color 200ms" },
      }}
    >
      <Box position="relative" w="100%" h="auto">
        <Link to={viewLink} onClick={handleAmplitudeClick}>
          <Image
            src={info.thumbnailUrl}
            borderTopLeftRadius={4}
            borderTopRightRadius={4}
            w="1920px"
            h="auto"
            fallbackSrc={ImgNotFound}
          />
        </Link>
      </Box>
      <VStack
        w="100%"
        spacing={0}
        mt={"-54px !important"}
        position={"relative"}
        pointerEvents={"none"}
      >
        <HStack
          justifyContent={"space-between"}
          w={"100%"}
          bg={`linear-gradient(to top, #171923 25%, transparent 100%)`}
          p={3}
          className="search-item-status"
        >
          <HStack alignItems={"flex-end"} pointerEvents={"none"}>
            <Badge
              display="flex"
              borderRadius="sm"
              colorScheme="yellow"
              py={0.5}
              variant="solid"
              pointerEvents={"none"}
              letterSpacing={0.5}
            >
              Analyzing... {search_progress ? `${search_progress}%` : ""}
              <Spinner size="xs" ml={1} mt={0.5} />
            </Badge>

            {video_type === "VOD" && (
              <Badge
                colorScheme={"blackAlpha"}
                bg={"blackAlpha.800"}
                variant={"solid"}
                position={"absolute"}
                right={3}
                py={0.5}
              >
                {video_duration}
              </Badge>
            )}
          </HStack>
        </HStack>
        {video_type === "VOD" ? (
          <VStack w="100%" spacing={-1}>
            <Progress
              size="sm"
              colorScheme="yellow"
              isIndeterminate={search_progress ? false : true}
              value={search_progress ?? 0}
              w="100%"
              className="search-item-progress"
              bg="gray.500"
            />
            <Progress
              size="sm"
              colorScheme="yellow"
              isIndeterminate
              w="100%"
              mt={"-8px !important"}
            />
          </VStack>
        ) : (
          <Progress value={60} size="sm" colorScheme="red" isIndeterminate w="100%" />
        )}
      </VStack>
      <HStack
        justifyContent="space-between"
        w="100%"
        bg="gray.700"
        p="4"
        className="search-item-details"
        borderBottomLeftRadius={4}
        borderBottomRightRadius={4}
        h="100%"
        alignItems={"flex-start"}
        transition={"background-color 200ms"}
      >
        <VStack
          alignItems={"flex-start"}
          alignContent={"flex-start"}
          justifyContent={"center"}
          spacing={1.5}
          w="100%"
        >
          <HStack
            alignItems="center"
            justifyContent={"flex-start"}
            className="search-item-details-icon-title"
            w="100%"
            spacing={1}
          >
            {origin_type === originTwitch ? (
              <Image src={TwitchLogo} alt="Twitch Logo" title="Twitch" h="18px" mr={1} />
            ) : origin_type === originYouTube ? (
              <Image src={YouTubeLogo} alt="YouTube Logo" title="YouTube" h="18px" mr={1} />
            ) : (
              <></>
            )}
            <Link to={viewLink} onClick={handleAmplitudeClick}>
              <Text
                className={`lnk-to-result-${id}`}
                fontSize="sm"
                fontWeight="medium"
                noOfLines={1}
                title={video_title}
              >
                {video_title}
              </Text>
            </Link>
          </HStack>
          <HStack
            w="100%"
            spacing={0}
            cursor={"default"}
            flexWrap={"wrap"}
            justifyContent={"space-between"}
          >
            <Tooltip label="Date published">
              <HStack alignItems="center" className="search-item-details-date" spacing={1.5} pr={3}>
                <Videocamera size={"18px"} color={"gray.300"} theme="filled" />
                <Text fontSize="sm" color={"gray.300"} sx={{ textWrap: "nowrap" }}>
                  {info.published}
                </Text>
              </HStack>
            </Tooltip>
            <Menu strategy="fixed" placement={"right"}>
              <MenuButton
                as={IconButton}
                aria-label={"More options"}
                icon={<MoreOne size={"20px"} />}
                size="xs"
                variant="ghost"
                disabled={isButtonLoading}
              />
              <MenuList>
                <MenuItem as={"a"} href={url} target={"_blank"} icon={<ExternalLinkIcon />}>
                  View on {origin_type === originTwitch ? "Twitch" : "YouTube"}
                </MenuItem>

                <MenuDivider />

                <MenuItem icon={<Square theme="filled" />} onClick={() => stopSearch(id)}>
                  Stop analyzing
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </VStack>
      </HStack>
    </VStack>
  );
};
