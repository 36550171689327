import { useState } from "react";
import {
  Text,
  IconButton,
  Badge,
  Box,
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Image,
  VStack,
  Tooltip,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import {
  BackOne,
  Check,
  Eyes,
  Heartbeat,
  InboxIn,
  InboxOut,
  MoreOne,
  Videocamera,
} from "@icon-park/react";
import { DeleteIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Link, useSearchParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  DeleteSearchMutation,
  SetSearchArchiveStatusMutation,
  SetSearchWorkflowMutation,
} from "../../../api/search";
import { originTwitch, originYouTube } from "../../../models/urls";
import TwitchLogo from "../../../assets/twitch-logo.svg";
import YouTubeLogo from "../../../assets/youtube-logo.svg";
import ImgNotFound from "../../../assets/image-not-found.png";
import * as amplitude from "@amplitude/analytics-browser";
import { formatDateForDisplay } from "../../../utils/time";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import Cookies from "js-cookie";
import { ResultsURLParams, RivrLocations, updateURLParams } from "../../../models/navigation";

export const SearchHistoryItem = ({
  id,
  url,
  video_title,
  video_type,
  video_duration,
  origin_type,
  metadata,
  result_count,
  status,
  status_description,
  workflow_status,
  archived_at,
  onDelete,
}) => {
  const [searchParams] = useSearchParams();

  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure();

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const { getFlag } = useKindeAuth();
  const demoUser = getFlag("demo-user").value;
  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const info = !metadata
    ? {
        // No metadata
        thumbnailUrl: { ImgNotFound },
        published: "Not available",
      }
    : origin_type === originTwitch
    ? {
        // Twitch metadata
        thumbnailUrl: metadata.thumbnail_url.replace("%{width}", "480").replace("%{height}", "270"),
        published: formatDateForDisplay(new Date(metadata.published_at)),
      }
    : {
        // YouTube metadata
        thumbnailUrl: metadata.thumbnail,
        published: formatDate(metadata.upload_date),
      };

  function formatDate(dateStr) {
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);
    const date = new Date(`${year}-${month}-${day}`);
    return formatDateForDisplay(date);
  }

  const isArchived = archived_at !== null;

  const viewLink = `${RivrLocations.Results}/${id}?${updateURLParams(searchParams.toString(), [
    [ResultsURLParams.SelectedMoment, ""],
    [ResultsURLParams.SelectedTimestamp, ""],
    [ResultsURLParams.SelectedPanel, ""],
  ])}`;

  const confirmDelete = () => {
    setIsDeleteLoading(true);
    deleteSearchAPI({
      variables: { id, deleted_at: new Date().toISOString() },
    });
  };

  const [deleteSearchAPI] = useMutation(DeleteSearchMutation, {
    onCompleted() {
      setIsDeleteLoading(false);
      onDeleteAlertClose();
      onDelete();
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
  });

  const [archiveSearchAPI] = useMutation(SetSearchArchiveStatusMutation, {
    onCompleted() {
      onDelete();
      setIsStatusLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const [setWorkflowStatusAPI] = useMutation(SetSearchWorkflowMutation, {
    onCompleted() {
      onDelete();
      setIsStatusLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const setSearchWorkflowStatus = (status) => {
    setIsStatusLoading(true);
    setWorkflowStatusAPI({
      variables: { id, status },
    });
  };

  const archiveSearch = (date) => {
    setIsStatusLoading(true);
    archiveSearchAPI({ variables: { id, date } });
  };

  const handleAmplitudeClick = () => {
    amplitude.track("History Card Clicked");
  };

  const getBadgeForStatus = () => {
    if (isArchived) {
      return (
        <Badge
          display="flex"
          borderRadius="sm"
          colorScheme="gray"
          py={0.5}
          variant="solid"
          pointerEvents={"none"}
          letterSpacing={0.5}
        >
          Archived
        </Badge>
      );
    }
    if (status === "error") {
      return (
        <Badge
          display="flex"
          borderRadius="sm"
          colorScheme="red"
          py={0.5}
          variant="solid"
          pointerEvents={"none"}
          letterSpacing={0.5}
        >
          Error
        </Badge>
      );
    } else {
      switch (workflow_status) {
        case "completed":
          return (
            <Badge
              display="flex"
              borderRadius="sm"
              colorScheme="blue"
              py={0.5}
              variant="solid"
              pointerEvents={"none"}
              letterSpacing={0.5}
            >
              Completed
            </Badge>
          );
        case "reviewing":
          return (
            <Badge
              display="flex"
              borderRadius="sm"
              colorScheme="purple"
              py={0.5}
              variant="solid"
              pointerEvents={"none"}
              letterSpacing={0.5}
            >
              Reviewing
            </Badge>
          );
        default:
          return (
            <Badge
              display="flex"
              borderRadius="sm"
              colorScheme="green"
              py={0.5}
              variant="solid"
              pointerEvents={"none"}
              letterSpacing={0.5}
            >
              Analyzed
            </Badge>
          );
      }
    }
  };

  return (
    <>
      <AlertDialog isOpen={isDeleteAlertOpen} onClose={onDeleteAlertClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete from History
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this media and its Moments from your history? This
              cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onDeleteAlertClose} colorScheme="gray" variant={"ghost"}>
                Cancel
              </Button>
              <Button isLoading={isDeleteLoading} colorScheme="red" onClick={confirmDelete} ml={2}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <VStack
        spacing="0"
        alignItems={"flex-start"}
        className="search-item-wrapper"
        borderWidth={1}
        borderRadius={"md"}
        h={"fit-content"}
        _hover={{
          ".search-item-details": { bg: "gray.600", transition: "background-color 200ms" },
        }}
      >
        <Box position="relative" w="100%" h="auto" opacity={isArchived ? 0.4 : 1}>
          <Link to={viewLink} onClick={handleAmplitudeClick}>
            <Image
              src={info.thumbnailUrl}
              borderTopLeftRadius={4}
              borderTopRightRadius={4}
              w="1920px"
              h="auto"
              fallbackSrc={ImgNotFound}
            />
          </Link>
        </Box>
        <VStack
          w="100%"
          spacing={0}
          mt={"-46px !important"}
          position={"relative"}
          pointerEvents={"none"}
        >
          <HStack
            justifyContent={"flex-start"}
            w={"100%"}
            bg={`linear-gradient(to top, #171923 25%, transparent 100%)`}
            p={3}
            className="search-item-status"
          >
            <HStack alignItems={"flex-end"} pointerEvents={"none"}>
              {isAdmin && getBadgeForStatus()}

              {video_type === "VOD" && (
                <Badge
                  colorScheme={"blackAlpha"}
                  bg={"blackAlpha.800"}
                  variant={"solid"}
                  position={"absolute"}
                  right={3}
                  py={0.5}
                  opacity={isArchived ? 0.4 : 1}
                >
                  {video_duration}
                </Badge>
              )}
              <Box h="30px" bg="gray.900" pointerEvents={"none"} />
            </HStack>
          </HStack>
        </VStack>
        <HStack
          justifyContent="space-between"
          w="100%"
          bg={isArchived ? "whiteAlpha.50" : "gray.700"}
          p="4"
          className="search-item-details"
          borderBottomLeftRadius={4}
          borderBottomRightRadius={4}
          h="100%"
          alignItems={"flex-start"}
          transition={"background-color 200ms"}
        >
          <VStack
            alignItems={"flex-start"}
            alignContent={"flex-start"}
            justifyContent={"center"}
            spacing={1.5}
            w="100%"
          >
            <HStack
              alignItems="center"
              justifyContent={"flex-start"}
              className="search-item-details-icon-title"
              w="100%"
              spacing={1}
              opacity={isArchived ? 0.4 : 1}
            >
              {origin_type === originTwitch ? (
                <Image src={TwitchLogo} alt="Twitch Logo" title="Twitch" h="18px" mr={1} />
              ) : origin_type === originYouTube ? (
                <Image src={YouTubeLogo} alt="YouTube Logo" title="YouTube" h="18px" mr={1} />
              ) : (
                <></>
              )}
              <Link to={viewLink} onClick={handleAmplitudeClick}>
                <Text
                  className={`lnk-to-result-${id}`}
                  fontSize="sm"
                  fontWeight="medium"
                  noOfLines={1}
                  title={video_title || `Error: ${status_description}`}
                >
                  {video_title || "Error"}
                </Text>
              </Link>
            </HStack>
            <HStack
              w="100%"
              spacing={0}
              cursor={"default"}
              flexWrap={"wrap"}
              justifyContent={"space-between"}
            >
              <HStack spacing={0} opacity={isArchived ? 0.4 : 1}>
                <Tooltip label="Date published">
                  <HStack
                    alignItems="center"
                    className="search-item-details-date"
                    spacing={1.5}
                    pr={3}
                  >
                    <Videocamera size={"18px"} color={"gray.300"} theme="filled" />
                    <Text fontSize="sm" color={"gray.300"} sx={{ textWrap: "nowrap" }}>
                      {info.published}
                    </Text>
                  </HStack>
                </Tooltip>
                {result_count > 0 && (
                  <Tooltip
                    label={`${result_count} user Moment${result_count === 1 ? "" : "s"}`}
                    hasArrow
                  >
                    <HStack alignItems="center" spacing={1.5} pr={3}>
                      <Heartbeat size={"18px"} theme="outline" />
                      <Text fontSize="sm" color={"gray.300"} sx={{ textWrap: "nowrap" }}>
                        {result_count}
                      </Text>
                    </HStack>
                  </Tooltip>
                )}
              </HStack>
              <Menu strategy="fixed" placement={"right"}>
                <MenuButton
                  as={IconButton}
                  aria-label={"More options"}
                  icon={<MoreOne size={"20px"} />}
                  size="xs"
                  variant="ghost"
                  disabled={isStatusLoading || isDeleteLoading}
                />
                <MenuList>
                  {isAdmin && status !== "error" && !isArchived && (
                    <>
                      {workflow_status !== null && (
                        <MenuItem icon={<BackOne />} onClick={() => setSearchWorkflowStatus(null)}>
                          Reset to Analyzed
                        </MenuItem>
                      )}
                      {workflow_status !== "reviewing" && (
                        <MenuItem
                          icon={<Eyes />}
                          onClick={() => setSearchWorkflowStatus("reviewing")}
                        >
                          Mark Reviewing
                        </MenuItem>
                      )}
                      {workflow_status !== "completed" && (
                        <MenuItem
                          icon={<Check />}
                          onClick={() => setSearchWorkflowStatus("completed")}
                        >
                          Mark Completed
                        </MenuItem>
                      )}
                      <MenuDivider />
                    </>
                  )}
                  <MenuItem as={"a"} href={url} target={"_blank"} icon={<ExternalLinkIcon />}>
                    View on {origin_type === originTwitch ? "Twitch" : "YouTube"}
                  </MenuItem>

                  {!demoUser && (
                    <>
                      <MenuDivider />
                      {isAdmin && (
                        <MenuItem
                          icon={!isArchived ? <InboxIn /> : <InboxOut />}
                          onClick={() => {
                            archiveSearch(!isArchived ? new Date() : null);
                          }}
                        >
                          {!isArchived ? "Archive" : "Unarchive"}
                        </MenuItem>
                      )}
                      <MenuItem icon={<DeleteIcon />} onClick={onDeleteAlertOpen}>
                        Delete from History
                      </MenuItem>
                    </>
                  )}
                </MenuList>
              </Menu>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </>
  );
};
