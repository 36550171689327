import { useState, useRef, useContext, useMemo } from "react";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  PopoverHeader,
  HStack,
  Box,
  VStack,
  IconButton,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import { SixPoints } from "@icon-park/react";
import CreditsHelp from "./CreditsHelp";
import { formatDateForDisplay } from "../../utils/time";
import { RivrCampaign, campaignFromQueryResult } from "../../models/rivrCampaign";
import { useQuery } from "@apollo/client";
import { OrgCampaignsQuery } from "../../api/campaign";
import { OrganizationMembership } from "../../models/rivrOrganization";
import { AccountContext } from "../../context/AccountContext";
import { useSearchParams } from "react-router-dom";
import { CampaignURLParams } from "../../models/navigation";
import { getCurrentCycleStartDate } from "../../utils/credits";

function CreditsMenu() {
  const { memberships } = useContext(AccountContext);

  const isMobile = useBreakpointValue({ base: true, sm: true, md: true, lg: false });

  const [isCreditHelpOpen, setIsCreditHelpOpen] = useState(false);
  const onCloseCreditHelp = () => setIsCreditHelpOpen(false);
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  const [searchParams] = useSearchParams();
  const selectedOrg = searchParams.get(CampaignURLParams.SelectedOrganization) || "";

  const formatCurrency = (value: number) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const selectedMembership = useMemo(() => {
    return selectedOrg !== ""
      ? memberships.find((m: OrganizationMembership) => m.organization.id === selectedOrg)
      : memberships[0];
  }, [selectedOrg, memberships]);

  const { data: campaignsData } = useQuery(OrgCampaignsQuery, {
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
    variables: { org_id: selectedMembership?.organization.id },
    // poll to update credit counts when new searches are added
    pollInterval: parseInt(process.env.REACT_APP_ACTIVE_SEARCHES_POLL_TIME || "10000"),
  });

  const campaigns: RivrCampaign[] | undefined = useMemo(() => {
    if (campaignsData) {
      return campaignsData.campaign.map((c: any) => campaignFromQueryResult(c));
    }
  }, [campaignsData]);

  const planAllotment = 100;
  const ppvHour = 1;

  const hoursThisCycle = campaigns
    ? campaigns.reduce((totalHours: number, campaign: RivrCampaign) => {
        return totalHours + campaign.hoursThisCycle;
      }, 0)
    : 0;

  const hoursLastCycle = campaigns
    ? campaigns.reduce((totalHours: number, campaign: RivrCampaign) => {
        return totalHours + campaign.hoursLastCycle;
      }, 0)
    : 0;

  const nextCycleStartDate = () => {
    const result = getCurrentCycleStartDate();
    result.setMonth(result.getMonth() + 1);
    return result;
  };

  const lastCycleEndDate = () => {
    const result = getCurrentCycleStartDate();
    result.setDate(result.getDate() - 1);
    result.setHours(23, 59, 59, 999);
    return result;
  };

  return (
    <Box>
      <Popover placement={"bottom-end"}>
        <PopoverTrigger>
          {!isMobile ? (
            <Button size={"sm"} fontWeight={"medium"} aria-label={"VOD hours"}>
              <SixPoints theme="filled" />
              <Text pt={0.5} pl={1}>
                {hoursThisCycle} / {planAllotment}
              </Text>
            </Button>
          ) : (
            <Box>
              <Tooltip placement={"bottom"} label={"VOD Hours"}>
                <IconButton
                  size={"sm"}
                  aria-label={"VOD hours"}
                  icon={<SixPoints theme="filled" />}
                />
              </Tooltip>
            </Box>
          )}
        </PopoverTrigger>
        <PopoverContent minW={"max-content"}>
          <PopoverHeader>
            <HStack w={"full"} justify={"space-between"}>
              <HStack spacing={1}>
                <SixPoints theme="filled" />
                <Text>VOD Hours</Text>
              </HStack>
              <Tooltip label={"Help"}>
                <IconButton
                  icon={<QuestionOutlineIcon />}
                  borderRadius={"full"}
                  size={"xs"}
                  variant={"ghost"}
                  aria-label={"Credits help"}
                  onClick={() => setIsCreditHelpOpen(true)}
                />
              </Tooltip>
            </HStack>
          </PopoverHeader>
          <PopoverBody>
            <VStack w={"full"} py={2}>
              <VStack w={"full"}>
                <HStack w={"full"} justify={"space-between"}>
                  <VStack spacing={-1} align={"flex-start"}>
                    <Text>Analyzed this month</Text>
                    {/* <Text fontSize={"xs"} color={"gray.400"}>
                    Next cycle begins {formatDateForDisplay(nextCycleStartDate).slice(0, -6)}
                  </Text> */}
                  </VStack>
                  <Box
                    bg={"gray.600"}
                    borderRadius={"full"}
                    px={2}
                    py={1}
                    fontSize={"sm"}
                    borderWidth={1}
                  >
                    {hoursThisCycle} / {planAllotment}
                  </Box>
                </HStack>
                {hoursThisCycle > planAllotment && (
                  <VStack
                    bg={"gray.800"}
                    w={"full"}
                    borderRadius={"md"}
                    py={2}
                    px={3}
                    spacing={-0.5}
                    borderWidth={1}
                  >
                    <HStack justify={"space-between"} w={"full"} fontSize={"sm"}>
                      <Text>Overage</Text>
                      <Text>Expected cost</Text>
                    </HStack>
                    <HStack justify={"space-between"} w={"full"} fontSize={"sm"} color={"gray.300"}>
                      <Text>{hoursThisCycle - planAllotment}</Text>
                      <Text>{formatCurrency((hoursThisCycle - planAllotment) * ppvHour)}</Text>
                    </HStack>
                  </VStack>
                )}
              </VStack>
              <VStack w={"full"} pt={2} opacity={"0.66"}>
                <HStack w={"full"} justify={"space-between"}>
                  <VStack spacing={-1} align={"flex-start"}>
                    <Text>Analyzed last month</Text>
                    {/* <Text fontSize={"xs"} color={"gray.400"}>
                    Cycle ended {formatDateForDisplay(previousCycleEndDate).slice(0, -6)}
                  </Text> */}
                  </VStack>
                  <Box
                    bg={"gray.600"}
                    borderRadius={"full"}
                    px={2}
                    py={1}
                    fontSize={"sm"}
                    borderWidth={1}
                  >
                    {hoursLastCycle} / {planAllotment}
                  </Box>
                </HStack>
              </VStack>
              {hoursLastCycle > planAllotment && (
                <VStack
                  bg={"gray.800"}
                  w={"full"}
                  borderRadius={"md"}
                  py={2}
                  px={3}
                  spacing={-0.5}
                  borderWidth={1}
                  opacity={"0.66"}
                >
                  <HStack justify={"space-between"} w={"full"} fontSize={"sm"}>
                    <Text>Overage</Text>
                    <Text>Expected cost</Text>
                  </HStack>
                  <HStack justify={"space-between"} w={"full"} fontSize={"sm"} color={"gray.300"}>
                    <Text>{hoursLastCycle - planAllotment}</Text>
                    <Text>{formatCurrency((hoursLastCycle - planAllotment) * ppvHour)}</Text>
                  </HStack>
                </VStack>
              )}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <CreditsHelp isOpen={isCreditHelpOpen} onClose={onCloseCreditHelp} cancelRef={cancelRef} />
    </Box>
  );
}

export default CreditsMenu;
