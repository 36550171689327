import { useQuery } from "@apollo/client";
import { Box, Stat, StatLabel, StatNumber, StatHelpText, Link } from "@chakra-ui/react";
import { ClipRequestsThisMonthQuery } from "../../api/stats";
import { allowedUserIds } from "../../utils/allowedUserIds";

function ClipRequestsThisMonth() {
  const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

  const { data } = useQuery(ClipRequestsThisMonthQuery, {
    variables: {
      timestamp_thirty_days_ago: thirtyDaysAgo.toISOString().split(".")[0],
      excludedUserIDs: allowedUserIds,
    },
  });

  const clipRequestCount = data?.clip_request_aggregate?.aggregate?.count || 0;
  const clipRequests = data?.clip_request || [];
  let longestClipLength = 0;
  let longestClipSearchId = "";

  clipRequests.forEach((clip) => {
    const clipLength = clip.lead_in + clip.lead_out;
    if (clipLength > longestClipLength) {
      longestClipLength = clipLength;
      longestClipSearchId = clip.search_id;
    }
  });

  return (
    <>
      <Box p="6" backgroundColor="gray.700" borderRadius={8} w="full">
        <Stat>
          <StatLabel>Clip Requests</StatLabel>
          <StatNumber>{clipRequestCount}</StatNumber>
          <StatHelpText mb={0}>Last 30 days</StatHelpText>
        </Stat>
      </Box>
      <Box p="6" backgroundColor="gray.700" borderRadius={8} w="full">
        <Stat>
          <StatLabel>Longest Clip (30 days)</StatLabel>
          <StatNumber>{longestClipLength} seconds</StatNumber>
          <StatHelpText mb={0}>
            <Link href={`/results/${longestClipSearchId}`}>Search: {longestClipSearchId}</Link>
          </StatHelpText>
        </Stat>
      </Box>
    </>
  );
}

export default ClipRequestsThisMonth;
