import React from "react";
import { Box, Heading, HStack, VStack, IconButton, AbsoluteCenter, Text } from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";
import ActiveSearchesStat from "../components/Stats/ActiveSearchesStat";
import SearchesTwentyFourHours from "../components/Stats/SearchesTwentyFourHours";
import SearchesThisWeek from "../components/Stats/SearchesThisWeek";
import SearchesThisMonth from "../components/Stats/SearchesThisMonth";
import TopUsersThisWeek from "../components/Stats/TopUsersThisWeek";
import TopUsersThisMonth from "../components/Stats/TopUsersThisMonth";
import MomentsThisWeek from "../components/Stats/MomentsThisWeek";
import MomentsThisMonth from "../components/Stats/MomentsThisMonth";
import UsersNoSearchesThisMonth from "../components/Stats/UsersNoSearchesThisMonth";
import UsersNoSearchesThisWeek from "../components/Stats/UsersNoSearchesThisWeek";
import ClipRequestsThisWeek from "../components/Stats/ClipRequestsThisWeek";
import ClipRequestsThisMonth from "../components/Stats/ClipRequestsThisMonth";
import AverageClipLength from "../components/Stats/AverageClipLength";
import TotalHoursComponent from "../components/Stats/TotalHoursProcessed";

import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { allowedUserIds } from "../utils/allowedUserIds";

const Stats = () => {
  const { isAuthenticated, isLoading, user } = useKindeAuth();

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Box w={"100%"} height={"100%"} position={"relative"}>
      {allowedUserIds.includes(user.id) && isAuthenticated && !isLoading ? (
        <VStack p={6} alignItems={"flex-start"} w="full" spacing="4">
          <HStack mb="2" alignItems={"flex-start"} justifyContent={"space-between"} w="full">
            <Heading as="h1" size="lg">
              Stats For Nerds.
            </Heading>
            <IconButton
              size="sm"
              colorScheme="blue"
              icon={<RepeatIcon />}
              onClick={handleRefresh}
            />
          </HStack>
          <VStack alignItems={"flex-start"} w="full" spacing="2">
            <Heading size="md" fontWeight={"medium"}>
              Searches
            </Heading>
            <Box backgroundColor="gray.900" p="5" borderRadius={8} w="full">
              <HStack spacing="4" w="full" alignItems={"flex-start"}>
                <ActiveSearchesStat />
                <SearchesTwentyFourHours />
                <SearchesThisWeek />
                <SearchesThisMonth />
                <TotalHoursComponent />
              </HStack>
            </Box>
          </VStack>
          <VStack alignItems={"flex-start"} w="full" spacing="2">
            <Heading size="md" fontWeight={"medium"}>
              Searchers
            </Heading>
            <Box backgroundColor="gray.900" p="5" borderRadius={8} w="full">
              <HStack spacing="4" w="full" alignItems={"flex-start"}>
                <TopUsersThisWeek />
                <TopUsersThisMonth />
                <UsersNoSearchesThisWeek />
                <UsersNoSearchesThisMonth />
              </HStack>
            </Box>
          </VStack>
          <VStack alignItems={"flex-start"} w="full" spacing="2">
            <Heading size="md" fontWeight={"medium"}>
              Clips
            </Heading>
            <Box backgroundColor="gray.900" p="5" borderRadius={8} w="full">
              <HStack spacing="4" w="full" alignItems={"flex-start"}>
                <ClipRequestsThisWeek />
                <ClipRequestsThisMonth />
                <AverageClipLength />
              </HStack>
            </Box>
          </VStack>
          <VStack alignItems={"flex-start"} w="full" spacing="2">
            <Heading size="md" fontWeight={"medium"}>
              Moments
            </Heading>
            <Box backgroundColor="gray.900" p="5" borderRadius={8} w="full">
              <VStack spacing="4" w="full" alignItems={"flex-start"}>
                <MomentsThisWeek />
                <MomentsThisMonth />
              </VStack>
            </Box>
          </VStack>
        </VStack>
      ) : (
        <Box className="no-access">
          <AbsoluteCenter textAlign={"center"}>
            <Text fontSize="2xl" fontWeight={600}>
              Unauthorized Access Detected
            </Text>
            <Text fontSize="lg" color="rivr.red" fontWeight={600}>
              Tracing IP Address...
            </Text>
            <Text fontSize="lg" color="rivr.red" fontWeight={600}>
              Logging activities to server...
            </Text>
          </AbsoluteCenter>
        </Box>
      )}
    </Box>
  );
};

export default Stats;
