import {
  HStack,
  Select,
  Button,
  ButtonGroup,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";

interface CampaignFilterProps {
  campaigns: { id: string; title: string }[];
  filter: { campaignId: string };
  handleFilterChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  viewFilter: "all" | "claimed" | "unclaimed";
  setViewFilter: (filter: "all" | "claimed" | "unclaimed") => void;
  exportToCSV: (type: "full" | "client") => void;
}

const CampaignFilter = ({
  campaigns,
  filter,
  handleFilterChange,
  viewFilter,
  setViewFilter,
  exportToCSV,
}: CampaignFilterProps) => {
  return (
    <HStack w={"100%"} justifyContent={"space-between"}>
      <Select
        size={"sm"}
        borderRadius={"md"}
        minW={"max-content"}
        maxW={"max-content"}
        placeholder="Filter by Campaign"
        name="campaignId"
        value={filter.campaignId}
        onChange={handleFilterChange}
        isDisabled={!campaigns.length}
      >
        {campaigns.map((campaign) => (
          <option key={campaign.id} value={campaign.id}>
            {campaign.title}
          </option>
        ))}
      </Select>
      <HStack>
        <ButtonGroup size="sm" isAttached>
          <Button
            colorScheme={viewFilter === "all" ? "blue" : "gray"}
            onClick={() => setViewFilter("all")}
          >
            All
          </Button>
          <Button
            colorScheme={viewFilter === "claimed" ? "blue" : "gray"}
            onClick={() => setViewFilter("claimed")}
          >
            Claimed
          </Button>
          <Button
            colorScheme={viewFilter === "unclaimed" ? "blue" : "gray"}
            onClick={() => setViewFilter("unclaimed")}
          >
            Unclaimed
          </Button>
        </ButtonGroup>
        <Box>
          <Menu>
            <MenuButton as={Button} size={"sm"} minW={"fit-content"}>
              Export
            </MenuButton>
            <MenuList zIndex={10000}>
              <MenuItem onClick={() => exportToCSV("full")}>Export full CSV</MenuItem>
              <MenuItem onClick={() => exportToCSV("client")}>Export for Client</MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
    </HStack>
  );
};

export default CampaignFilter;
