import { useState, useEffect, useRef, useMemo } from "react";
import {
  Button,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Tooltip,
  SimpleGrid,
  Center,
  Box,
  Divider,
  Text,
  HStack,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
} from "@chakra-ui/react";
import {
  DeleteIcon,
  SearchIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { useApolloClient, useMutation } from "@apollo/client";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { DeleteAllSearchHistoryMutation, SearchHistoryQuery } from "../../api/search";
import { SearchHistoryItem } from "./SearchHistoryItem/SearchHistoryItem";
import { usePagination } from "../../hooks/usePagination";
import { originTwitch } from "../../models/urls";

type Props = {
  eventCounter: number;
};

export const SearchHistory = (props: Props) => {
  const sortMethods = [
    {
      value: "processingDate",
      display: "Date analyzed",
    },
    {
      value: "publishingDate",
      display: "Date published",
    },
    {
      value: "title",
      display: "Title",
    },
    {
      value: "momentCount",
      display: "Moment count",
    },
  ];
  const { eventCounter } = props;
  const { user, isLoading, isAuthenticated } = useKindeAuth();
  const historyLimit = parseInt(process.env.REACT_APP_SEARCH_HISTORY_MAX_LIMIT || "50");
  const client = useApolloClient();
  const [searchList, setSearchList] = useState<any[]>([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const onCloseDialog = () => setIsConfirmDialogOpen(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [filterTerm, setFilterTerm] = useState("");
  const [sortMethod, setSortMethod] = useState("processingDate");

  const getSearchHistory = async () => {
    if (user) {
      const response = await client.query({
        query: SearchHistoryQuery,
        variables: {
          user_id: user.id,
          limit: historyLimit,
        },
      });
      if (response && response.data && response.data.search) {
        setSearchList(response.data.search);
      }
    }
  };

  const [deleteAllSearchHistoryAPI, { loading: isDeleteAllLoading }] = useMutation(
    DeleteAllSearchHistoryMutation,
    {
      onCompleted(data) {
        getSearchHistory(); // refresh history
        setIsConfirmDialogOpen(false);
      },
      onError({ graphQLErrors, networkError }) {
        if (graphQLErrors) {
          for (const err of graphQLErrors) {
            console.log("Error:", err.extensions.code);
          }
        }
        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
        }
      },
    }
  );

  const deleteAllSearchHistory = () => {
    if (user) {
      deleteAllSearchHistoryAPI({
        variables: {
          user_id: user.id,
          deleted_at: new Date().toISOString(),
        },
      });
    }
  };

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getSearchHistory();
    }
  }, [isLoading, eventCounter, isAuthenticated]);

  const sortSearchList = useMemo(() => {
    return (a: any, b: any) => {
      if (a.archived_at !== null) return 1;
      if (b.archived_at !== null) return -1;

      if (sortMethod === "title") return a.video_title?.localeCompare(b.video_title);
      else if (sortMethod === "momentCount")
        return b.moments_aggregate.aggregate.count - a.moments_aggregate.aggregate.count;
      else if (sortMethod === "processingDate")
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      else if (sortMethod === "publishingDate")
        return (
          new Date(
            b.origin_type === originTwitch ? b.metadata.published_at : b.metadata.upload_date
          ).getTime() -
          new Date(
            a.origin_type === originTwitch ? a.metadata.published_at : a.metadata.upload_date
          ).getTime()
        );
      return 0;
    };
  }, [sortMethod]);

  const filterSeachList = useMemo(() => {
    return (item: any) => item.video_title?.toLowerCase().includes(filterTerm.toLowerCase());
  }, [filterTerm]);

  const {
    elements,
    totalElements,
    page,
    totalPages,
    nextPage,
    previousPage,
    hasNextPage,
    hasPreviousPage,
  } = usePagination(searchList, sortSearchList, filterSeachList, 15);

  if (searchList.length === 0) return null;
  return (
    <>
      <Center py={12} px={6}>
        <Box w="100%">
          <HStack justifyContent={"space-between"} alignItems={"baseline"}>
            <Text pb="3" fontSize="lg" fontWeight="medium">
              History
            </Text>
            <HStack>
              <Flex>
                <Box>
                  <InputGroup size={"sm"}>
                    <InputLeftElement pointerEvents="none">
                      <SearchIcon pointerEvents="none" />
                    </InputLeftElement>
                    <Input
                      className={"amp-unmask"}
                      size="sm"
                      borderRadius={"md"}
                      value={filterTerm}
                      onChange={(e) => setFilterTerm(e.target.value)}
                      placeholder="Filter by title"
                      variant={"filled"}
                    />
                  </InputGroup>
                </Box>
              </Flex>
              <Box>
                <Menu closeOnSelect={false}>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm">
                    Sort by
                  </MenuButton>
                  <MenuList>
                    <MenuOptionGroup
                      title="Sort by"
                      type="radio"
                      value={sortMethod}
                      onChange={setSortMethod as (value: string | string[]) => void}
                    >
                      {sortMethods.map((method) => (
                        <MenuItemOption key={method.value} value={method.value}>
                          {method.display}
                        </MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </Box>
              <Tooltip label="Delete all" placement="top">
                <IconButton
                  aria-label="Delete all searches"
                  className="btn-delete-all-history"
                  onClick={() => setIsConfirmDialogOpen(true)}
                  size={"sm"}
                  colorScheme="red"
                  isLoading={isDeleteAllLoading}
                  icon={<DeleteIcon />}
                >
                  Delete all
                </IconButton>
              </Tooltip>
            </HStack>
          </HStack>

          <Divider orientation="horizontal" border="1px" borderColor="gray.500" mb={4} />
          {elements.length > 0 ? (
            <SimpleGrid columns={{ md: 2, lg: 3, xl: 4, "3xl": 5, "4xl": 5 }} spacing={4} my={4}>
              {elements.map((item) => (
                <SearchHistoryItem
                  result_count={item.moments_aggregate.aggregate.count}
                  key={item.id}
                  {...item}
                  onDelete={getSearchHistory}
                />
              ))}
            </SimpleGrid>
          ) : (
            <Center pb="4">
              <Text mt="12" fontSize="lg" fontWeight="medium">
                No items match your filter
              </Text>
            </Center>
          )}
          {elements.length > 0 && (
            <HStack justifyContent={"space-between"} spacing={2}>
              <Box>
                <Text fontSize={"sm"} color={"gray.400"}>
                  Showing {totalElements} {totalElements === 1 ? "item" : "items"}
                </Text>
              </Box>

              <HStack>
                <Text
                  fontSize={"sm"}
                  color={"gray.400"}
                  pr={2}
                >{`Page ${page} of ${totalPages}`}</Text>
                <IconButton
                  size="sm"
                  variant={"outline"}
                  aria-label="Previous history page"
                  icon={<ChevronLeftIcon />}
                  onClick={previousPage}
                  disabled={!hasPreviousPage}
                />
                <IconButton
                  size="sm"
                  variant={"outline"}
                  aria-label="Next history page"
                  icon={<ChevronRightIcon />}
                  onClick={nextPage}
                  disabled={!hasNextPage}
                />
              </HStack>
            </HStack>
          )}
        </Box>
      </Center>
      <AlertDialog
        isOpen={isConfirmDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDialog}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete all history
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to remove all videos and their Moments from your history? This
              cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseDialog} colorScheme="gray" variant="ghost">
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteAllSearchHistory} ml={2}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
