import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, VStack, HStack, IconButton, Tooltip } from "@chakra-ui/react";
import { CloseOne, Scale, InboxIn, InboxOut, Like } from "@icon-park/react";
import { Link } from "react-router-dom";
import { secondsToHMSFormat, durationToSeconds } from "../../utils/time";
import { RivrMoment } from "../../models/moment";

interface Props {
  toggleVisibility: () => void;
  timestamp: string;
  resultsLink: string;
  videoUrl: string;
  playerScale: number;
  setPlayerScale: React.Dispatch<React.SetStateAction<number>>;
  handleFavorite?: () => void;
  handleArchive?: (date: Date | null) => void;
  moment?: RivrMoment;
}

const MiniPlayer = ({
  toggleVisibility,
  timestamp,
  resultsLink,
  videoUrl,
  playerScale,
  setPlayerScale,
  handleFavorite,
  handleArchive,
  moment,
}: Props) => {
  const handleToggleScale = () => setPlayerScale((playerScale) => (playerScale === 1 ? 2 : 1));
  const baseWidthRem = "30rem";
  const aspectRatio = 9 / 16;
  const calculatedHeight = `calc(${baseWidthRem} * ${aspectRatio} * 1.18)`;
  const parent = window.location.hostname;
  const formattedTimestamp = secondsToHMSFormat(durationToSeconds(timestamp));
  const twitchEmbedUrl = videoUrl
    .replace("www.", "")
    .replace("twitch.tv/videos/", "player.twitch.tv/?video=")
    .concat(`&t=${formattedTimestamp}&parent=${parent}&muted=false`);

  const isArchived = moment?.archivedAt !== null;

  return (
    <Box
      position={"fixed"}
      right={6}
      bottom={24}
      w={`calc(${baseWidthRem} * ${playerScale})`}
      maxW={"50vw"}
      h={`calc(${calculatedHeight} * ${playerScale})`}
      maxH={"60vh"}
      zIndex={"overlay"}
      boxShadow={"lg"}
      borderRadius={"md"}
      overflow={"hidden"}
      borderWidth={1}
      bg={"gray.700"}
      shadow={"dark-lg"}
    >
      <VStack spacing={0} height={"100%"}>
        <Box flex={"1"} w={"100%"} position={"relative"}>
          <iframe
            title="Twitch Stream"
            src={twitchEmbedUrl}
            style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
            allowFullScreen={true}
          />
        </Box>
        <HStack p={2} bg={"gray.800"} w={"100%"} justify={"space-between"}>
          <Tooltip label={"Scale player"} placement={"bottom"}>
            <IconButton
              transform={"scaleX(-1)"}
              size={"sm"}
              icon={<Scale />}
              aria-label={"Scale video player"}
              colorScheme={"blue"}
              onClick={handleToggleScale}
            />
          </Tooltip>
          {moment && handleFavorite && handleArchive && (
            <HStack>
              <Tooltip label={moment.favorited ? "Unfavorite Moment" : "Favorite Moment"}>
                <IconButton
                  size="sm"
                  icon={<Like size={16} theme={moment.favorited ? "filled" : "outline"} />}
                  aria-label={"Favorite Moment"}
                  onClick={handleFavorite}
                  colorScheme={moment.favorited ? "red" : "gray"}
                />
              </Tooltip>
              <Tooltip label={isArchived ? "Unarchive" : "Archive"}>
                <IconButton
                  size="sm"
                  icon={isArchived ? <InboxOut /> : <InboxIn />}
                  aria-label={"Archive Moment"}
                  onClick={() => handleArchive(isArchived ? null : new Date())}
                  colorScheme={isArchived ? "purple" : "gray"}
                />
              </Tooltip>
            </HStack>
          )}
          <Tooltip label={"Go to result page"} placement={"bottom"}>
            <IconButton
              size={"sm"}
              icon={<ExternalLinkIcon />}
              aria-label={"Go to results page"}
              colorScheme={"green"}
              as={Link}
              to={resultsLink}
            />
          </Tooltip>
          <IconButton
            size={"sm"}
            icon={<CloseOne size={20} />}
            borderRadius={"full"}
            aria-label={"Close video player"}
            onClick={toggleVisibility}
            pos={"absolute"}
            top={2}
            right={2}
          />
        </HStack>
      </VStack>
    </Box>
  );
};

export default MiniPlayer;
