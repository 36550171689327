import { HStack, IconButton } from "@chakra-ui/react";
import { PlayOne } from "@icon-park/react";
import { TypeAnimation } from "react-type-animation";

const MockSearchbar = () => {
  return (
    <HStack
      w={"100%"}
      maxW={"540px"}
      p={2}
      pl={6}
      bg={"gray.700"}
      borderRadius={"full"}
      borderWidth={1}
      overflow={"hidden"}
      alignItems={"center"}
      justifyContent={"space-between"}
      pointerEvents={"none"}
      fontSize={"xl"}
    >
      <TypeAnimation
        sequence={[
          "Discover your best moments, instantly.",
          3000,
          "youtube.com/watch?v=dQw4w9WgXcQ",
          2000,
          "twitch.tv/videos/26001337420",
          2000,
        ]}
        speed={50}
        deletionSpeed={80}
        wrapper={"p"}
        repeat={Infinity}
        pointerEvents={"none"}
      />
      <IconButton
        borderRadius={"full"}
        colorScheme={"yellow"}
        pointerEvents={"none"}
        icon={<PlayOne size={24} theme={"filled"} />}
      >
        Go
      </IconButton>
    </HStack>
  );
};

export default MockSearchbar;
