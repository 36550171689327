import { useQuery } from "@apollo/client";
import { Stat, StatLabel, StatNumber, StatHelpText, Box } from "@chakra-ui/react";
import { SearchesTwentyFourHoursQuery } from "../../api/stats";
import { allowedUserIds } from "../../utils/allowedUserIds";

function SearchesTwentyFourHours() {
  // Get the timestamp for 7 days ago
  const TwentyFourHoursAgo = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000);
  const { data } = useQuery(SearchesTwentyFourHoursQuery, {
    variables: {
      timestamp_twenty_four_hours_ago: TwentyFourHoursAgo.toISOString().split(".")[0],
      excludedUserIDs: allowedUserIds,
    },
  });

  // Add check for data and data.search before trying to access data.search.length
  const numSearchesTwentyFourHours = data && data.search ? data.search.length : 0;

  return (
    <Box p="6" backgroundColor={"gray.700"} w={"full"} borderRadius={8}>
      <Stat>
        <StatLabel>Searches</StatLabel>
        <StatNumber>{numSearchesTwentyFourHours}</StatNumber>
        <StatHelpText mb="0">In the last 24 hours</StatHelpText>
      </Stat>
    </Box>
  );
}

export default SearchesTwentyFourHours;
