import React from "react";
import { Button, ButtonGroup, IconButton, Image, Tooltip } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { Plug } from "@icon-park/react";
import TwitchLogo from "../../assets/dark-twitch-logo.svg";
import YouTubeLogo from "../../assets/youtube-logo.svg";
import { getRandomBase64 } from "../../utils/random";

type Props = {
  platform: "twitch" | "youtube";
  isConnecting: boolean;
  setIsConnecting: (isConnecting: boolean) => void;
  username: string | null;
};

const PlatformButton: React.FC<Props> = (props: Props) => {
  const { platform, isConnecting, setIsConnecting, username } = props;
  const isConnected = username !== null;
  const platformName = platform.charAt(0).toUpperCase() + platform.slice(1);
  const colorScheme = platform === "twitch" ? "purple" : "red";
  const tooltipLabel = isConnected ? `Connected to ${platformName}` : `Connect to ${platformName}`;

  const handlePlatformConnect = () => {
    setIsConnecting(true);
    if (platform === "twitch") {
      const client_id = process.env.REACT_APP_TWITCH_CLIENT_ID;
      const redirect_uri = process.env.REACT_APP_TWITCH_REDIRECT_URI;
      const claims = JSON.stringify({
        userinfo: {
          preferred_username: null,
        },
      });
      const scope = encodeURIComponent("openid");
      const state = getRandomBase64(20);

      localStorage.setItem("twitch-oauth.state", state);
      window.location.href = `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&claims=${claims}&scope=${scope}&state=${state}`;
    } else {
      setIsConnecting(false);
    }
  };

  return (
    <Tooltip label={tooltipLabel}>
      <ButtonGroup isAttached variant="solid" ml={"0 !important"} onClick={handlePlatformConnect}>
        <Button
          leftIcon={
            <Image src={platform === "twitch" ? TwitchLogo : YouTubeLogo} h={4} w={"100%"} />
          }
          colorScheme={colorScheme}
          isDisabled={isConnecting || isConnected}
          size={"md"}
          cursor={isConnected ? "default" : "pointer"}
        >
          {isConnected ? username : `Connect to ${platformName}`}
        </Button>
        <IconButton
          aria-label={tooltipLabel}
          icon={isConnected ? <CheckIcon /> : <Plug />}
          isLoading={isConnecting}
          isDisabled={isConnected}
          colorScheme={colorScheme}
          variant="outline"
          size={"md"}
        />
      </ButtonGroup>
    </Tooltip>
  );
};

export default PlatformButton;
