import { useQuery } from "@apollo/client";
import { Stat, StatLabel, StatNumber, StatHelpText, Box } from "@chakra-ui/react";
import { AllActiveSearchesQuery } from "../../api/stats"; // Assuming queries is in the same folder
import { allowedUserIds } from "../../utils/allowedUserIds";

function ActiveSearchesStat() {
  const { data } = useQuery(AllActiveSearchesQuery, {
    variables: {
      excludedUserIDs: allowedUserIds,
    },
  });

  // Add check for data and data.search before trying to access data.search.length
  const numActiveSearches = data && data.search ? data.search.length : 0;

  return (
    <Box p="6" backgroundColor={"gray.700"} w={"full"} borderRadius={8}>
      <Stat>
        <StatLabel>Active Searches</StatLabel>
        <StatNumber>{numActiveSearches}</StatNumber>
        <StatHelpText mb="0">Current</StatHelpText>
      </Stat>
    </Box>
  );
}

export default ActiveSearchesStat;
