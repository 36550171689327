import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Select,
  Text,
  HStack,
  VStack,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { ActionSetsAggregatedQuery } from "../../../api/action-set";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { getActionTypes } from "../../../utils/action-types";
import { getApps } from "../../../utils/connected-apps";
import { Action } from "../../../models/action";

type Props = {
  isOpen: any;
  onClose: any;
  headerText: string;
  descriptionText: string;
  selectLabel: string;
  listLabel: string;
  selectActions: (actions: Action[]) => void;
};

const ApplyActionSet = (props: Props) => {
  const { isOpen, onClose, headerText, descriptionText, selectLabel, listLabel, selectActions } =
    props;
  const [selectedActionSet, setSelectedActionSet] = useState<number | null>(null);
  const actionTypes = getActionTypes();
  const apps = getApps();

  const { user } = useKindeAuth();

  const { data } = useQuery(ActionSetsAggregatedQuery, {
    variables: { user_id: user?.id },
  });
  const selectedActions = data?.action_sets.find((set: any) => set.id === selectedActionSet)
    ?.actions_aggregate.nodes;

  const confirmAction = () => {
    selectActions(selectedActions);
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{headerText}</ModalHeader>
        <ModalBody>
          <Text mb={4}>{descriptionText}</Text>
          <FormControl isRequired>
            <FormLabel>{selectLabel}</FormLabel>
            <Select
              placeholder="Select an Action Set"
              value={selectedActionSet !== null ? selectedActionSet : ""}
              onChange={(e) => setSelectedActionSet(Number(e.target.value))}
            >
              {data?.action_sets.map((set: { id: string; title: string }) => (
                <option key={set.id} value={set.id}>
                  {set.title}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl mt="4">
            {selectedActionSet ? <FormLabel>{listLabel}</FormLabel> : null}
            <VStack alignItems={"stretch"} spacing={1}>
              {selectedActions?.map((action: Action) => {
                const actionTypeImage = actionTypes.find(
                  (actionType) => actionType.title === action.type.kind
                )?.image;
                const appImage = apps.find(
                  (app) => app.title === action.output_destination.kind
                )?.image;
                return (
                  <HStack
                    px={3}
                    py={2}
                    justifyContent={"space-between"}
                    bg={"gray.600"}
                    borderRadius={"md"}
                    fontSize="sm"
                    key={action.id}
                    spacing={2}
                    borderLeftStyle={"solid"}
                    borderLeftWidth={6}
                    borderLeftRadius={"md"}
                    borderLeftColor={"yellow.200"}
                    flexWrap={"nowrap"}
                  >
                    <Text noOfLines={1} title={action.title} maxWidth="30ch">
                      {action.title}
                    </Text>
                    <HStack spacing={1} flexWrap={"nowrap"}>
                      <Tooltip label={action.type.kind} aria-label={action.type.kind}>
                        <Image w="32px" h="32px" src={actionTypeImage} />
                      </Tooltip>
                      <Text>→</Text>
                      <Tooltip
                        label={action.output_destination.kind}
                        aria-label={action.output_destination.kind}
                      >
                        <Image w="32px" h="32px" src={appImage} />
                      </Tooltip>
                    </HStack>
                  </HStack>
                );
              })}
            </VStack>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme={"green"}
            ml="2"
            onClick={confirmAction}
            isDisabled={!selectedActionSet}
          >
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ApplyActionSet;
