import { useQuery } from "@apollo/client";
import { Box, Stat, StatLabel, StatNumber, StatHelpText } from "@chakra-ui/react";
import { TotalHoursProcessedQuery } from "../../api/stats";

const sumDurationsToHours = (durations) => {
  let totalSeconds = 0;
  durations.forEach((duration) => {
    if (duration) {
      let days = 0,
        hours = 0,
        minutes = 0,
        seconds = 0;
      const daysMatch = duration.match(/(\d+) days/);
      if (daysMatch) {
        days = Number(daysMatch[1]);
      }
      const timeMatch = duration.match(/(\d+):(\d+):(\d+)/);
      if (timeMatch) {
        [, hours, minutes, seconds] = timeMatch.map(Number);
      }
      if (isNaN(days) || isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
        console.log(`Invalid duration: ${duration}`);
        return;
      }
      totalSeconds += days * 86400 + hours * 3600 + minutes * 60 + seconds;
    }
  });
  return Math.floor(totalSeconds / 3600);
};

const TotalHoursComponent = () => {
  const { loading, data } = useQuery(TotalHoursProcessedQuery);

  if (loading) return "Loading...";

  if (!data || !data.search || data.search.length === 0) return "No data available";

  const totalHours = sumDurationsToHours(data.search.map((item) => item.video_duration));

  return (
    <Box p="6" backgroundColor="gray.700" borderRadius={8} w="full">
      <Stat>
        <StatLabel>Total Hours Processed</StatLabel>
        <StatNumber>{totalHours}</StatNumber>
        <StatHelpText mb={0}>Including Admin Users</StatHelpText>
      </Stat>
    </Box>
  );
};

export default TotalHoursComponent;
