import { gql } from "@apollo/client";

// Queries
export const AllActiveSearchesQuery = gql`
  query AllActiveSearches($excludedUserIDs: [String!]!) {
    search(where: { status: { _eq: "in-progress" }, user_id: { _nin: $excludedUserIDs } }) {
      status
      created_at
      id
      user_id
    }
  }
`;

export const SearchesTwentyFourHoursQuery = gql`
  query SearchesTwentyFourHours(
    $timestamp_twenty_four_hours_ago: timestamp!
    $excludedUserIDs: [String!]!
  ) {
    search(
      where: {
        created_at: { _gte: $timestamp_twenty_four_hours_ago }
        user_id: { _nin: $excludedUserIDs }
      }
    ) {
      created_at
      id
      user_id
    }
  }
`;

export const SearchesThisWeekQuery = gql`
  query SearchesThisWeek($timestamp_seven_days_ago: timestamp!, $excludedUserIDs: [String!]!) {
    search(
      where: {
        created_at: { _gte: $timestamp_seven_days_ago }
        user_id: { _nin: $excludedUserIDs }
      }
    ) {
      created_at
      id
      user_id
    }
  }
`;

export const SearchesThisMonthQuery = gql`
  query SearchesThisMonth($timestamp_thirty_days_ago: timestamp!, $excludedUserIDs: [String!]!) {
    search(
      where: {
        created_at: { _gte: $timestamp_thirty_days_ago }
        user_id: { _nin: $excludedUserIDs }
      }
    ) {
      created_at
      id
      user_id
    }
  }
`;

export const TopUsersThisMonthQuery = gql`
  query TopUsersThisMonth($timestamp_thirty_days_ago: timestamp!, $excludedUserIDs: [String!]!) {
    search(
      where: {
        created_at: { _gte: $timestamp_thirty_days_ago }
        user_id: { _nin: $excludedUserIDs }
      }
    ) {
      created_at
      user_id
    }
  }
`;

export const TopUsersThisWeekQuery = gql`
  query TopUsersThisWeek($timestamp_seven_days_ago: timestamp!, $excludedUserIDs: [String!]!) {
    search(
      where: {
        created_at: { _gte: $timestamp_seven_days_ago }
        user_id: { _nin: $excludedUserIDs }
      }
    ) {
      created_at
      user_id
    }
  }
`;

export const HypeMomentsThisWeekQuery = gql`
  query HypeMomentsThisWeek($timestamp_seven_days_ago: timestamp!) {
    results(
      where: { created_at: { _gte: $timestamp_seven_days_ago } }
      order_by: { hype_level: desc }
      limit: 20
    ) {
      hype_level
      search_id
      start_time
    }
  }
`;

export const HypeMomentsThisMonthQuery = gql`
  query HypeMomentsThisMonth($timestamp_thirty_days_ago: timestamp!) {
    results(
      where: { created_at: { _gte: $timestamp_thirty_days_ago } }
      order_by: { hype_level: desc }
      limit: 20
    ) {
      hype_level
      search_id
      start_time
    }
  }
`;

export const MomentsThisWeekQuery = gql`
  query MomentsThisWeek($timestamp_seven_days_ago: timestamptz!, $excludedUserIDs: [String!]!) {
    moment(
      where: {
        created_at: { _gte: $timestamp_seven_days_ago }
        search: { user_id: { _nin: $excludedUserIDs } }
        deleted: { _eq: false }
      }
      order_by: { created_at: desc }
    ) {
      id
      title
      search_id
      tags
      created_at
      deleted
    }
  }
`;

export const MomentsThisMonthQuery = gql`
  query MomentsThisMonth($timestamp_thirty_days_ago: timestamptz!, $excludedUserIDs: [String!]!) {
    moment(
      where: {
        created_at: { _gte: $timestamp_thirty_days_ago }
        search: { user_id: { _nin: $excludedUserIDs } }
        deleted: { _eq: false }
      }
      order_by: { created_at: desc }
    ) {
      id
      title
      search_id
      tags
      created_at
      deleted
    }
  }
`;

export const ClipRequestsThisWeekQuery = gql`
  query ClipRequestsThisWeek(
    $timestamp_seven_days_ago: timestamptz!
    $excludedUserIDs: [String!]!
  ) {
    clip_request_aggregate(
      where: {
        created_at: { _gte: $timestamp_seven_days_ago }
        search: { user_id: { _nin: $excludedUserIDs } }
      }
    ) {
      aggregate {
        count
      }
    }
    clip_request(
      where: {
        created_at: { _gte: $timestamp_seven_days_ago }
        search: { user_id: { _nin: $excludedUserIDs } }
      }
    ) {
      lead_in
      lead_out
      search_id
    }
  }
`;

export const ClipRequestsThisMonthQuery = gql`
  query ClipRequestsThisMonth(
    $timestamp_thirty_days_ago: timestamptz!
    $excludedUserIDs: [String!]!
  ) {
    clip_request_aggregate(
      where: {
        created_at: { _gte: $timestamp_thirty_days_ago }
        search: { user_id: { _nin: $excludedUserIDs } }
      }
    ) {
      aggregate {
        count
      }
    }
    clip_request(
      where: {
        created_at: { _gte: $timestamp_thirty_days_ago }
        search: { user_id: { _nin: $excludedUserIDs } }
      }
    ) {
      lead_in
      lead_out
      search_id
    }
  }
`;

export const ClipRequestsQuery = gql`
  query ClipRequests($excludedUserIDs: [String!]) {
    clip_request(where: { search: { user_id: { _nin: $excludedUserIDs } } }) {
      lead_in
      lead_out
    }
  }
`;

export const TotalHoursProcessedQuery = gql`
  query TotalHoursProcessed {
    search {
      video_duration
    }
  }
`;
