import { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Image,
  VStack,
  Text,
  AspectRatio,
  HStack,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { ArrowLeft, ArrowRight } from "@icon-park/react";
import { SearchTour, ResultsTour, RivrWalkthrough, ContentStep } from "./TourContent";
import { useTutorial } from "../../hooks/useTutorial";

interface TourModalProps {
  isOpen: boolean;
  onClose: () => void;
  tourType: "video" | "search" | "results";
}

const TourModal: React.FC<TourModalProps> = ({ isOpen, onClose, tourType }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const tutorial = useTutorial(tourType);

  useEffect(() => {
    setCurrentStep(0);
  }, [tourType, isOpen]);

  const tourSteps =
    tourType === "video" ? RivrWalkthrough : tourType === "search" ? SearchTour : ResultsTour;

  const handleNext = () => {
    if (currentStep < tourSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleComplete = () => {
    tutorial.completeTutorial();
    onClose();
  };

  const handleModalClose = () => {
    onClose();
  };

  let content;
  let headline = "";

  const currentStepData = tourSteps[currentStep];

  if (currentStepData) {
    switch (currentStepData.type) {
      case "video":
        headline = currentStepData.videoHeadline;
        content = (
          <Box position="relative">
            <AspectRatio ratio={16 / 9} borderRadius={"md"} overflow={"hidden"} zIndex={1}>
              <iframe
                width={"100%"}
                src={currentStepData.content}
                title="Rivr Walkthrough"
                allowFullScreen
                allow={"autoplay"}
                key={currentStep}
              />
            </AspectRatio>
            <VStack align={"start"} w={"100%"}>
              <Box fontSize={"md"} color={"gray.300"} sx={{ textWrap: "pretty" }} mt={6}>
                {currentStepData.videoText}
              </Box>
            </VStack>
          </Box>
        );
        break;
      case "content":
        headline = currentStepData.contentHeadline;
        const contentStep = currentStepData as ContentStep;
        content = (
          <VStack spacing={6} align={"start"} w={"100%"}>
            <Image src={contentStep.image} w={"100%"} borderRadius={"md"} />
            <Box fontSize={"md"} color={"gray.300"} sx={{ textWrap: "pretty" }}>
              {contentStep.contentText}
            </Box>
          </VStack>
        );
        break;
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      size={"6xl"}
      closeOnOverlayClick={true}
      closeOnEsc={true}
    >
      <ModalOverlay />
      <ModalContent overflow={"auto"} pt={4}>
        <ModalHeader display={"flex"} justifyContent={"space-between"}>
          {headline}
          <HStack align={"center"} justify={"end"}>
            {tourSteps.length > 1 && (
              <>
                <IconButton
                  aria-label={"Step back"}
                  icon={<ArrowLeft />}
                  onClick={handlePrevious}
                  isDisabled={currentStep === 0}
                  variant={"outline"}
                  size={"sm"}
                />
                <Text fontSize={"sm"} color={"gray.300"} minW={10} fontWeight={"normal"}>
                  {currentStep + 1} of {tourSteps.length}
                </Text>
                <IconButton
                  aria-label={"Step forward"}
                  icon={<ArrowRight />}
                  onClick={handleNext}
                  isDisabled={currentStep === tourSteps.length - 1}
                  variant={"outline"}
                  size={"sm"}
                />
              </>
            )}
            {tutorial.completed ? (
              <Button onClick={handleModalClose} minW={"fit-content"} size={"sm"} variant={"ghost"}>
                Close
              </Button>
            ) : (
              <Button
                onClick={handleComplete}
                minW={"fit-content"}
                colorScheme={"green"}
                size={"sm"}
                isDisabled={currentStep !== tourSteps.length - 1}
              >
                Complete
              </Button>
            )}
          </HStack>
        </ModalHeader>
        <ModalBody>{content}</ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default TourModal;
