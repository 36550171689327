import { Navigate } from "react-router-dom";
import { Box, VStack, useDisclosure } from "@chakra-ui/react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import FeedbackModalContext from "./context/FeedbackModalContext";
import FeedbackForm from "./components/FeedbackForm/FeedbackForm";
import LiveChat from "./components/LiveChat/LiveChat";
import "./components/LiveChat/LiveChat.css";
import { AccountProvider } from "./context/AccountProvider";
import NavigationBar from "./components/Navigation/NavigationBar";

const Protected = ({ children }) => {
  const { isAuthenticated } = useKindeAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!isAuthenticated) {
    localStorage.setItem("loginRedirect", `${window.location.pathname}${window.location.search}`);
    return <Navigate replace to="/" />;
  }
  localStorage.removeItem("loginRedirect");

  return (
    <Box w={"100%"} h={"100vh"}>
      <AccountProvider>
        <FeedbackModalContext.Provider value={{ isOpen, onOpen, onClose }}>
          <VStack spacing={0} h={"100%"} w={"100%"} className="site-wrapper">
            <NavigationBar />
            {children}
          </VStack>
          <FeedbackForm />
          <LiveChat />
        </FeedbackModalContext.Provider>
      </AccountProvider>
    </Box>
  );
};
export default Protected;
