type SignalConfig = {
  display: string;
  color: string;
  range: [number, number];
  deadzone: number;
  rescale?: boolean;
  visible: boolean;
  index: number;
  colorAlt1?: string;
  colorAlt2?: string;
};

export const signals: [string, Omit<SignalConfig, "index">][] = [
  [
    "Streamer intensity",
    {
      display: "Audio Intensity",
      color: "#F56565",
      colorAlt1: "#C53030",
      colorAlt2: "#9F7AEA",
      range: [-1, 1],
      deadzone: 0,
      visible: false,
    },
  ],
  [
    "Chat intensity",
    {
      display: "Chat Intensity",
      color: "#4FD1C5",
      colorAlt1: "#48BB78",
      colorAlt2: "#4FD1C5",
      range: [0, 1],
      deadzone: 0.05,
      rescale: true,
      visible: true,
    },
  ],
  [
    "Chat sentiment",
    {
      display: "Chat Response",
      color: "#9F7AEA",
      colorAlt1: "#009E73",
      colorAlt2: "#009E73",
      range: [-1, 1],
      deadzone: 0.03,
      visible: true,
    },
  ],
  [
    "Hype",
    {
      display: "Hype",
      color: "#FAF089",
      colorAlt1: "#ECC94B",
      colorAlt2: "#ECC94B",
      range: [0, 9],
      deadzone: 0.2,
      visible: true,
    },
  ],
  [
    "Hype normalized",
    {
      display: "Hype",
      color: "#F6E05E",
      colorAlt1: "#ECC94B",
      colorAlt2: "#ECC94B",
      range: [0, 1],
      deadzone: 0.03,
      visible: true,
    },
  ],
  [
    "Chat positive",
    {
      display: "Chat Positivity",
      color: "#4299E1",
      colorAlt1: "#3182CE",
      colorAlt2: "#48BB78",
      range: [0, 1],
      deadzone: 0.05,
      visible: true,
    },
  ],
  [
    "Chat negative",
    {
      display: "Chat Negativity",
      color: "#9F7AEA",
      colorAlt1: "#B83280",
      colorAlt2: "#F56565",
      range: [-1, 0],
      deadzone: 0.05,
      visible: true,
    },
  ],
];

type Annotation = {
  name: string;
  signal: string;
  negative?: boolean;
};

export const annotations: Annotation[] = [
  { name: "Hype", signal: "Hype normalized" },
  { name: "Chat intensity", signal: "Chat intensity" },
  { name: "Chat positive", signal: "Chat positive" },
  { name: "Chat negative", signal: "Chat negative", negative: true },
];

export const signalMap = new Map<string, SignalConfig>(
  signals.map(([name, config], index) => [name, { ...config, index }])
);

export const signalReplacements = new Map([["Hype", "Hype normalized"]]);
