import { Box } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import KbLayout from "../../components/KnowledgeBase/KbLayout";
import { SettingsIcon } from "@chakra-ui/icons";
import KbButton from "../../components/KnowledgeBase/KbButton";
import LinkedHeading from "../../components/KnowledgeBase/KbLinkedHeading";
import RelatedArticles from "../../components/KnowledgeBase/KbRelated";
import { KBSubPages, RivrLocations } from "../../models/navigation";
import { Search, Star } from "@icon-park/react";

import { Text, Link, UnorderedList, ListItem } from "../../components/KnowledgeBase/KbElements";

const KbGettingStarted: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <KbLayout>
      <Box w={"full"} h={"full"}>
        <LinkedHeading as={"h1"} id={"getting-started"} py={2}>
          Getting started
        </LinkedHeading>
        <LinkedHeading as={"h2"} id={"accounts-auth"} pt={6} pb={4}>
          Accounts and signing In
        </LinkedHeading>
        <Text>Currently, we offer the following sign-in options:</Text>
        <UnorderedList spacing={2} pl={6} pb={4}>
          <ListItem>Google</ListItem>
          <ListItem>Twitch</ListItem>
        </UnorderedList>
        <Text>
          In all cases, Rivr only accesses the information necessary to create your account.
          Authentication services are provided by Kinde – visit their{" "}
          <Link href={"https://kinde.com/docs/important-information/privacy-policy/"} isExternal>
            website
          </Link>{" "}
          for details on their information security practices and policies.
        </Text>
        <LinkedHeading as={"h3"} id={"account-settings"} pt={6} pb={4}>
          Account settings
        </LinkedHeading>
        <Text>
          You can manage various aspects of your Rivr account on the{" "}
          <Link onClick={() => navigate(`${RivrLocations.Account}?${searchParams.toString()}`)}>
            Account
          </Link>{" "}
          page , accessible via the{" "}
          <KbButton isIconButton={true} icon={<SettingsIcon />} colorScheme={"gray"} /> menu at the
          top-right of Rivr.
        </Text>
        <LinkedHeading as={"h3"} id={"account-removal"} pt={6} pb={4}>
          Account removal
        </LinkedHeading>
        <Text>
          We respect your right to request the full removal of your account and associated data. To
          request account removal, go to the Account tab on the{" "}
          <Link onClick={() => navigate(`${RivrLocations.Account}?${searchParams.toString()}`)}>
            Account
          </Link>{" "}
          page and click the{" "}
          <KbButton
            isIconButton={false}
            text="Delete account"
            colorScheme="red"
            variant={"outline"}
          />{" "}
          button. Once complete, a confirmation email will be sent to the address you used to
          register. Please allow up to 72 hours for this process. Note: Your account and any
          associated data cannot be restored once deleted.
        </Text>
        <Box py={6}>
          <Text textAlign={"right"} fontSize={"sm"} pb={0}>
            <em>
              Last updated on Sept. 12, 2024 by{" "}
              <Link href={"https://x.com/marcfromrivr"} isExternal>
                @MarcFromRivr
              </Link>
            </em>
          </Text>
        </Box>
        <RelatedArticles
          articles={[
            {
              title: "Search",
              description: "How to analyze your VODs for your best Moments",
              icon: Search,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Search}`,
            },
            {
              title: "Results",
              description: "How to navigate results and download clips",
              icon: Star,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Results}`,
            },
          ]}
        />
      </Box>
    </KbLayout>
  );
};

export default KbGettingStarted;
